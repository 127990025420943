.Security_bg{
    padding:90px 0 0;
    //background:$bannerlightgray;
    background-image:url('Images/HeaderBanner.jpg');
    position: relative;
    min-height:450px;
    canvas{
        height:350px !important;
    }
    
    .jumbotron{
        background:transparent;
        padding:0;
        margin:0;
    }
    .bannerdetails{
        //position: absolute;
        text-align: center;
        padding:80px 0 0;
        width: 100%;
        h1{
            font-size:34px;
            color:$darkblue;
            line-height:50px;
            text-align:center;
            font-weight:700;
            font-style:normal;
        }
        p{
            font-size:20px;
            color:$darkblue;
            line-height:36px;
            text-align:center;
        }
        button, a{
            background:$lightorange;
            font-weight:bold;
            border-radius:30px;
            padding:10px 30px;
            color:$white;
            border:none;
            margin-top:30px;
            display:inline-block;
            &:hover{
               background:$gradient-bluesky;
               text-decoration:none;
            }
        }
    }
}

//Security Section1 Start
.Security_Modules{
    padding:115px 0 10px;

    p{
        font-size:16px;
    }
}


//Security Section2 Start

.Securty_Modules_And_Features {
    margin: 80px 0px 50px 0px;

    .Security_Modules_Service{
        border: 1px solid #22d7cf !important;
        padding: 10px;
        margin-bottom: 20px;
  
        p {
            font-size: 17px;
            font-weight: 500;
        }
    }
}

//Security Section3 Start

.SecurityProduct-bg{
    background:url(Images/features-bg.jpg);
    background-size:cover;
    background-position:center;

    .Securitytitle{
        padding:30px 0 10px;
        h2{
            font-size:32px;
            color:$darkblue;
            text-align: left;
            font-weight: 700;
            font-style: normal;
        }
        p{
            font-size:16px;
            color:$github;
        }
    }

    .Securityproductbox{
        background:$white;
        padding:15px 20px;
        min-height:250px;
        margin:0 0 30px;
        a{
            img{
                width:70%;
                margin:0 15% 0;
            }
            text-decoration:none;
            color:$github;  
            span{
                font-size:20px;
                color:$darkblue;
                margin:15px 0 5px;
                display:inline-block;
                font-weight:600;
            }
            p{
                font-size:15px;
                color:$github;  
            }
        }
    }
}

//Security Section4 Start

.Security_insight-bg{
    padding:50px 0px 80px 0px;;
     
    .card-title{
        height: 70px;
        font-size: 18px;
        color: $lightblack;
        &:hover{
            color: $lightblue;
           text-decoration: none;
        }
    }

    .carddiv{
        &:hover{
        box-shadow: 0 2px 3px rgba(0,0,0,0.1), 0 5px 10px rgba(0,0,0,0.25);
        transition: box-shadow 0.3s;
        }
    }
}

@media only screen and (min-width: 320px) and (max-width: 680px)  {

    //canvas
    .security-modulescanvas {
        height:376px !important;
    }
}

@media only screen and (max-width : 767px) {
   
    .Security_bg {
        padding:0;
    }
    //Security Section1 Start
    .Security_Modules{
        padding:25px 0 10px;

        p{
            font-size:16px;
        }
    }
}

//Tablet View
@media (min-width: 768px) and (max-width: 1024px) {
    .Security_Modules {
        padding:30px 0 10px;
    }
    .SecurityProduct-bg{
        .Securityproductbox{
            min-height:375px;
        }
    }
    .carddiv{
        min-height:250px;
    }
}