//Common Main Title Start
.P2Ptitle{
    padding:30px 0 10px;
    h2, h3, h4, h5,h6{
        font-size:32px;
        color:$darkblue;
        text-align: left;
        font-weight: 700;
        font-style: normal;
    }
    p{
        font-size:16px;
        color:$github;
    }
}

//Hybrid Exchange Banner Start
.P2PExchangeSolution{
    padding:90px 0 0;
    //background:$bannerlightgray;
    background-image:url('Images/HeaderBanner.jpg');
    position: relative;
    min-height:450px;
    canvas{
        height:350px !important;
    }
    .jumbotron{
        background:transparent;
        padding:0;
        margin:0;
    }
    .bannerdetails{
        //position: absolute;;
        text-align: center;
        padding:80px 0 0;
        h1{
            font-size:34px;
            color:$darkblue;
            line-height:50px;
            text-align:center;
            font-weight:700;
            font-style:normal;
        }
        p{
            font-size:20px;
            color:$darkblue;
            line-height:36px;
            text-align:center;
        }
        button, a{
            background:$lightorange;
            font-weight:bold;
            border-radius:30px;
            padding:10px 30px;
            color:$white;
            border:none;
            margin-top:30px;
            display:inline-block;
            &:hover{
               background:$gradient-bluesky;
               text-decoration:none;
            }
        }
    }
}



//Peer To Peer Exchange
.PeerToPeerExchange{
    background:$bannerlightgray;
    margin:50px 0 0;
    padding-bottom:30px;
}

.PeerToPeerBox{
    background-color:$white;
    padding:20px;
    color: $darkblue;
    box-shadow: 15px 10px 30px rgba(0, 0, 0, 0.2);
    min-height:250px;
    border-radius:8px;
    margin:0 0 30px 0;
    span, h3{
        font-size:22px;
        color:$darkblue;
    }
    p{
        font-size:16px;
        color:$github;
        padding: 10px 0 0 0;
    }
}

// P2P Crypto Exchange
.P2PCrypto-bg{
    background:$gradient-skyblue;
    padding:30px 0;
    span{
        color:$white;
        font-size:24px;
    }
    a{
        background-color:$white;
        color:$darkblue;
        cursor: pointer;
        box-shadow: 0 2px 1px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.3);
        padding: 10px 20px;
        font-size: 18px;
        border-radius: 3px;
        margin: 0px 0 0 0;
        display: inline-block;
        &:hover{
            text-decoration:none;
            color:$darkblue;
        }
    }
}

.ExchangeModelbox{
    margin:10px 0 30px;
    h4{
        font-size: 18px;
        display: inline-block;
        font-weight: normal;
        width: 70%;
        padding: 0 0 0 8px;
    }
    img{
        vertical-align: top;
    }
}


//Our Other Exchange Product
.P2PExchangeProduct{
    background-color:$lightwhite;
    .p2pproductbox{
        background:$white;
        padding:15px 20px;
        min-height:250px;
        margin:0 0 30px;
        a{
            img{
                width:70%;
                margin:0 15% 0;
            }
            text-decoration:none;
            color:$github;  
            span, h3{
                font-size:20px;
                color:$darkblue;
                margin:15px 0 5px;
                display:inline-block;
                font-weight:700;
            }
            p{
                font-size:15px;
                color:$github;  
            }
        }
        &:hover {
            transition: all 2s ease;
            transform: scale(1.0);
            
        }  
    }
}


//Mobile View
@media only screen and (max-width:767px) {
    .FooterMain{
        .footermenu{
            margin-left:15px;
        }
    }
}

//Tablet View
@media (min-width: 768px) and (max-width: 1024px) {
    .P2PExchangeProduct{
        .p2pproductbox{
            min-height: 320px;
        }
    }
}