$vision_h2 : #20126f;

.vision_section1 {
    padding-top: 50px;
    padding-bottom: 50px;

    img {
        max-width: 72%;
    }
    h2{
        font-size: 32px;
        color:$vision_h2;
        font-weight: 700;
        padding-bottom: 30px;
        padding-top: 20px;
    }
}