
//Trading Exchange Banner Start
.Trading_report_bg{
    padding:90px 0 0;
    //background:$bannerlightgray;
    background-image:url('Images/HeaderBanner.jpg');
    position: relative;
    min-height:450px;
    canvas{
        height:350px !important;
    }
    
    .jumbotron{
        background:transparent;
        padding:0;
        margin:0;
    }
    .bannerdetails{
        //position: absolute;
        text-align: center;
        padding:80px 0 0;
        width: 100%;
        h1{
            font-size:34px;
            color:$darkblue;
            line-height:50px;
            text-align:center;
            font-weight:700;
            font-style:normal;
        }
        p{
            font-size:20px;
            color:$darkblue;
            line-height:36px;
            text-align:center;
        }
        button,a{
            background:$lightorange;
            font-weight:bold;
            border-radius:30px;
            padding:10px 30px;
            color:$white;
            border:none;
            margin-top:30px;
            display:inline-block;
            &:hover{
               background:$gradient-bluesky;
               text-decoration:none;
            }
        }
    }
}

//Trading Section1 Start
.Trading{
    padding:115px 0 10px;

    p{
        font-size:16px;
    }
}


//Trading Section2 Start

.Trading_Report_Management_bg{
    background-color: $lightgray;
    padding: 70px 0px 20px 0px;
    margin-top: 45px;

    .Trading_Service {
        box-shadow: 1px 5px 36px rgba(0, 0, 0, 0.1);
        border-radius: 8px;
        padding: 25px 10px 15px 10px;
        background-color: $white;
        min-height:225px;
        margin-bottom: 25px;

        svg{
            color:$lightblue;
        }
        h4{
            font-size:20px;
        }
        p{
            font-size:15px;
        }
    }
}

//Trading Section3 Start

.TradingProduct-bg{
    background:url(Images/features-bg.jpg);
    background-size:cover;
    background-position:center;

    .tradingtitle{
        padding:30px 0 10px;
        h2{
            font-size:32px;
            color:$darkblue;
            text-align: left;
            font-weight: 700;
            font-style: normal;
        }
        p{
            font-size:16px;
            color:$github;
        }
    }

    .tradingproductbox{
        background:$white;
        padding:15px 20px;
        min-height:250px;
        margin:0 0 30px;
        a{
            img{
                width:70%;
                margin:0 15% 0;
            }
            text-decoration:none;
            color:$github;  
            span{
                font-size:20px;
                color:$darkblue;
                margin:15px 0 5px;
                display:inline-block;
                font-weight:600;
            }
            p{
                font-size:15px;
                color:$github;  
            }
        }
    }
}

//Trading Section4 Start


   .Tradinginsight-bg{
       padding:50px 0px 80px 0px;

        h3{
            font-size:32px;
            color:$darkblue;
            text-align: left;
            font-weight: 700;
            font-style: normal;
        }
        .card-title{
             height: 70px;
            font-size: 18px;
            color: $lightblack;
            &:hover{
                color: $lightblue;
                text-decoration: none;
            }
        }
        .carddiv{
            &:hover{
            box-shadow: 0 2px 3px rgba(0,0,0,0.1), 0 5px 10px rgba(0,0,0,0.25);
            transition: box-shadow 0.3s;
            }
        }
    }

@media only screen and (min-width: 320px) and (max-width: 680px)  {

    //canvas
    .trading_reportcanvas {
        height: 450px !important;
    }
}

 
@media only screen and (max-width : 767px) {
    .Trading_report_bg{
        padding:0;
        .trading_reportcanvas {
            height: 370px !important;
        }
    }
    
    //Trading Section1 Start
    .Trading{
        padding:25px 0 10px;

        p{
            font-size:16px;
        }
    }
}

//Tablet View
@media (min-width: 768px) and (max-width: 1024px) {
    .Trading_report_bg{
        .bannerdetails {
            padding: 70px 0 0 !important;
        }
    }
    .Trading {
        padding: 25px 0 10px;
    }
    .TradingProduct-bg{
        .tradingproductbox{
            min-height: 370px;
        }
    }
    .Trading_Report_Management_bg{
        .Trading_Service{
            min-height: 270px;
        }
    }
}