
.whitepaperbanner-bg{
    padding:100px 0;
    background-image:url(Images/features-bg.jpg);
    background-size: cover;
    background-position:center;

    .bannerdetails{
        text-align: center;
        padding:90px 0 0;
        h1{
            font-size:34px;
            color:$darkblue;
            line-height:50px;
            text-align:center;
            font-weight:700;
            font-style:normal;
        }
        p{
            font-size:20px;
            color:$darkblue;
            line-height:36px;
            text-align:center;
        }
        button, a{
            background:$lightorange;
            font-weight:bold;
            border-radius:30px;
            padding:10px 30px;
            color:$white;
            border:none;
            margin-top:30px;
            display:inline-block;
            &:hover{
               background:$gradient-bluesky;
               text-decoration:none;
            }
        }
    }
}

.whitepaperdetailsbox{
    padding:30px 0 0;
    h2{
        font-size:28px;
        color:$darkblue;
        text-align:left;
        font-weight:700;
        font-style:normal;
        padding-bottom:10px;
    }
    .graphdetails{
        font-size:16px;
        padding:10px 0;
        text-align:center;
    }
    strong{
        font-size:18px;
        padding:10px 0;
        display:inline-block;
    }
    p{
        font-size:16px;
        display:inline-block;
        text-align: justify;
        width:100%;
    }
    a{
        color:$lightorange;
        font-weight:bold;
    }
    .pricetable{
        width:100%;
        border:1px solid gray;
        text-align:center;
        > tr{
            border-bottom:1px solid gray;
            th{
                background:#f4f4f4;
            }
            > td{
                text-align:center;
            }
        }
        table{
            width:100%;
            tr{
                td{
                    text-align:center;
                    padding: 0 10px;
                }
            }
        }
        tr{
            table{
                border-right:1px solid gray; 
            }
            
            td{
                &:last-child{
                    table{
                        border-right:none; 
                    }
                } 
            }
        }
    }
}


@media only screen and (max-width:767px) {
    .whitepaperbanner-bg {
        padding: 10px 0;
        .bannerdetails{
            h1{
                font-size: 20px;
                line-height: 30px;
            }
            p{
                font-size: 16px;
                line-height: 26px;
            }
        }
    }
    .pricetable-bg{
        width:100%;
        overflow:scroll;
        .pricetable{
            width:1000px;
        }
    }
}