//Common Main Title Start
.stoadviceitle{
    padding:30px 0 10px;
    h2, h3, h4, h5{
        font-size:32px;
        color:$darkblue;
        text-align: left;
        font-weight: 700;
        font-style: normal;
    }
    p{
        font-size:16px;
        color:$github;
    }
}
.stoadviceabouttitle{
    h2, h3{
        font-size:22px;
        color:$darkblue;
        text-align:left;
        font-weight:500;
        font-style: normal;
    }
    p{
        font-size:16px;
        color:$github;
        text-align:justify;
    }
}

//Cryptocurrency Exchange Software Banner

.AdviceExchange-bg{
    // padding:100px 0;
    // background:$bannerlightgray;
    // background-image:url(Images/HeaderBanner.jpg);
    // background-size: cover;
    // background-position:center;
    // min-height:450px;
    padding:100px 0 0;
    background-image:url('Images/HeaderBanner.jpg');
    position:relative;
    min-height:450px;
    .jumbotron{
        background:transparent;
        padding:0;
        margin:0;
    }
    .bannerdetails{
        text-align: center;
        padding:90px 0 0;
        h1{
            font-size:34px;
            color:$darkblue;
            line-height:50px;
            text-align:center;
            font-weight:700;
            font-style:normal;
        }
        p{
            font-size:20px;
            color:$darkblue;
            line-height:36px;
            text-align:center;
        }
        button, a{
            background:$lightorange;
            font-weight:bold;
            border-radius:30px;
            padding:10px 30px;
            color:$white;
            border:none;
            margin-top:30px;
            display:inline-block;
            &:hover{
               background:$gradient-bluesky;
               text-decoration:none;
            }
        }
    }
}

//Services Section
.AdviceServices-bg{
    background:$bannerlightgray;
    padding:0 0 30px;
    .Servicesbox{
        background:$white;
        padding: 0 15px;
        margin-top:20px;
        box-shadow: 7px 5px 10px rgba(0,0,0,0.10);
        min-height:286px;
        img{
            text-align: center;
            margin: 0 auto;
            display: inherit;
            padding: 10px 0;
        }
        h3{
            text-align: center;
            color:$darkblue;
            padding:10px 0 10px 0;
            display: inline-block;
            width:100%;
            font-size:22px;
            font-weight:600;
        }
        p{
            text-align:justify;
            font-size:14px;
        }
    }
}


// Mobile View
@media only screen and (max-width: 768px) {
    .AdviceExchange-bg {
        padding:50px 0 0;
        min-height:310px;
    }
    .Servicesbox{
        padding:10px 15px !important;
    }
    .stoadviceitle{
        padding:0px 0 0;
        h2{
            font-size:24px;
        }
    }
    .certificateshortbox{
        margin:30px 0 0;
        min-height:170px;
    }
    .faqmaintitle {
        font-size:24px;
        padding:40px 0 0;
    }
    .talktoexcpert{
        h2{
            font-size: 1.5rem;
        }
    }
    .talktoexceprt {
        width:50%;
    }
    .innercontent {
        padding: 0px 0 0;
    }
  }


  //iPad Mini 
  @media only screen 
and (min-device-width : 768px) 
and (max-device-width : 1024px)  {
    .jumbotron{
        .bannerdetails{
            h1{
                font-size: 28px !important;
            }
        }
    }
    .AdviceExchange-bg {
        padding:115px 0 0;
        min-height: 350px;
    }
    .stoadviceitle {
        h2{
            font-size:28px;
        }
    }
    .AdviceServices-bg{
        .Servicesbox{
            min-height:400px;
        }
    }
    .certificateshortbox {
        //min-height:200px;
        margin: 30px 0 0px;
    }
    .certificatetitle{
        h2{
            padding:0;
        }
    }
}