.mt-50{
    margin-top:50px;
}
.mt-40{
    margin-top:40px;
}
.mt-30{
    margin-top:30px;
}
.mb-50{
    margin-bottom:50px;
}
.mb-40{
    margin-bottom:40px;
}
.mb-30{
    margin-bottom:30px;
}
.mt-20{
    margin-top:20px;
}
.mb-20{
    margin-bottom:20px;
}
.mt-10{
    margin-top:10px;
}
.mb-10{
    margin-bottom:10px;
}
.ScrollUpButton__Container{
    width:50px !important;
    right:15px !important;
    bottom:80px !important;
    background:$gradient-skyblue;
    border-radius:50%;
   
    &:hover{
        background:$gradient-bluesky;
    }
    svg{
        width:70% !important;
        height:70% !important;
        margin:8px;
    }
}

.errorMsg {
    font-size: 12px;
    color: red;
}

//Loader
.loader-overlay {
    position: fixed;
    background: rgba(0,0,0, 0.8);
    top: 0;
    left: 0;
    right: 0;
    bottom: 0px;
    z-index: 101;
    align-items: center;
    overflow: hidden;
    margin: 0 auto;
    > div{
        > div{
            background:$white;
        }
    }
  }
  


//Top Header
.topheader{
    background:#446fa6;
    color:$white;
    position:fixed;
    padding:5px 0;
    width: 100%;
    z-index: 9;
    border-bottom: 1px solid rgba($bannerlightgray,0.2);
    .headerinfo{
        display:inline-block;
        padding: 0 15px 0 0;
        vertical-align: middle;
    }
    a{
        color:$white !important;
        padding:0 5px 0 !important;
        vertical-align: text-bottom;
        font-size:14px;
        &:hover{
            color:#FFAF48;
            text-decoration:none;
        }
    }
    .CryptoExchangeSolutionfreebtn{
        //background: #FFAF48;
        // border-radius: 22px;
        // padding:1px 5px 0 0;
        // vertical-align:unset;
        // margin:0px auto;
        // width:225px;
        // float:right;
        a{
            background: #FFAF48;
            padding: 5px 10px !important;
            border-radius: 30px;
            margin: 1px 0 0 0;
            display: inline-block;
        }
    }
}




// Header Start
.bookdemobtnheader{
    a{
        background:$rss;
        color:$white;
        padding:8px 15px;
        border-radius:20px;
        font-size:18px;
        &:hover{
            text-decoration:none;
            color:$white;
        }
    }
}
.CryptoExchangeSolutionMenu{
    .dropmenu{
        display:none;
    }
    .CryptoExchangeSolutionfreebtn{
        position: relative;
        top:0px;
    }
}
.allmenu{
    .allpagemenu{
        display:none;
    }
}
.headermenu{
    // background:$gradient-skyblue;
    background:#446fa6;
    position: fixed;
    z-index: 1;
    width: 100%;
    .navbar{
        padding:44px 0 0 5px;
    }
    .navbar-nav{
        .nav-item{
            min-width:100px;
            text-align: center;
            &:hover{
              background:$white;
              
              a{
                color:$darkblue;
              }
            }
            a{
              color:$white;
              padding-top:12px;
              padding-bottom:12px;
              display:inline-block;
              &:hover{
                  text-decoration:none;
              }
            }
        }
    }
    .navbar-brand{
        .nav-link{
            padding:0px !important;
            img{
                //width:180px;
                width:200px;
            }
        }
    }
    .submenumain{
        position: relative;
        
        a{
            i {
                border: 1px solid $white;
                border-width: 0 2px 2px 0;
                display: inline-block;
                padding: 2px;
                position: relative;
                left: 7px;
                transform: rotate(45deg);
                bottom: 2px;
              }
        }
        .innermenu{
            display: none;
            background:$white;
            position: absolute;
            overflow: hidden;
            width:100%;
            padding: 10px 15px;
            // margin:10px 0 0;
            a{
                padding:3px 0;
            }
        }
       
        &:hover{
            .innermenufirst{
                min-width:34rem !important;
            }
            .innermenu{
                display:block;
                transform-origin:50% 0;
                transition:transform 0.3s, opacity 0.3s;
                box-shadow:0 1px 1px 0 rgba(0,0,0,0.05), 0 2px 5px 0 rgba(0,0,0,0.25);
                text-align:left;
                min-width:23rem;
                padding-top:12px;
    
                > a {
                    font-weight:normal;
                    color:$darkblue;
                    text-decoration:none;
                    display:inline-block;
                    width:100%;
                    padding:5px 15px;
                }
                ul{
                    padding:3px 0;
                    > li:first-child{
                        a{
                            color:$lightsky;
                            text-decoration:none;
                        }
                    }
                }
                
                li{
                    list-style:none;
                    padding:3px 0;
                    a{
                        font-weight:bold;
                    }
                    ul{
                        padding:0 20px;
                        .innertitle{
                            list-style:circle;
                            a{
                                color:$darkblue;
                                font-weight:bold;
                                text-decoration:none;
                            }
                        }
                        li{
                            line-height:15px;
                            ul{
                                li:nth-child(1), li, .companymenu{
                                    list-style: disc;
                                    a{
                                        font-weight:normal;
                                        color:$darkblue;
                                        text-decoration:none;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    
}


//Footer Start
.FooterMain, .cryptofooter{
    background:$black;
    padding:50px 0 30px;
    //z-index:9;
    //z-index:0;
    position:relative;
    
    h6{
        font-size:20px;
        color:$bannerlightgray;
        text-align:left;
        font-weight:600;
        font-style:normal;
        margin:0 0 20px;
    }

    .footermenu{
        padding: 0;
        li{
            color:$white;
            a{
                color:$white;
                &:hover{
                    color:$lightblue;
                    text-decoration:none;
                }
            }
        }
    }
    .footercommenu{
        padding:0 15px 0;
        color:$white;
        li{
            a{
                color:$white;
                &:hover{
                    color:$lightblue;
                    text-decoration:none;
                }
            }
        }
    }
    .businessdetails{
        list-style:none;
        padding:0 15px 0 0;
        li{
            width: 100%;
            display: inline-block;
            a, p, span{
                color:$white;
                padding: 7px 0 0 0;
                text-decoration:none;
            }
        }
        img{
            float: left;
            padding-right: 10px;
        }
        p{
            color:$white;
        }
    }
    .footersocial{
        padding:0;
        li{
            width:16% !important;
            display: inline-block;
            padding: 0 15px 0 0;
            a{
                display: inline-block;
                img{
                    width:30px;
                }
            }
        }
    }
}

.crpcontent{
    color:$white;
    padding: 0 0 20px;
}

.Newsletterbtn {
    width:100%;
    input[type='submit']{
        border:none;
        background: $gradient-skyblue;
        color: $white;
        cursor: pointer;
        font-weight: bold;
        padding: 8px 0 36px;
        &:hover{
            background: $gradient-bluesky;
            border:none;
        }
    }
} 

// Copyright Start
.copyrightfooter, .crpcopyrightfooter {
    padding:15px 0;
    background-color:$lightblack;

    ul{
        margin:0;
        li{
            display:inline-block;
            padding-right:37px;
            text-align:right;
            a{
                color:$white;
                &:hover{
                    color:$lightsky;
                    text-decoration: none;
                }
            }
           
        }      
    }
    
    p{
        text-align: left;
        color: #596160;
        font-weight: 700;
        margin:0;
    }
    .cescontent{
        text-align:center;
        color: #596160;
        font-weight: 700;
    }
}
.headermenu{
    display:block;
}
.mobileheadermenu{
    display:none;
} 
.mobilebtnces, .mobilefootercall{
    display:none;
}
//Responsive Design{
    @media only screen and (max-width:767px) {
        .copyrightfooter{
            ul{
                padding:0;
                margin-bottom:50px;
            }
        }
        .mobilefootercall{
            position: fixed;
            bottom: 0;
            left: 0;
            right: 0;
            display: inline-block;
            background:$linkedin;
            padding:8px 0;
            .bookdemomobile{
                background:#D78720;
                color:$white;
                margin:0px 10px 10px;
                padding:10px 12px;
                border-radius:30px;
                display:inline-block;
                font-weight:bold;
            }
            ul{
                list-style:none;
                margin:0;
                padding:4px 0;
                li{
                    display:inline-block;
                    a{
                        cursor: pointer;
                    }
                    img{
                        width: 36px;
                        margin: 0 15px;
                    }
                }
            }
        }
        .allmenu{
            // position: relative;
            // min-height: 140px;
            // z-index: 9;
        }
        .topheader{
            display:none;
            position:relative;
            .headerinfo{
                padding: 0 6px;
                font-size:12px;
            }
        }
        .navbar {
            padding:0rem 0rem 0 !important;
        }
        .mobileheadermenu{
            .CryptoExchangeSolutionfreebtn{
                background: #FFAF48;
                border-radius: 22px;
                padding:1px 5px 0 0 !important;
                vertical-align:unset !important;
                margin:5px auto !important;
                width:180px !important;
                display:none;
                a{
                    padding: 3px 0 0 0 !important;
                }
            }
        }
        
        .usericonmobile{
            width: 30px;
            height: 30px;
            display: inline-block;
            margin: 1px 5px 4px;
            img{
                width: 28px;
                padding: 2px;
            }
        }
        .headermenu{
            display:none;
            .submenumain:hover {
                .innermenu{
                  min-width: 21rem;
                }
            }
            .navbar {
                padding:30px 0 0 5px;
            }
        }
       
        .mobileheadermenu{
            display:block;
            
            .dropmenu{
                background:$gradient-skyblue;
            }
        }  
        //Mobile Menu Start
        .navbar-brand{
            img{
                width:120px;
            }
        }
        .navbar{
            padding:0;
        }
        #navbarCollapse3{
            position: absolute;
            background:rgba($white,0.8);
            width: 100%;
            z-index: 999;
            padding:0 30px;
        }
        .ExchangeFeaturesBg{
            img{
                width:30px;
            }
        }
    }
    .innermenu {
        display: block;
        z-index: 10000;
    }



    //Mobile Menu Start By Manish
    .mobileheadermenu{
        .nav-item{
            color:$github;
            border-bottom:1px solid $darakwhite;
            > a {
                background:$lightwhite;
                color:$github;
                padding:6px 15px;
                display: block;
            }
          
          .collapse {
            background:$white;
          }
          ul{
            padding:3px 0;
                > li:first-child{
                    a{
                        color:$lightsky;
                        text-decoration:none;
                    }
                }
            }
        
            li{
                list-style:none;
                padding:3px 15px;
                a{
                    font-weight:bold;
                }
                ul{
                    padding:0 20px;
                    .innertitle{
                        list-style:circle;
                        a{
                            color:$darkblue;
                            font-weight:bold;
                            text-decoration:none;
                        }
                    }
                    li{
                        ul{
                            li:nth-child(1), li, .companymenu{
                                list-style: disc;
                                a{
                                    font-weight:normal;
                                    color:$darkblue;
                                    text-decoration:none;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    body.modal-open {
        overflow-y: scroll;
    }
    
    .modal-open{
        padding: 0 !important;
    }

   //common Blog Scss
   .commonblogsection{
        padding: 30px 0; 
    }

    .blogcommontitle{
        h3, h2{
            font-size:32px;
            color:$darkblue;
            text-align: left;
            font-weight: 700;
            font-style: normal;
        }
    }

    .commonblog{
        a{
            color:$github;
            &:hover{
                text-decoration:none;
            }
        }
        > div{
            > div {
                min-height: 300px;
            }
        }
    }

@media (min-width: 768px) and (max-width: 1024px) {
    .headermenu{
        .navbar-nav{
            .nav-item{
                min-width:88px;
            }
        }
    }
    .FooterMain{
        padding-top: 20px;
        h6{
            font-size: 20px;
        }
    }
    .footersocial li a img {
        width: 20px;
    }
    .navbar-brand{
        .nav-link{
            padding:0;
        }
    }
    .navbar-expand-md {
        .navbar-nav{
            .nav-link{
                padding-right:0rem !important;
                padding-left:0rem !important;
            }
        }
    }
}


//Thank You Page Start
.Thankyoumain{
    padding: 150px 0 0 0;
    text-align: center;
    margin-bottom: 100px;
    h4{
        font-size:30px;
        color:$lightblue;
        font-weight:700;
        font-style:normal;
    }
    span{
        font-size:16px;
        width:100%;
        display: inline-block;
        padding: 0 0 10px;
    }
    .btnlink{
        background:$lightblue;
        color:$white;
        font-size:18px;
        padding: 10px 15px;
        border-radius: 3px;
        &:hover{
            text-decoration:none;
        }
    }
    .thankyousocial{
        padding:0;
        li{
            display: inline-block;
            padding: 20px 15px 30px 0;
            a{
                display: inline-block;
                img{
                    width:30px;
                }
            }
        }
    }
}


.cryptofooter, .cryptocopyrightfooter{
    .FooterMain, .copyrightfooter{
        display: none;
    }
}

.allfooter, .allcopyrightfooter{
    .crynewfooter, .crpcopyrightfooter{
        display: none;
    }
}
.footerbtn{
    background-color:transparent !important;
    border:none !important;
    padding:0 !important;
    &:hover, &:focus{
        border:none !important;
        box-shadow:none !important;
    }
}
.contactusbtn{
    background-color:transparent !important;
    border:none !important;
    padding:0 !important;
    color:$darkblue !important;
    &:hover, &:focus{
        border:none !important;
        box-shadow:none !important;
        color:$lightblack !important;
    }
}

.freedemobtn{
    background:#FFAF48 !important;
    padding:7px 15px !important;
    //border:1px solid $white;
    &:hover{
        background:#f58a00 !important;
        text-decoration:none;
        color:$white;
    }
}

/*Heaser Icon Animation Start*/
.userheadricon {
    background-color:#BC9C16;
    width:0.5em;
    height:0.5em;
    border-radius: 50%;
    -webkit-animation: ripple1 1s linear infinite;
    animation: ripple1 1s linear infinite;
    top:40%;
    position:absolute;
    // left:20%;
    img{
        position:absolute;
        top:-14px;
        right:-13px;
    }
}
@keyframes ripple1 {
    0% {
        -webkit-box-shadow: 0 0 0 0 rgba(188,156,22, 0.3), 0 0 0 0.5em rgba(101, 255, 120, 0.3), 0 0 0 3em rgba(101, 255, 120, 0.3), 0 0 0 5em rgba(101, 255, 120, 0.3);
        box-shadow: 0 0 0 0 rgba(188,156,22, 0.3),
        0 0 0 0.5em rgba(188,156,22, 0.3),
        0 0 0 0.7em rgba(188,156,22, 0.3),
        0 0 0 1em rgba(188,156,22, 0.3);
    }
    100% {

        box-shadow: 0 0 0 0.5em rgba(188,156,22, 0.3),
        0 0 0 1em rgba(188,156,22, 0.3),
        0 0 0 1.4em rgba(188,156,22, 0.3),
        0 0 0 2em rgba(188,156,22, 0);
    }
}


//certificate image and title Section 
.certificatetitle {
    h2{
        font-size: 32px;
        color:$darkblue;
        text-align: left;
        font-weight: 700;
        font-style: normal;
        padding:30px 0 0 0;
    }
}
.certificateshortbox{
    background-color: #FFFFFF;
    text-align: center;
    padding:10px 10px;
    color:$darkblue;
    box-shadow: 15px 10px 30px rgba(0, 0, 0, 0.2);
    -webkit-transition: all 0.3s ease-in-out 0s;
    transition: all 0.3s ease-in-out 0s;
    margin:30px 0 50px;
    min-height:200px;
    img{
        width:100px;
        margin-bottom:10px;
    }
    p{
        font-size:18px;
    }
}

.Certificatescommbg{
    background:$lightwhite;
    padding:20px 0;
    
}
.faqmaintitle{
    font-size: 32px;
    color: $darkblue;
    text-align: left;
    font-weight: 700;
    font-style: normal;
    padding:20px 0 0;
}