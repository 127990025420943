//Common Main Title Start
.Aboutustitle{
    padding:70px 0 10px;
    h2, h3, h4, h5{
        font-size:32px;
        color:$darkblue;
        text-align: left;
        font-weight: 700;
        font-style: normal;
    }
    p{
        font-size:16px;
        color:$github;
    }
}
//About us Banner Start
.AboutusBanner-bg{
    padding:20px 0;
    // background-image:url(Images/features-bg.jpg);
    // background-size: cover;
    // background-position:center;

    .jumbotron{
        background:transparent;
        padding:0;
        margin:0;
    }
    .bannerdetails{
        text-align: center;
        padding:50px 0 0;
        h1{
            font-size:30px;
            color:$darkblue;
            line-height:50px;
            text-align:center;
            font-weight:700;
            font-style:normal;
        }
        p{
            font-size:20px;
            color:$darkblue;
            line-height:36px;
            text-align:center;
        }
        button{
            background:$gradient-skyblue;
            border-radius:30px;
            padding:10px 30px;
            color:$white;
            border:none;
            margin-top:30px;
            &:hover{
               background:$gradient-bluesky;
            }
        }
    }
}

//Trading Software Development Experts.
.AboutusTrading-bg{
    background:$lightwhite;
    padding:20px 0;

    .AboutusTradingList{
        ul{
            padding: 0;
            li{
                padding-bottom:20px;
                font-size:16px;
                list-style: none;
                padding-left:50px;
                position: relative;
                &:after{
                    background: url(Images/itemright.png);
                    content: "";
                    position: absolute;
                    left:0;
                    right: 0;
                    top:3px;
                    margin: 0;
                    width: 30px;
                    height: 30px;
                    background-repeat: no-repeat;
                }
            }
        }
        a{
            background:$gradient-skyblue;
            color:$white;
            border-radius: 30px;
            padding: 10px 30px;
            border: none;
            margin-top: 0;
            display: inline-block;
            margin-bottom: 20px;
            &:hover{
                background:$gradient-bluesky;
                color:$white;
                text-decoration:none;
            }
        }
    }
}

//What Makes Espay Exchange Special?
.AboutMakeSpeciallist{
    li{
        color:$github;
        font-size:15px;
        padding:15px 0;
    }
}

//How Espay Exchange Work ?
.AboutusWork{
    text-align:center;
    img{
        width:60px;
    }
    p, h3{
        font-size: 22px;
        color:$github;
        padding:10px 0;
    }
}

@media only screen and (max-width : 767px) {
    .AboutusBanner-bg {
        padding: 0 0 50px;
    }
}