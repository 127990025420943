// Home Banner 
.Guestpostbanner_bg{
    background-image:url('Images/HeaderBanner.jpg');
    padding-top:120px;
    padding-bottom:20px;
    position:relative;
    h1{
        color:$darkblue;
        text-align: left;
        font-weight: 700;
        font-style: normal;
        font-size: 36px;
        padding-top:120px;
    }
    p{
        color:$darkblue;
        text-align: left;
        font-size: 26px;
        line-height: 36px;
    }
}
.Guestpostabout{
    padding:50px 0;
    span{
        font-size:28px;
        font-weight:700;
        color:$darkblue;
        line-height: normal;
        padding-bottom: 30px;
        display: inline-block;
    }
    p{
        font-size:16px;
    }
}

.Contributorsbg{
    background:$bannerlightgray;
    padding:50px 0;
    h2{
        font-size:32px;
        font-weight:700;
        color:$darkblue;
        line-height: normal;
        display: inline-block;
        margin-bottom:15px;
    }
    p{
        font-size:16px;
    }
    .notedes{
        padding-top:20px;
        font-weight:bold;
    }
    span{
        font-size:24px;
        font-weight:700;
        color:$darkblue;
        line-height: normal;
        padding-top:20px;
        padding-bottom:10px;
        display: inline-block;
    }
    .middlepart{
        margin:40px auto;
        text-align: center;
        i{
            border-left:1px solid $timelinebg;
            min-height: 250px;
            display: inline-block;
        }
    }
    ul{ padding:0 20px;
        li{
            font-size:16px;
            padding: 0 0 10px 0;
            list-style: initial;
        }
    }
}

.whywriteexchange{
    padding:50px 0;
    span{
        font-size:24px;
        font-weight:700;
        color:$darkblue;
        line-height: normal;
        padding-top:20px;
        padding-bottom:20px;
        display: inline-block;
    }
    h4{
        font-size:18px;
        font-weight:700;
        color:$darkblue;
        line-height: normal;
        padding-top:20px;
        padding-bottom:20px;
        display: inline-block;
    }
    h5{
        font-size:16px;
        padding:30px 0 10px 0;
        b{
            font-size:18px;
        }
    }
    ul{ padding:0 20px;
        li{
            font-size:16px;
            padding: 0 0 10px 0;
            list-style: circle;
        }
    }
}