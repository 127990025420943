::-webkit-scrollbar {
  width:5px;
}
::-webkit-scrollbar-thumb {
  background:rgba(0,0,0,0.5); 
  border-radius: 10px;
}
::-moz-scrollbar {
  width:5px;
}
::-moz-scrollbar-thumb {
  background:rgba(0,0,0,0.5); 
  border-radius: 10px;
}

.faqbanner{
    padding:100px 0;
    background:url(Images/features-bg.jpg);
    background-size: cover;
}

.faqbannerdetails{
      text-align: center;
      padding:50px 0 0;

      .jumbotron{
          background:transparent;
          padding:0;
          margin:0;
      h1{
          font-size:30px;
          color:$darkblue;
          line-height:50px;
          text-align:center;
          font-weight:700;
          font-style:normal;
        }
        p{
          font-size:20px;
          color:$darkblue;
          line-height:36px;
          text-align:center;
      }
    }
  }

.accordion{
    .accordion-item{
      .panel{
        div{
          padding: 15px;
          p{ 
            // margin-bottom:5px;
            a{
              &:hover{
                text-decoration:none;
              }
            }
          }
        }
      }
    }
  }

.main{
  padding:100px 0 68px 0;
  .topinnertitle{
    text-align:center;
    // border-bottom: 1px solid;  
    padding: 20px 0 20px 0;
  }
  .button_groupmain{
      position: relative;;
      top:65px;
      left:0px;
      width:200px;
      border-right:1px solid $github;
        .button-faq{
            display: block;
            width:200px;
            font-weight: bold;
            font-size: 14px;
            color: #424242;
            padding:8px 5px;
            border: none !important;
            background: transparent;
            text-align: left;
            border-bottom: 1px solid !important;
            // background: $white;
              &:hover{
                opacity: .6;
                font-weight: 700;
                color: $black;
                cursor: pointer;
              }
              &:focus , &:active{
                opacity: .6;
                font-weight: 700;
                color: $black;
                border-bottom: 1px solid $black; 
                color: inherit;
                text-decoration: none;
                background: linear-gradient(#fff,#fff),linear-gradient(#fff,#fff),linear-gradient(#1da1f2,#1da1f2);
                background-position-x: 0%, 0%, 0%;
                background-position-y: 0%, 0%, 0%;
                background-repeat: repeat, repeat, repeat;
                background-size: auto auto, auto auto, auto auto;
                background-size: .05em 2px,.05em 2px,2px 2px;
                background-repeat: no-repeat,no-repeat,repeat-x;
                text-shadow: .03em 0 #fff,-.03em 0 #fff,0 .03em #fff,0 -.03em #fff,.06em 0 #fff,-.06em 0 #fff,.09em 0 #fff,-.09em 0 #fff,.12em 0 #fff,-.12em 0 #fff,.15em 0 #fff,-.15em 0 #fff;
                background-position: 0 98%,100% 98%;
              }
        }
      } 
    }
  
  .innerfaq1content {
      
      .topinnertitle{
        text-align: left;
        border: 1px solid;
      }
      .faqdiffbor{
        border-bottom: 1px solid;
      }
      .firstinnerfaq{
        h5{
          font-weight: 600;
          padding: 15px 0 20px 0;
        }
        }
        p{
          font-size: 15px;
        }
        h4{
            font-size: 20px;
            font-weight: 700;
          }
          ul{
            list-style: disclosure-closed;
          }
        }

    .innerfaq2content {
      padding-top: 20px;
      .faqdiffbor{
        border-bottom: 1px solid;
      }
      h4{
          font-size: 20px;
          font-weight: 700;
        }
        ul{
          list-style: disclosure-closed;
        }
    }

    .innerfaq3content {
      .faqdiffbor{
        border-bottom: 1px solid;
      }
      padding-top: 20px;
      h4{
          font-size: 20px;
          font-weight: 700;
        }
        ul{
          list-style: disclosure-closed;
        }
    }

    .innerfaq4content {
      padding-top: 20px;
      .faqdiffbor{
        border-bottom: 1px solid;
      }
      p{
        font-size: 15px;
      }
      h4{
          font-size: 20px;
          font-weight: 700;
        }
        ul{
          list-style: disclosure-closed;
        }
    }

    .innerfaq5content {
      padding-top: 20px;
      .faqdiffbor{
        border-bottom: 1px solid;
      }
      p{
        font-size: 15px;
      }
      h4{
          font-size: 20px;
          font-weight: 700;
        }
        ul{
          list-style: disclosure-closed;
        }
    }

    .innerfaq6content {
      padding-top: 20px;
      .faqdiffbor{
        border-bottom: 1px solid;
          img{
            width: 100%;;
          }
      }
      p{
        font-size: 15px;
      }
      h4{
          font-size: 20px;
          font-weight: 700;
        }
        ul{
          list-style: disclosure-closed;
        }
    }

    .innerfaq7content {
      padding-top: 20px;
      .faqdiffbor{
        border-bottom: 1px solid;
      }
      p{
        font-size: 15px;
      }
      h4{
          font-size: 20px;
          font-weight: 700;
        }
        ul{
          list-style: disclosure-closed;
        }
    }
    .faqbannerque{
      height:300px !important;
    }

.Faqtable{
  tr{
    th{
      background:#4373A7;
      color:$white;
      border-right: 1px solid;
      text-align: center;
    }
    td{
      font-size:12px;
      border: 1px solid #4373A7;
      padding: 0 10px;
    }
    // tr:first-child{
    //   background:#fac47d;
    // }
  }
}

.leftpanelmain{
  overflow-y: scroll;
  overflow-x: hidden;
  width: 13%;
  max-height:600px;
  min-height:450px;
  position: fixed;
  top:40px;
}
.faqmainbox{
  min-height:300px;
  max-height:300px;
  overflow-y:scroll;
  clear: both;
  height:100%;
}
//Media Query
@media only screen and (max-width:767px) {
  .main{
    padding-top: 0;
  .button_group{
        position: relative;
        left: 90px;    
        bottom: 15px;
      } 
    }
  }



#panel1a-header{
  //background:#f8f7f1;
}
#panel1a-header p{
  font-size:16px;
  font-weight:bold;
  color:$lightblack;
}
.faqleftmain{
  padding:8px 20px 0 !important;
}
  .Faqlinks{
    position:relative;
    //height:200px;
    //overflow-y:scroll;
    a{
      width: 100%;
      display: inline-block;
      padding: 10px 0;
      font-size: 16px;  
      cursor: pointer;
      padding:0 0px 15px 20px;
      &:hover{
        text-decoration:none;
        color:$lightorange;
      }
      &:last-child{
        padding:0 0px 0 20px;
      }
    }
  }

.topsubtitlefaq{
  font-size:18px;
  margin:0 0 10px;
  font-weight:bold;
}

.faqimg{
  margin:0 0 20px;
  width:80%;
}
.faqstrongtitle{
  font-size:18px;
  margin:20px 0;
  display:inline-block;
}
.main{
  ul{
    li{
      margin:10px 0;
    }
  }
}

.faqactive{
  color:$lightorange;
}
.facility-addons {
  height: 500px;
  overflow-x: hidden;
  overflow-y: scroll;
  > div{
    box-shadow: none !important;
    &::before{
      background: none;
    }
  }
}
