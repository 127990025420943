.winter-is-coming, .snow {
  //  z-index: -99999;
  pointer-events: none;
}

.winter-is-coming {
  overflow: hidden;
  position: absolute;
  top: 60px;
  height: 100%;
  width: 100%;
  max-width: 100%;
  background: transparent;
  left: 0;
  transform: rotate(180deg);
}

.snow {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  -webkit-animation: falling linear infinite both;
          animation: falling linear infinite both;
  -webkit-transform: translate3D(0, -100%, 0);
          transform: translate3D(0, -100%, 0);
}
.snow--near {
  -webkit-animation-duration: 10s;
          animation-duration: 10s;
  background-image: url("https://dl6rt3mwcjzxg.cloudfront.net/assets/snow/snow-large-075d267ecbc42e3564c8ed43516dd557.png");
  background-size: contain;
}
.snow--near + .snow--alt {
  -webkit-animation-delay: 5s;
          animation-delay: 5s;
}
.snow--mid {
  -webkit-animation-duration: 20s;
          animation-duration: 20s;
  background-image: url("https://dl6rt3mwcjzxg.cloudfront.net/assets/snow/snow-medium-0b8a5e0732315b68e1f54185be7a1ad9.png");
  background-size: contain;
}
.snow--mid + .snow--alt {
  -webkit-animation-delay: 10s;
          animation-delay: 10s;
}
.snow--far {
  -webkit-animation-duration: 30s;
          animation-duration: 30s;
  background-image: url("https://dl6rt3mwcjzxg.cloudfront.net/assets/snow/snow-small-1ecd03b1fce08c24e064ff8c0a72c519.png");
  background-size: contain;
}
.snow--far + .snow--alt {
  -webkit-animation-delay: 15s;
          animation-delay: 15s;
}

@-webkit-keyframes falling {
  0% {
    -webkit-transform: translate3D(-7.5%, -100%, 0);
            transform: translate3D(-7.5%, -100%, 0);
  }
  100% {
    -webkit-transform: translate3D(7.5%, 100%, 0);
            transform: translate3D(7.5%, 100%, 0);
  }
}

@keyframes falling {
  0% {
    -webkit-transform: translate3D(-7.5%, -100%, 0);
            transform: translate3D(-7.5%, -100%, 0);
  }
  100% {
    -webkit-transform: translate3D(7.5%, 100%, 0);
            transform: translate3D(7.5%, 100%, 0);
  }
}


////////////////////////////////////////////////////////////////


.apps-craft-welcome-section {
  // background-color: #F4F4F4;
  height: 950px;
}
.apps-craft-welcome-section {
  position: relative;
  overflow: hidden;
  // height: 1060px;
  height: 900px;
  background-position: center center;
  background-repeat: no-repeat;
  // min-height: 700px;
  // background-color: #222838;
}


.apps-craft-welcome-section-v19::before {

  bottom: 15vh;
  border-radius: 45%;
  -webkit-animation-duration: 10s;
  animation-duration: 10s;

}

.apps-craft-welcome-section-v19::before {
  content: "";
  position: absolute;
  left: 50%;
  min-width: 300vw;
  min-height: 300vw;
  background-image:  linear-gradient(to right, #4470a6 , #28d1ca);
  -webkit-animation-name: rotate;
  animation-name: rotate;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
}

.apps-craft-welcome-section-v19::after {
  content: "";
  position: absolute;
  left: 50%;
  min-width: 300vw;
  min-height: 300vw;
  background-image:  linear-gradient(to right, #4470a6 , #28d1ca);
  -webkit-animation-name: rotate;
  animation-name: rotate;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  z-index: -1;
}
.apps-craft-welcome-section-v19::after {

  bottom: 12vh;
  opacity: .5;
  border-radius: 47%;
  -webkit-animation-duration: 10s;
  animation-duration: 10s;

}



@keyframes rotate {
  0% {
    -webkit-transform: translate(-50%, 0) rotateZ(0deg);
            transform: translate(-50%, 0) rotateZ(0deg);
  }
  50% {
    -webkit-transform: translate(-50%, -2%) rotateZ(180deg);
            transform: translate(-50%, -2%) rotateZ(180deg);
  }
  100% {
    -webkit-transform: translate(-50%, 0%) rotateZ(360deg);
            transform: translate(-50%, 0%) rotateZ(360deg);
  }
}


.parag{
  padding: 150px 0px 0px 0px;
  .bannerdetails{
    text-align: left;
    padding:50px 0 0;
    h1{
        font-size:34px;
        color:$white;
        line-height:45px;
        text-align:left;
        font-weight:700;
        font-style:normal;
    }
    p{
        font-size:20px;
        color:$white;
        line-height:36px;
        text-align:left;
        padding-bottom: 20px;
    }
    a{
      background:$gradient-skyblue;
      border-radius:30px;
      padding:10px 30px;
      color:$white;
      border:none;
      margin-top:30px;
      &:hover{
          background:$gradient-bluesky;
      }
    }
  }
  img{
    width: 520px;
  }
}


//Powerfulfront
.Powerfulfront{
  .PowerfulfrontInfo{
    padding:50px 0;
    h4{
      font-size: 32px;
      color: $darkblue;
      text-align:left;
      font-weight:700;
      font-style: normal;
    }
  }
  .PowerfulfrontBox{
    text-align: center;
    padding-bottom: 25px;
    span{
        font-size:20px;
        width: 100%;
        display: inline-block;
        text-align: center;
        padding-top: 20px;
        a{
          text-decoration: none;
          color: $darkblue;
        }
    }
  }
}

//Artificial_Intelligence
.Artificial_Intelligence{
  padding: 10px 0px;
  background: $bannerlightgray;
  .Artificial_Intelligence_Info{
    padding:50px 0;
    h4{
      font-size: 30px;
      color: $darkblue;
      text-align:left;
      font-weight:700;
      font-style: normal;
    }
  }
  .Artificial_Intelligence_Box{
    text-align: center;
    padding-bottom: 25px;
    span{
        font-size:20px;
        width: 100%;
        display: inline-block;
        text-align: center;
        padding-top: 20px;
        a{
          text-decoration: none;
          color: $darkblue;
        }
    }
  }
}

// Exchanges_worldwide

.Exchanges_worldwide{

  padding:50px 0;
  background:url(Images/transbg.png);
  background-repeat: round;

  .Additionalfeature-content{
      h1{
          color:$darkblue;
          font-weight: bold;
          font-size: 32px;
      }
  }

  .flip-container {
      perspective: 1000;
      background:$white;
      margin: 35px auto;
      width: 320px;
      height: 200px;
      box-shadow: 15px 10px 30px rgba(0,0,0,.2);
      .flipper {
          transition: 0.6s;
          transform-style: preserve-3d;
          position: relative;
          text-align: center;
          
          .front {
          background: $white;
          backface-visibility: hidden;
          position: absolute;
          top: 0;
          left: 0;
          width: 320px;
          height: 200px;
          padding: 20px;
            img{
              width:100px;
            }
              h4{
                  padding:10px;
              }
          }
          .back {
              transform: rotateY(180deg);
              background: $gradient-skyblue;
              backface-visibility: hidden;
              position: absolute;
              top: 0;
              left: 0;
              width: 320px;
              height: 200px;
              padding: 20px;
              color: $white;
          }
      }


  }
  .flip-container:hover .flipper, .flip-container.hover .flipper {
      transform: rotateY(180deg);
  }
  .worldwide-btn{
    text-align: center;
    margin:30px 0px; 
  a{
   
    background:$gradient-bluesky;
    box-shadow: 0 2px 1px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.3);
    border-radius: 30px;
    padding: 15px 15px;
    color: #FFFFFF;
    font-size: 19px;
    border: none;
    &:hover{
      text-decoration: none;
      background:$gradient-skyblue;
    }
  }
  }
  
}

// Software_Development Section 
.Software_Development{
  padding:100px 0;
  background: url(Images/features-bg.jpg);
  background-size:cover;

  .Software_Development_heading{
      h2{
          font-size: 32px;
          color: $darkblue;
          text-align:left;
          font-weight:700;
          font-style: normal;
      }
      p{
          font-size:16px;
      }
  }

  .Software_Development_box{
      background-color:$white;
      box-shadow: 0 5px 30px rgba(0, 0, 0, 0.1);
      padding: 20px 20px;
      text-align: left;
      border-radius: 8px;
      margin: 10px 0 0 0;
      i{
          background:$lightsky;
      }
      h3{
          padding-top:10px;
          font-size:22px;
          color: $lightblack;
          margin-top:10px;
          margin-bottom:15px;
      }
      a{
          background:$gradient-skyblue;
          border-radius:30px;
          padding:6px 30px;
          color:$white;
          border:none;
          margin: 10px 0 0;
          display: inline-block;

          &:hover{
              background:$gradient-bluesky;
              text-decoration:none;
          }
      }
  }
}


/////////////  Responsive/////////////////////
@media only screen and (min-width: 320px) and (max-width: 680px)  {

  
  .parag{
    padding: 70px 0px 0px 0px;
    .bannerdetails{
      padding: 10px 0px;
    h1{
          font-size:20px;
          line-height:25px;
      }
      p{
          font-size:16px;
          line-height:22px;
      }
    }
  }

  .apps-craft-welcome-section {
    height: 455px;
  }

  //Powerfulfront
  .Powerfulfront{
    .PowerfulfrontInfo{
      padding:0px 0px 10px 0px;
      h4{
        font-size: 25px;
      }
    }
    
  }
}