//banner video

.banner_bg{
    padding-top: 50px 0px;
    
    .banner {
        margin-left: -15px;
        margin-right: -15px;
        text-align:center;
        #player {
            width: 100%;
        }
    }
}

//peoplecoin sec 10

.Peoplecoin{
    padding:55px 0;
    // background-image:url('./Images/peplecoin.png');
    background: #f8f8f8;
    background-repeat: round;
    background-attachment: fixed;
    
    .crypto_exchange{
        h2{
            color:$darkblue;
            text-align:center;
            font-size:29px;
            font-weight:700;
        }
        p{
            background:$white;
            padding:20px;
            text-align:center;
            font-size:20px;
            //box-shadow: 0 5px 30px rgba(0, 0, 0, 0.1);
            box-shadow:0 5px 10px #17205a;
            min-height:102px;
            padding-top:30px;
            border-radius:8px;
            margin-top:15px;
        }
    }

    .devlopment-btn{
        margin-top:50px; 
        
        .devlopment-btn2{
            font-size: 18px;
            background-color:$white;
            padding: 15px 19px;
            border-radius:25px;
            margin-right:20px;   
            text-decoration: none;
            font-weight: 600;
        }
        
    }
    .whychoose{
      text-align: center
    }
}


//Sec11

.Sec11{

    padding:30px 0px;
    
    .sec-11-heading{
        h2{
        font-size:32px;
        color: $darkblue;
        text-align: left;
        font-style: normal;
        font-weight: bold;
        }
        
        
    }
    .sec-11-section{
        padding:55px 0px;
            p{
                font-size:16px;
            }
        .spark-img{
            text-align: center;
             
            .spark{
                width:375px;
                height:260px;
                text-align: center;
            }
        }
    }
    .benefit-web{
        display: block;
        .sec-11-section{
            margin:55px 0px;
            h3{
                font-size:30px;
                color:$darkblue;
                padding: 0 0 15px;
                font-weight:700;
            }
            .spark-img{
                text-align: center;
                .box > img{
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    width: 450px;
                    height: 300px;
                    // background: #111845a6;
                    box-sizing: border-box;
                    overflow: hidden;
                    box-shadow: 0 20px 50px rgb(23, 32, 90);
                    // border: 2px solid #2a3cad;
                    color: white;
                    padding: 20px;
                
                .spark{
                    width:375px;
                    height:260px;
                    text-align: center;
                }
            }
            }
        }
    }
    .benefit-mob{
        display:none;
    }
}

//Sec12

.Sec12{

    padding:50px 0;
    background:url('./Images/transbg.png');
    background-repeat: round;

    .Additionalfeature-content{
        color:$white;
        h1{
            color:$white;
            font-size:32px;
            font-weight:700;
        }
    }

    .flip-container {
        perspective: 1000;
        background:rgba(0, 0, 0, 0.5);
        margin:30px auto 70px;
        // width: 320px;
        height: 284px;
        box-shadow: 15px 10px 30px rgba(0,0,0,.2);
        .flipper {
            transition: 0.6s;
            transform-style: preserve-3d;
            position: relative;
            text-align: center;
           .front1 {
            background-image: url('./Images/Landing/Challlenges2.jpg');
             backface-visibility: hidden;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 284px;
            text-align: center;
                h4{
                    padding:240px 10px 17px 10px;
                    font-size:22px;
                    color: $white;
                    font-weight: bold;
                    background:rgba(0,0,0,0.5);
                }
                
            }
            
            .front2 {
                background-image: url('./Images/Landing/Challlenges3.jpg');
                 backface-visibility: hidden;
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 284px;
                text-align: center;
                h4{
                    padding:240px 10px 17px 10px;
                    font-size:22px;
                    color: $white;
                    font-weight: bold;
                    background:rgba(0,0,0,0.5);
                }
            }
            .front3 {
                background-image: url('./Images/Landing/Challlenges1.jpg');
                 backface-visibility: hidden;
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 284px;
                text-align: center;
                h4{
                    padding:241px 10px 17px 10px;
                    font-size:22px;
                    color: $white;
                    font-weight: bold;
                    background:rgba(0,0,0,0.5);
                    width:100%
                }
            }
            .front4 {
                background-image: url('./Images/Landing/Challlenges4.jpg');
                 backface-visibility: hidden;
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 284px;
                text-align: center;
                h4{
                    padding:240px 10px 17px 10px;
                    font-size:22px;
                    color: $white;
                    font-weight: bold;
                    background:rgba(0,0,0,0.5);
                }
            }
                
            .back {
                transform: rotateY(180deg);
                //background: $gradient-skyblue;
                backface-visibility: hidden;
                position: absolute;
                top: 0;
                left: 0;
                //width: 320px;
                height: 284px;
                padding: 20px;
                color: $white;
                p{
                    font-size: 17px;
                }
            }
        }


    }
    .flip-container:hover .flipper, .flip-container.hover .flipper {
        transform: rotateY(180deg);
    }
      
    
}

//sec14

.Sec14{
    background: #f8f8f8;
    padding: 50px 0px;
    .ladingsection2{
        h2 {
            padding-top:30px;
            padding-bottom:30px;
            color:$darkblue;
            text-align: left;
            font-size:30px;
            font-weight: 700;
            font-style: normal;
        }
        .esversion{
            width: 100%;
        }
    }
}



//Responsive


@media only screen and (max-width : 500px) {

      //Sec12
  
    //   .Sec12{
    //     .flip-container {
            
    //         margin: 10px auto !important;
    //         width: 260px !important;
    //         .flipper {
    //            .front1 {
    //             width: 260px !important;
    //             }
    //             .front2 {
    //                 width: 260px !important;
    //             }
    //             .front3 {
    //                 width: 260px !important;
    //             }
    //             .front4 {
    //                 width: 260px !important;
    //             }
    //             .back {
    //                 width: 260px !important;
    //                     p{
    //                         font-size: 17px !important;
    //                     }
    //             }
    //         }
    //     }
    // }
}


@media only screen and (max-width : 768px) {

  //peoplecoin

    .Peoplecoin{
        padding:10px 0;
       
        .Development{
            h1{
               font-size: 25px;
            }
            p{
                text-align:center;
            }
            
        }
       
    }
 
    //Sec 11
    .Sec11{

        padding:30px 0px;
        
        .sec-11-heading{
            h3{
            font-size: 25px;
            }
        }
        .sec-11-section{
            h3{
                color:$darkblue;
                margin: 20px 0 10px;
            }
            p{
                font-size:16px;
            }
            padding: 10px 0px;
            .spark-img{
                .spark{
                    width:250px;
                    height: 200px;
                   
                }
            }
        }
    }
    
    //Sec12
    .Sec12{
        padding:30px 0;
        background:url('./Images/transbg.png');
        background-repeat: round;
    
        .Additionalfeature-content{
            h1{
                color:$white;
                font-size:32px;
                font-weight:700;
            }
        }
    
        .flip-container {
            perspective: 1000;
            //background:$white;
            margin:30px auto;
            // width:255px;
            // height: 284px;
            box-shadow: 15px 10px 30px rgba(0,0,0,.2);
            .flipper {
                transition: 0.6s;
                transform-style: preserve-3d;
                position: relative;
                text-align: center;
               .front1 {
                padding:0px 0px;
                height:auto;
                width:100%;
                background-size:cover;
                    h4{
                        padding:120px 10px 10px 10px;
                        color: $white;
                        min-height: 284px;
                        margin: 0;
                    }
                }
                
                .front2 {
                    padding:0px 0px;
                    height:auto;
                    width:100%;
                    background-size:cover;
                        h4{
                            padding:120px 10px 10px 10px;
                            color: $white;
                            min-height: 284px;
                            margin: 0;
                        }
                }
                .front3 {
                    padding:0px 0px;
                    height:auto;
                    width:100%;
                    background-size:cover;
                        h4{
                            padding:120px 10px 10px 10px;
                            color: $white;
                            min-height: 284px;
                            margin: 0;
                        }
                }
                .front4 {
                    padding:0px 0px;
                    height:auto;
                    width:100%;
                    background-size:cover;
                        h4{
                            padding:120px 10px 10px 10px;
                            color: $white;
                            min-height: 284px;
                            margin: 0;
                        }
                }
                    
                .back {
                    // width: 172px;
                    // height: 284px;
                    // padding: 20px;
                    color: $white;
                    p{
                        font-size: 13px;
                    }
                }
            }  
        }
        .flip-container:hover .flipper, .flip-container.hover .flipper {
            transform: rotateY(180deg);
        }             
    }

    //sec14
    .Sec14{
        padding: 50px 0px;
        background: #f8f8f8;
        .ladingsection2{
            h2 {
                font-size: 24px;
                font-weight: 700;
            }
        }
    }
}

.Trading-btn{
    text-align: center;
        // a {
        //     color: #007bff;
        //     background: #fff;
        //     padding:15px 20px;
        //     border-radius:30px;
        //     font-size:20px;
        //         &:hover{
        //             text-decoration: none;
        //         }
        // }
        a{
            background:$lightorange;
            font-weight:bold;
            border-radius:30px;
            padding:15px 30px;
            color:$white;
            border:none;
            margin-top:30px;
            display:inline-block;
            &:hover{
               background:$gradient-bluesky;
               text-decoration:none;
               color:$white;
               box-shadow:0 5px 10px #17205a;
            }
        }
}

.devlopment_last{
    text-align: center;
    margin-top: 50px;
    a{
        background:$gradient-skyblue;
        font-weight:bold;
        border-radius:30px;
        padding:15px 30px;
        color:$white;
        border:none;
        margin-top:30px;
        display:inline-block;
        &:hover{
           background:$gradient-bluesky;
           box-shadow: 0 5px 10px #17205a;
           text-decoration:none;
           color:$white;
        }
    }
}

@media only screen and (min-width: 320px) and (max-width: 680px)  {
    .Trading-btn{
        padding-top: 50px;
    }
}

@media only screen and (max-width :767px) {

    //Sec11

    .Sec11{
        padding:30px 0px;
        .benefit-web{
            display: none;
            
        }
        .benefit-mob{
            display:block;
            .sec-11-section{
                padding:40px 0px;
                .spark-img{
                    text-align: center;
                    margin: 50px 0px 125px 0px;
                    .box{
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        width:100% !important;
                        // height: 196px !important;
                        // background: #111845a6;
                        box-sizing: border-box;
                        overflow: hidden;
                        box-shadow: 0 20px 50px rgb(23, 32, 90);
                        // border: 2px solid #2a3cad;
                        color: white;
                        padding: 20px;
                    
                        .spark{
                            width:100% !important;
                            height:150px !important;
                            text-align: center;
                        }
                    }
                }
            }
        }
    }
}