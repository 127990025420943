.blur{
  //background: url(https://static.canva.com/static/images/bg_tiles.jpg) no-repeat;
  //background:url(Images/transbg.png);
  background:url(Images/Landing/landing-11.jpg);
  
  background-size: cover;
  height: 750px;
  padding:120px 0px 100px 0px;

  .banner-heading{
    margin-top:100px;
    h1{
      font-size:36px;
      font-weight: bold;
      //color: $rss;
      color:#FFAF48;
      margin-bottom:20px;
    }
    p{
      color: $white;
      font-size: 20px;
      font-weight: 500;
    }
  }
  .banner-content{
    h1{
      font-size: 25px;
      font-weight: bold;
      //color: $rss;
      color:#ffaf48;
    }
    ul{
      margin-top:20px;
    
     li{
        padding:5px;
        color: $white;
        font-size:18px;
        font-weight: bold;
        margin-bottom: 5px;
      }
    }
    
  }
  .landing-form{
    
    //padding: 6% 14% 2% 15% ;
    //background: #f8f8f8 ;
    padding:15px;
    //margin: 0px 95px 0px 53px ;
    background: rgba(0,0,0,0.5);
    //border:5px solid $rss;
   // border-radius: 10px;
    .form-control{
      // height: 30px;
      // padding: 5px;
      // font-size: 15px;
      background: transparent !important;
      
      select{
        background: transparent !important;
        color:$white !important;
      }
    }
    .form-group{
      input[type='text'], textarea{
        background: transparent !important;
        color:$white !important;
        border-radius: 0;
      }
      select{
        background:rgba(0,0,0,0.5) !important;
        color:$white !important;
      }
    }
    .contactsendbutton {
      width: 40%;
      border: none;
      
      padding: 10px;
      //background: $gradient-skyblue;
      background:#FFAF48;
      font-weight: 600;
      color: $white;
      cursor: pointer;
      margin-top: 20px;
          // &:hover{
          //     background: $gradient-bluesky;
          // }
    }  
  }
}

.thankubg{
  background:url(Images/Landing/landing-11.jpg);
  background-size: cover;
  height:500px;
  padding:190px 0px 0px 0px;
}
.thankyou-content{
  color:white;
  text-align:center;

  h1{
    font-weight:bold;
  }
  a, a:hover{
    text-decoration: none;
    background-color: #ffaf48;
    border: none;
    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.1), 0 4px 8px rgba(0, 0, 0, 0.3);
    color: white;
    padding: 10px 30px;
    font-size: 20px;
    margin: 20px 0 0 0;
    display: inline-block;
  }
}

.bolb{
  padding-left:10px; 
  position: relative;
  width: 436px;
  height: 436px;
  border-radius: 100%;
  background-color: #cf8989;
  animation: rotate 6s 0.2s linear infinite;
  transform-origin: 52% 52%;
  -webkit-transform-origin: 52% 52%;
  margin-top: 10px;
  display: block;
  margin-left: 80px;
  opacity: 0.7;
}
.bolb::before, .bolb::after{
  content: "";
  background-color: #000000;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  left: 0;
  right: 0;
  border-radius: 100%;
 width: 430px;
  height:430px;

}
.bolb::after{
  animation: rotate 6s 0.5s linear infinite;
  transform-origin: 52% 52%;
}
.bolb::before{
  animation: rotate 4s 0.5s linear infinite;
 transform-origin: 48% 52%;
}
@keyframes rotate{
  to{
    transform: rotate(360deg);
  }
}



////////////////////////// Responsive  ///////////////////////

@media only screen and (max-width:767px) {
  .blur{
    height: 1150px !important;
    padding: 20px 0px;
  
    .banner-heading{
      margin: 10px 0px 15px 0px !important;
      h1{
        font-size:30px;
        font-weight: bold;
        margin-bottom: 20px;
      }
      p{
        font-size:16px;
        font-weight: 500;
        margin-bottom: 15px;
      }
    }
    .banner-content{
      margin:25px 0px 15px 0px !important;
      h1{
        font-size: 20px;
        font-weight: bold;
      }
      p{
        font-size: 18px !important;
        font-weight: 500;
        margin-bottom: 0px;
        padding: 3px;
      }
      ul{
        li{
          font-size:18px;
        }
      }
    }
    .landing-form{
      margin: 0px 1px 0px 5px !important;
      background: rgba(0,0,0,0.5);
      opacity: 0.9;
      .contactsendbutton {
        width: 40% !important;
      }  
    }
  }
  .bolb{
   display: none;
  }
  .thankubg {
    padding: 140px 0px 0px 0px;
  }
}


// @media only screen and (max-width: 1400px) {
//   .blur{
//     background-size: cover;
//     height: 654px;
//     padding: 100px 0px;
  
//     .banner-heading{
//       margin: 25px 25px 15px 25px;
//       h1{
//         font-size: 33px;
//         font-weight: bold;
//         //color: $rss;
//         color:#FFAF48;
//         margin-bottom: 20px;
//         //background: -webkit-linear-gradient(top,#ff9800, #fff);
//         //background-clip: text;
//         //-webkit-text-fill-color: transparent;
//         // line-height: 60px;
//         padding:20px 0px 10px 0px;
//         font-family: Arial, Helvetica, sans-serif;
//       }
//       p{
//         font-size: 18px;
//         font-weight: 500;
//         margin-bottom: 15px;
//       }
//     }
//     .banner-content{
//       margin:0px 25px 25px 25px;
//       h1{
//         font-size: 20px;
//         font-weight: bold;
//       }
//       li{
//         font-size: 20px;
//         font-weight: 500;
//         margin-bottom: 0px;
//         padding: 3px;
//         color:#ffffff;
//       }
//     }
//     .landing-form{
      
//       padding: 6% 14% 2% 15% ;
//       background: #f8f8f8 ;
//       margin: 0px 95px 0px 53px ;
//       border:5px solid $rss;
//       border-radius: 10px;
      
//       .form-control{
//         height: 30px;
//         padding: 5px;
//         font-size: 15px;
//         border-radius: 0px !important;
//       }
//       .contactsendbutton {
//         width: 40%;
//       }
//     }
//   }
  
//   .bolb{
//     padding-left:10px; 
//     width: 415px;
//     height: 415px;
//     margin-top: 10px;
//     margin-left: 15px !important;
    
//   }
//   .bolb::before, .bolb::after{
//     width: 415px !important;
//     height:415px !important;
  
//   }
  
// }