$mision_bg : #f8f8f8;
$heading : #20126f;

.mision_section2{
    background-color:$mision_bg;
    padding-top: 50px;
    padding-bottom: 50px;

    h3{
        font-size: 32px;
        color:$heading;
        font-weight: 700;
        padding-bottom: 20px;
        padding-top: 20px;
    }

}