//Marketing Research Banner Start
.MarketingResearchBannermain{
    padding:90px 0 0;
    background-color:$comheading;
    position: relative;
    .bannerdetails{
        text-align:center;
        h1{
            font-size:46px;
            color:$white;
            line-height:50px;
            text-align:center;
            font-weight:700;
            font-style:normal;
            padding:50px 0 0;
        }
        span{
            width:100%;
            display:inline-block;
        }
    }
}

.tokenboxmain{
    padding:50px 0;
    p{
        font-size:16px;
    }
}
.differentareasmain{
    .differentareasbox{
        margin-bottom: 40px;
        display: block;
        text-align: center;
        width: 100%;
        p{
            font-size:16px;
        }
        h2{
            font-size: 32px;
            padding: 18px 40px;
            color:$white;
            position: relative;
            display: inline-block;
            background: $comheading;
            margin:0 0 40px;

            &::before{
                content: "";
                position: absolute;
                left: -49px;
                top: 0;
                background-image: url(Images/MarketingResearch/left-shape.png);
                width: 50px;
                height: 100%;
                background-repeat: no-repeat;
                background-size: 100% 100%;
            }
            &::after {
                content: "";
                position: absolute;
                right: -49px;
                top: 0;
                background-image: url(Images/MarketingResearch/right-shape.png);
                width: 50px;
                height: 100%;
                background-repeat: no-repeat;
                background-size: 100% 100%;
            }
        }
    }
    /* Area-Of-Currency */

}
.area-of-currency-img {
    float: left;
    width: 100%;
    background-image: url(Images/MarketingResearch/bb-2.png);
    min-height:685px;
    background-repeat: no-repeat;
    position: relative;
    background-size: contain;
    .area-heading {
        display: inline-block;
        width: 100%;
        text-align: center;
        h3{
            font-size:21px;
            text-align:center;
            padding:4px 25px 10px;
            color:$white;
            background:#082966;
            display:inline-block;
            position:relative;
            &::before{
                content: "";
                position:absolute;
                left:-13px;
                top:0;
                background-image: url(Images/MarketingResearch/left-small-shape.png);
                width:14px;
                height:100%;
                background-repeat:no-repeat;
                background-size:100% 100%;
            }
            &::after{
                content: "";
                position: absolute;
                right: -13px;
                top: 0;
                background-image: url(Images/MarketingResearch/right-small-shape.png);
                width: 14px;
                height: 100%;
                background-repeat: no-repeat;
                background-size: 100% 100%;
            }
        }
    }

    .area-boxs {
        width: 325px;
        position: absolute;
    }
    .area-box-Exchange {
        top: 16.3%;
        left: 2.6%;
    }
    .area-box-Sto {
        right: 0;
        top: 17%;
        width: 250px;
    }
    .area-box-Cryptocurrency {
        bottom: 27%;
        width: 250px;
    }
    .area-box-Ste {
        right: 0;
        bottom: 32%;
        width: 250px;
    }
    .area-boxs.area-box-ico {
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        -moz-transform: translateX(-50%);
        -webkit-transform: translateX(-50%);
    }
    .area-box-content {
        p{
            text-align: center;
            font-size: 14px;
            line-height: 18px;
            margin-top: 25px;
        }
       
    }
    
}
@media only screen and (max-width:767px) {
    .MarketingResearchBannermain{
        padding:0px 0 0;
        .bannerdetails{
            h1{
                font-size:22px;
                line-height: 30px;
                padding: 20px 0 0;
            }
        }
    }
    .area-of-currency-img{
        background-image:none;
        .area-boxs {
            position: relative;
            margin: 0 auto;
            width:100%;
            .area-heading{
                h3{
                    width:100%;
                }
            }
        }
    }
}

//What Are Tokens? Start

.tokens-bg{
    background: #000063;
    padding:70px 0;
    .heading-title {
        margin-bottom: 40px;
        display: block;
        text-align: center;
        width:100%;
        h2{
            background:#311b92;
            padding: 18px 40px;
            color: $white;
            position: relative;
            display: inline-block;
            margin:0 0 30px;
            font-size:32px;
            &::before {
                content: "";
                position: absolute;
                left: -49px;
                top: 0;
                background-image: url(Images/MarketingResearch/left-shape-b.png);
                width: 50px;
                height: 100%;
                background-repeat: no-repeat;
                background-size: 100% 100%;
            }
            &::after {
                content: "";
                position: absolute;
                right: -49px;
                top: 0;
                background-image: url(Images/MarketingResearch/right-shape-b.png);
                width: 50px;
                height: 100%;
                background-repeat: no-repeat;
                background-size: 100% 100%;
            }
        }
        p{
            color:$white;
            font-size:16px;
        }
    }

    .tokens-box {
        display:inline-block;
        width:100%;
        color:$white;
        text-align:center;
        h3{
            font-size: 28px;
            &::after{
                content: "";
                position: absolute;
                left: 0;
                bottom: -35px;
                background-image: url(Images/MarketingResearch/heading-bottom.png);
                width: 400px;
                right: 0;
                margin: 0 auto;
                height: 100%;
                background-repeat: no-repeat;
                background-size: contain;
            }
        }
    }
    .tokensleftright-box{
        margin: 60px 0 0;
        text-align: center;
        color:$white;
        h3{
            font-size: 28px;
            color:$white;
            padding:0 0 30px;
        }
      
    }
}
@media only screen and (max-width:767px) {
    .tokens-bg {
        .heading-title{
            h2::after, h2::before{
                background-image:none;
                left:0;
                right:0;
            }
        }
        .tokens-box{
            h3{
                &::after{
                    background-image:none;
                    border-bottom: 2px solid $white;
                    width:100%;
                }
            }
        }
    }
}

//Utility Tokens and Security Tokens

.utilitysecuritytokens-bg{
    padding:70px 0 70px;
    .heading-title {
        display:block;
        text-align:center;
        width:100%;
        h2{
            padding:18px 40px;
            color:$white;
            font-size:32px;
            position:relative;
            display:inline-block;
            background:$comheading;
            &::before {
                content: "";
                position:absolute;
                left:-49px;
                top:0;
                background-image: url(Images/MarketingResearch/left-shape.png);
                width:50px;
                height:100%;
                background-repeat: no-repeat;
                background-size: 100% 100%;
            }
            &::after {
                content: "";
                position: absolute;
                right: -49px;
                top: 0;
                background-image: url(Images/MarketingResearch/right-shape.png);
                width: 50px;
                height: 100%;
                background-repeat: no-repeat;
                background-size: 100% 100%;
            }
        }
        p{
            color:$white;
            font-size:16px;
        }
    }
    .utility-Tokens-img{
        float: left;
        position: relative;
        width: 100%;
        background-image: url(Images/MarketingResearch/Utility-Tokens.jpg);
        min-height: 705px;
        background-repeat: no-repeat;
    }
    .utility-title {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        span{
            position:absolute;
            left:-110px;
            bottom:-61px;
            h3{
                font-size:31px;
                text-align:center;
                color:$white;
                text-shadow: -3px 2px 1px rgba(0, 0, 0,0.5);
            }
            &:last-child{
                left:40px;
            }
        }
        
    }
    .utility-area {
        position:absolute;
        width:330px;
    }
    .utility-area.ua-1 {
        top:11%;
        left:4%;
    }
    .utility-area.ua-2 {
        top:34%;
        width:130px;
        left:9.5%;
    }
    .utility-area.ua-3 {
        top:62%;
        width:235px;
        left:1.5%;
    }
    .utility-area.ua-4 {
        bottom:5%;
        width:145px;
        left:18.5%;
    }
    .utility-area.ua-5 {
        right:-2%;
        top:9%;
        width:337px;
    }
    .utility-area.ua-6 {
        right:0%;
        top:33%;
        width:200px;
    }
    .utility-area.ua-7 {
        right:-2%;
        top:63%;
        width:211px;
    }
    .utility-area.ua-8 {
        right:6.9%;
        bottom:6%;
        width:220px;
    }
    
}

@media only screen and (max-width:767px) {
    .utilitysecuritytokens-bg {
        display:none;
        .heading-title{
            h2::before, h2::after{
                background-image:none;
                left:0;
                right:0;
            }
        }
        .utility-title{
            top: 5%;
        }
        .utility-Tokens-img{
            background-image:none;
        }
        .utility-area{
            position: relative;
            width: 100%;
        }
    }
}

//Difference between Cryptocurrency and Security Tokens
.diff-subtitle{
    display:inline-block;
    text-align:center;
    width:100%;
    margin-top:40px;
    h4{
        position: relative;
        display: inline-block;
        font-size: 33px;
        color: #031937;
        &::after{
            content: "";
            position: absolute;
            left: 0;
            bottom: -41px;
            background-image: url(Images/MarketingResearch/heading-bottom-2.png);
            width: 263px;
            right: 0;
            margin: 0 auto;
            height: 100%;
            background-repeat: no-repeat;
            background-size: contain;
        }
    }
}
.difference-tokens-bg{
    position:relative;
    margin-top:65px;
    background-image:url(Images/MarketingResearch/difference-tokens-bg.png);
    background-size:contain;
    background-repeat:no-repeat;
    min-height:405px;
    .df-common {
        position: absolute;
    }
    .df-title {
        display: inline-block;
        vertical-align: middle;
        p{
            font-size:18px;
            color:$white;
            width:350px;
        }
    }
    .df-icon{
        display: inline-block;
        vertical-align: middle;
        margin-right: 20px;
    }
    .df-icon1 {
        left:3.8%;
        top:3.1%;
    }
    .df-icon2 {
        left:3.8%;
        top:33.9%;
    }
    .df-icon3 {
        left:3.8%;
        bottom:6.3%;
    }
    .df-icon4 {
        right:6%;
        top:2.1%;
    }
    .df-icon5 {
        right:6%;
        top:33.9%;
    }
    .df-icon6 {
        right:6%;
        bottom:4.3%;
    }
    
}

@media only screen and (max-width:767px) {
    .CryptocurrencyExchange-bg{
        margin-bottom: 90px;    
        .heading-title{
            h2::after, h2::before{
                left:0 !important;
                right:0 !important;
                background-image:none !important;
            }
        }
        .exchange-box{
            .ex-box-main{
                margin:240px 0 115px !important;
            }
            .ex-box-3 {
                top:120px !important;
            }
            .ex-box-4 {
                bottom:-120px !important;
            }
        }
    }
}

//Difference between ICO and STO
.icosto-bg{
    .heading-title{
        margin-bottom:40px;
        margin-top:50px;
        display: block;
        text-align: center;
        width: 100%;
        h2 {
            font-size:32px;
            padding: 18px 40px;
            color: $white;
            position: relative;
            display: inline-block;
            background: $comheading;
            &::before {
                content: "";
                position: absolute;
                left: -49px;
                top: 0;
                background-image: url(Images/MarketingResearch/left-shape.png);
                width: 50px;
                height: 100%;
                background-repeat: no-repeat;
                background-size: 100% 100%;
            }
            &::after {
                content: "";
                position: absolute;
                right: -49px;
                top: 0;
                background-image: url(Images/MarketingResearch/right-shape.png);
                width: 50px;
                height: 100%;
                background-repeat: no-repeat;
                background-size: 100% 100%;
            }
        }
    }
    
    .icobg {
        position: relative;
        background-image: url(Images/MarketingResearch/ICO.png);
        background-size: contain;
        background-repeat: no-repeat;
        min-height: 630px;
    }
    .stobg{
        position: relative;
        background-image: url(Images/MarketingResearch/STO.png);
        background-size: contain;
        background-repeat: no-repeat;
        min-height: 630px;
    }
    .ico-contant{
        color:$white;
        p{
            font-size:18px;
            color:$white;
            position: absolute;
        }
        p:nth-of-type(1) {
            top:67px;
            left: 124px
        }
        p:nth-of-type(2) {
            left:195px;
            top:140px;
        }
        p:nth-of-type(3) {
            right:68px;
            top:218px;
        }
        p:nth-of-type(4) {
            right:0;
            width:300px;
            top:313px;
        }
        p:nth-of-type(5) {
            bottom:170px;
            right:140px;
        }
        p:nth-of-type(6) {
            bottom:100px;
            left:120px;
        }
        
    }
    
    .sto-contant{
        color:$white;
        p{
            font-size:18px;
            color:$white;
            position: absolute;
        }
        p:nth-of-type(1) {
            top:67px;
            right:130px;
        }
        p:nth-of-type(2) {
            right:201px;
            top:140px;
        }
        p:nth-of-type(3) {
            top:210px;
            right:210px;
            width:218px;
        }
        p:nth-of-type(4) {
            right:230px;
            top:313px;
            width:255px;
        }
        p:nth-of-type(5) {
            right:190px;
            bottom:172px;
        }
        p:nth-of-type(6) {
            right:117px;
            bottom:98px;
        }
    }
}





//Responsive Design{
    @media only screen and (max-width:767px) {
        .icosto-bg{
            .icobg, .stobg{
                min-height:450px;
            }
            h2{
                &::after, &::before{
                    display:none;
                }
            }
        }
        .ico-contant{
            p:nth-of-type(1) {
                top:38px !important;
                left:88px !important;
            }
            p:nth-of-type(2) {
                top:98px !important;
                left:140px !important;
            }
            p:nth-of-type(3) {
                top:136px !important;
                right:0px !important;
                width:200px;
            }
            p:nth-of-type(4) {
                right:0 !important;
                width:210px !important;
                top:212px !important;
            }
            p:nth-of-type(5) {
                bottom: 114px !important;
                left: 132px !important;
                width:250px;
            }
            p:nth-of-type(6) {
                bottom:64px !important;
                left:88px !important;
            }
        }
        .sto-contant{
            p:nth-of-type(1) {
                top:43px !important;
                right:88px !important;
            }
            p:nth-of-type(2) {
                right:136px !important;
                top:100px !important;
            }
            p:nth-of-type(3) {
                top:134px !important;
                right:130px !important;
            }
            p:nth-of-type(4) {
                right:166px !important;
                top:206px !important;
                width:206px !important;
            }
            p:nth-of-type(5) {
                right: 128px !important;
                bottom:113px !important;
            }
            p:nth-of-type(6) {
                right: 88px !important;
                bottom: 65px !important;
            }
        }
    }


    //Difference between Cryptocurrency Exchange and Security Token Exchange
    .CryptocurrencyExchange-bg{
        padding:50px 0;
    .heading-title{
        margin-bottom: 40px;
        display: block;
        text-align: center;
        width: 100%;
        h2 {
            font-size:32px;
            padding: 18px 5px;
            color: $white;
            position: relative;
            display: inline-block;
            background: $comheading;
            &::before {
                content: "";
                position: absolute;
                left: -49px;
                top: 0;
                background-image: url(Images/MarketingResearch/left-shape.png);
                width: 50px;
                height: 100%;
                background-repeat: no-repeat;
                background-size: 100% 100%;
            }
            &::after {
                content: "";
                position: absolute;
                right: -49px;
                top: 0;
                background-image: url(Images/MarketingResearch/right-shape.png);
                width: 50px;
                height: 100%;
                background-repeat: no-repeat;
                background-size: 100% 100%;
            }
        }
    }

    .exchange-box {
        display:inline-block;
        width:100%;
        position:relative;
        .ex-box-main {
            margin: 165px 0;
            display: block;
            text-align: center;
            .ex-box {
                height: 105px;
                width: 255px;
                background-color: #301b92;
                display: inline-block;
                margin: 0 -2px;
                position: relative;
                &::before {
                    content: "";
                    height: 113px;
                    border-left: 1px solid #4b7794;
                    left: 50%;
                    top: -113px;
                    position: absolute;
                }
                &::after {
                    content: "";
                    height: 119px;
                    border-left: 1px solid #4b7794;
                    left: 50%;
                    bottom: -119px;
                    position: absolute;
                }
                h4{
                    font-size: 28px;
                    color: $white;
                    text-align: center;
                    position: absolute;
                    left: 0;
                    right: 0;
                    margin: 0 auto;
                    top: 50%;
                    transform: translateY(-50%);
                    -moz-transform: translateY(-50%);
                    -webkit-transform: translateY(-50%);
                }
                &:last-child {
                    background-color: #00838f;
                }
            }
        }
        .ex-common {
            height:102px;
            width:260px;
            .ex-box-contant {
                position: absolute;
                top: 50%;
                left: 28%;
                transform: translateY(-50%);
                -moz-transform: translateY(-50%);
                -webkit-transform: translateY(-50%);
                p{
                    color:$white;
                    width:167px;
                    margin-bottom:0;
                    font-size:18px;
                }
            }
            .ex-box-img {
                position: absolute;
                top: -11px;
                left: -56px;
            }
        }
        .ex-box-1 {
            top:0;
            left:27px;
            position:absolute;
        }
        .ex-box-2 {
            left:27px;
            bottom:0;
            position: absolute;
        }
        .ex-box-3 {
            right:31px;
            top:0;
            position:absolute;
            .ex-box-contant {
                left: 8%;
            }
            .ex-box-img {
                right: -23%;
                left: inherit;
            }
        }
        .ex-box-4 {
            bottom:0;
            right:31px;
            position:absolute;
            .ex-box-contant {
                left: 8%;
            }
            .ex-box-img {
                right: -23%;
                left: inherit;
            }
        }
        .ex-green {
            background: #00828f;
            &::before {
                content:"";
                position:absolute;
                border-bottom:1px solid #003e67;
                width:140px;
                left:-139px;
                top:50%;
                right:-186px;
            }
        }
        .ex-blue {
            background: #301b92;
            &::before {
                content: "";
                position: absolute;
                border-bottom: 1px solid #003e67;
                width:145px;
                right:-143px;
                top: 50%;
            }
        }
    }
}



//Valuation of a Security Token
.Valuationtoken-bg{
    padding:50px 0 0;
    .heading-title{
        margin-bottom: 40px;
        display: block;
        text-align: center;
        width: 100%;
        h2 {
            padding: 18px 50px;
            color: $white;
            position: relative;
            display: inline-block;
            background: $comheading;
            &::before {
                content: "";
                position: absolute;
                left: -49px;
                top: 0;
                background-image: url(Images/MarketingResearch/left-shape.png);
                width: 50px;
                height: 100%;
                background-repeat: no-repeat;
                background-size: 100% 100%;
            }
            &::after {
                content: "";
                position: absolute;
                right: -49px;
                top: 0;
                background-image: url(Images/MarketingResearch/right-shape.png);
                width: 50px;
                height: 100%;
                background-repeat: no-repeat;
                background-size: 100% 100%;
            }
        }
    }
    .valuation-token-content {
        background: #6746c3;
        padding: 40px 35px;
        border-radius: 20px;
        p{
            text-align:center;
            font-size:18px;
            color:$white;
        }
    }
    .cryptohead{
        display: inline-block;
        width: 100%;
        text-align: center;
        padding:40px 0 0;
        h2{
            color: #082966;
            display: inline-block;
            position: relative;
            font-size: 28px;
            &::after{

                content: "";
                position: absolute;
                left: 0;
                bottom: -35px;
                background-image: url(Images/MarketingResearch/heading-small-shape.png);
                width: 400px;
                right: 0;
                margin: 0 auto;
                height: 100%;
                background-repeat: no-repeat;
                background-size: contain;
            }
        }
        p{
            color: #333333;
            font-size: 19px;
            text-align: center;
            margin:70px 0 0;
            padding: 0 25px;
        }
    }
    .cypto-bg{
        background-image: url(Images/MarketingResearch/crypto-factor.png);
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        min-height: 330px;
        margin: 90px 0 130px 0;
        position: relative;
    }
    .cypto-title{
        li {
            position: absolute;
            color: #082966;
            font-size:20px;
            list-style:none;
            font-weight: bold;
            &:nth-of-type(1) {
                bottom: -10%;
                left: 3.5%;
            }
            &:nth-of-type(2) {
                top: -9%;
                left: 28.2%;
            }
            &:nth-of-type(3) {
                top: -16%;
                right: 2.2%;
                width: 230px;
                text-align: center;
            }
            &:nth-of-type(4) {
                bottom: -17%;
                right: 28.2%;
                width: 230px;
                text-align: center;
            }
        }
    }
}

@media only screen and (max-width:767px) {
    .Valuationtoken-bg{
        .cypto-bg{
            margin: 90px 0 30px 0;
        }
        .heading-title{
            h2{
                &::after, &::before{
                    left:0;
                    right:0;
                    background-image:none;
                }
            }
        }
        .cypto-title{
            li{
                font-size: 18px;
                &:nth-of-type(1){
                    bottom:18%;
                    left:0;
                }
                &:nth-of-type(2){
                    left:0;
                }
                &:nth-of-type(3){
                    top:0%;
                    right:0%;
                    width:100%;
                    text-align:left;
                }
                &:nth-of-type(4){
                    bottom:0%;
                    right:0%;
                    width:100%;
                    text-align:left;
                }
            }
        }
    }
}


//Asset-based Factors
.assetbasedfactors-bg{
    .assettitle{
        display: inline-block;
        width: 100%;
        text-align: center;
        h3{
            display: inline-block;
            position: relative;
            font-size: 28px;
            color: #082966;

            &::after{
                content: "";
                position: absolute;
                background-image: url(Images/MarketingResearch/asset-factor-shape.png);
                left: 0;
                top: 40px;
                width:260px;
                margin: 0 auto;
                height: 100%;
                background-repeat: no-repeat;
                background-size: contain;
            }
            
        }
    }
    .assfactor-bg{
        position: relative;
        background-image: url(Images/MarketingResearch/asset-factor-bg.png);
        min-height: 715px;
        background-repeat: no-repeat;
        background-size: contain;
        margin:80px 0 80px 0;
        .asset-factor-box{
            // padding:0;
            // margin:0;
            // position:relative;
            li{
                list-style:none;
           
                &:nth-child(1){
                    left:-1%;
                    top:0;
                    position:absolute;
                }
                &:nth-child(2){
                    position:absolute;
                    left:33.2%;
                    top:0;
                }
                &:nth-child(3){
                    position: absolute;
                    top:0%;
                    right:-1%;
                }
                &:nth-child(4){
                    bottom:5%;
                    left: 16%;
                    position: absolute;
                }
                &:nth-child(5){
                    position: absolute;
                    bottom:5%;
                    right:14.4%;
                }
            }

            .factorboximg{
                position:relative;
            }
            .factorboxdetails{
                position: absolute;
                top:12%;
                left: 30%;
                width: 68%;
                span{
                    font-size:20px;
                    color:$white;
                    font-weight: bold;
                }
                p{
                    line-height:21px;
                    color:$white;
                    margin-top:20px;
                    padding:1px;
                }
            }
        }
    }
}

@media only screen and (max-width:767px) {
    .assfactor-bg{
        background-image:none !important;
        .asset-factor-box {
            padding:0;
            .factorboxdetails {
                width:62% !important;
                p{
                    font-size:14px;
                    margin-top:0px !important;
                }
            }
            li{
                &:nth-child(1){
                    left:0 !important;
                    top:0 !important;
                    position:unset !important;
                }
                &:nth-child(2){
                    position:unset !important;
                    left:0% !important;
                    top:0 !important;
                }
                &:nth-child(3){
                    position: unset !important;
                    top:0% !important;
                    right:0% !important;
                }
                &:nth-child(4){
                    bottom:0% !important;
                    left:0% !important;
                    position: unset !important;
                    .factorboximg{
                        img{
                            transform: rotateX(180deg);
                        }
                    }
                }
                &:nth-child(5){
                    position: unset !important;
                    bottom:0% !important;
                    right:0% !important;
                    .factorboximg{
                        img{
                            transform: rotateX(180deg);
                        }
                    }
                }
            }
        }   
    }
}

@media only screen and (min-width:768px) and (max-width:1024px)  {
    .assetbasedfactors-bg {
        .assfactor-bg{
            .asset-factor-box{
                .factorboxdetails {
                    p{
                        font-size:14px;
                        margin-top:0px !important;
                    }
                }
                li{
                    list-style: none;
                    width: 50%;
                    &:nth-child(2) {
                        left:50%;
                    }
                    &:nth-child(3){
                        top: 37%;
                        left: -1%;
                    }
                    &:nth-child(4){
                        top: 37%;
                        left: 50%;
                        .factorboximg{
                            img{
                                transform: rotateX(180deg);
                            }
                        }
                    }
                    &:nth-child(5){
                        bottom: -9%;
                        left: -1%;
                        .factorboximg{
                            img{
                                transform: rotateX(180deg);
                            }
                        }
                    }
                }
            }
        }
        
    }

}


//Benefits of Security Tokens
.benefitssecuritytokens-bg{
    background-color: #301b92;
    position: relative;
    padding:0px 0 80px 0;
    min-height: 1485px;
    .token-title {
        display: inline-block;
        width: 100%;
        text-align: center;
        position: relative;
        top: -23%;
        left: 50%;
        transform: translateX(-50%);
        -moz-transform: translateX(-50%);
        -webkit-transform: translateX(-50%);
        h3{
            font-size:32px;
            position: relative;
            color: $white;
            padding: 18px 40px;
            background-color: #000063;
            display: inline-block;
            &::before {
                content: "";
                position: absolute;
                background-image: url(Images/MarketingResearch/benefits-Security-shape-1.png);
                left: -34px;
                height: 75px;
                width: 35px;
                top: 0;
            }
            &::after {
                content: "";
                position: absolute;
                right: -34px;
                height: 0;
                width: 0;
                top: 0;
                background-image: url(Images/MarketingResearch/benefits-Security-shape-2.png);
                height: 75px;
                width: 35px;
            }
        }
    }

    .Benefits-Security-main{
        position: relative;
        display: inline-block;
        width: 100%;
        &::before {
            position: absolute;
            content: "";
            border-right: 1px solid $white;
            height: 987px;
            width: 1px;
            left: 50%;
            transform: translateX(-50%);
            -moz-transform: translateX(-50%);
            -webkit-transform: translateX(-50%);
            top: 151px;
        }
        .bs-cm {
            display: inline-block;
            position: absolute;
        }
        
        .bs-box-2 {
            top:169px;
        }
        .bs-box-3 {
            top:329px;
        }
        .bs-box-4 {
            top:508px;
        }
        .bs-box-5 {
            top:667px;
        }
        .bs-box-6 {
            top:846px;
        }
        .bs-box-7 {
            top:1006px;
        }
        
        .bs-box-img {
            position: relative;
            &::before {
                position: absolute;
                content: "";
                border-bottom: 1px solid $white;
                width:90px;
                right: -74px;
                top: 47%;
            }
            &::after {
                position: absolute;
                content: "";
                height: 35px;
                width: 35px;
                border-radius: 50%;
                background-color: #6746c3;
                right: -74px;
                top: 115px;
                box-shadow: 0px 0 5px rgba(0,0,0,0.5);
            }
            img {
                width: 500px;
            }
            .bs-box-icon {
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                -moz-transform: translateY(-50%);
                -webkit-transform: translateY(-50%);
                left: 7.3%;
                img {
                    width: 100px;
                }
            }
            .bs-box-text {
                position:absolute;
                top:15px;
                width:255px;
                right:45px;
                span{
                    font-size:22px;
                    font-weight:bold;
                    color:#113148;
                    margin-bottom:5px;
                }
            }
        }
        .bs-cm-left{
            right:0;
            .bs-box-text{left:70px;}
            .bs-box-icon{right:6.4%;left: inherit;}
            .bs-box-img{
                &::before{
                    left:-70px;
                }
                &::after{
                    left:-72px;
                }
            }
        }
    }
}

@media only screen and (max-width:767px) {
    .benefitssecuritytokens-bg{
        .token-title{
            h3{
                padding:20px 0;
                font-size:29px;
            }
        }
    }
    
    .Benefits-Security-main{
        &::before{
            display:none;
        }
        .bs-box-img{
            &::before, &::after{
                display:none;
            }
            img{
                display:none;
            }
            .bs-box-icon{
                img{
                    display:none;
                }
            }
            .bs-box-text {
                position:unset !important;
                top:0 !important;
                width:100% !important;
                right:0 !important;
                background:$white;
                padding:10px 15px;
                text-align:center;
                margin:15px 0;
            }
        }
        .bs-cm {
            position:static !important;
        }
       
    }
}

@media only screen and (min-width:768px) and (max-width:1024px)  {
    .Benefits-Security-main{
        text-align:center;
        &::before{
            display:none;
        }
        .bs-box-img{
            &::before, &::after{
                display:none;
            }
        }
        .bs-cm {
            position:static !important;
        }
        .bs-box-text {
            top:20px !important;
            right:63px !important;
        }
    }
}


//Limitations of Security Tokens
.lisecuritytokens_bg{
    padding:50px 0;
    .lsttitle{
        margin-bottom: 40px;
        display:block;
        text-align:center;
        width:100%;
        h3{
            font-size:32px;
            padding:18px 40px;
            color:$white;
            position:relative;
            display:inline-block;
            background:$comheading;
            &::before {
                content:"";
                position:absolute;
                left:-49px;
                top:0;
                background-image: url(Images/MarketingResearch/left-shape.png);
                width:50px;
                height:100%;
                background-repeat:no-repeat;
                background-size:100% 100%;
            }
            &::after {
                content:"";
                position:absolute;
                right:-49px;
                top:0;
                background-image:url(Images/MarketingResearch/right-shape.png);
                width:50px;
                height:100%;
                background-repeat:no-repeat;
                background-size:100% 100%;
            }
        }
    }
    .listtokenbox{
        background:#4fb3be;
        border-radius:45px;
        padding:30px 40px 10px;
        box-shadow:4px 0 13px rgba(0, 0, 0, 0.3);
        text-align:center;
        color:$white;
        min-height:410px;
        span{
            font-size:28px;
            font-weight:bold;
            line-height:normal;
        }
        p{
            font-size:18px;
            margin-top:30px;
        }
    }
    .lstbox2{
        background:#00838f;
    }
    .lstbox3{
        background:#005561;
    }
}

@media only screen and (max-width:767px) {
    .lisecuritytokens_bg{
        padding: 50px 0 0;
        .lsttitle{
            h3{
                padding:10px 5px;
                &::after, &::before{
                    display:none;
                }
            }
            img{
                display:none;
            }
        }
        .listtokenbox{
            border-radius:5px;
            padding:10px 5px 10px;
            min-height: auto;
            margin: 0 0 40px;
            p{
                margin-top:15px;
                font-size:16px;
            }
        }
    }
}
@media only screen and (min-width:768px) and (max-width:1024px)  {
    .lisecuritytokens_bg {
        .listtokenbox{
            padding:30px 5px 10px;
            min-height:510px;
        }
        
    }
}

//Enter Security Token Exchanges
.este_bg{
    padding:50px 0;
    .estetitle{
        margin-bottom: 40px;
        display: block;
        text-align: center;
        width: 100%;
        p{
            font-size:18px;
        }
        h3{
            padding:18px 40px;
            margin:0 0 30px;
            color:$white;
            position: relative;
            display: inline-block;
            background: $comheading;
            &::before {
                content:"";
                position:absolute;
                left:-49px;
                top:0;
                background-image: url(Images/MarketingResearch/left-shape.png);
                width:50px;
                height:100%;
                background-repeat:no-repeat;
                background-size:100% 100%;
            }
            &::after {
                content:"";
                position:absolute;
                right:-49px;
                top:0;
                background-image:url(Images/MarketingResearch/right-shape.png);
                width:50px;
                height:100%;
                background-repeat:no-repeat;
                background-size:100% 100%;
            }
        }
        h4{
            color: #082966;
            text-align:center;
            font-size:30px;
            &::after{
                content:"";
                position:absolute;
                background-image:url(Images/MarketingResearch/asset-factor-shape.png);
                left:0;
                right:0;
                top:50px;
                width:210px;
                margin:0 auto;
                height:100%;
                background-repeat:no-repeat;
                background-size:contain;
            }
        }
    }
    .estediscription{
        margin-top:50px;
        font-size:16px;
    }
}
@media only screen and (max-width:767px) {
    .estetitle{
        h3{
            &::before, &::after{
                display:none;
            }
        }
    }
}

//Key Components of a Security Token Exchange
.keycomponents_bg{
    padding:50px 0;
    .keysyetitle{
        text-align:center;
        h3{
            font-size:32px;
            padding:18px 40px;
            color:$white;
            position:relative;
            display:inline-block;
            background:$comheading;
            &::before {
                content:"";
                position:absolute;
                left:-49px;
                top:0;
                background-image: url(Images/MarketingResearch/left-shape.png);
                width:50px;
                height:100%;
                background-repeat:no-repeat;
                background-size:100% 100%;
            }
            &::after {
                content:"";
                position:absolute;
                right:-49px;
                top:0;
                background-image:url(Images/MarketingResearch/right-shape.png);
                width:50px;
                height:100%;
                background-repeat:no-repeat;
                background-size:100% 100%;
            }
        }
    }

    .key-box-main {
        display:inline-block;
        width:100%;
        margin-top:130px;
        margin-left: 4.70%;
    }
    .key-box-left {
        display:inline-block;
        width:46.8%;
        vertical-align:middle;
        .key-green:last-child{
            &::before, &::after{
                display:none;
            }
        }
    }
   
    .key-blue {
        position: relative;
        display: inline-block;
        width: 485px;
        height: 270px;
        background-color: #301b92;
        border-radius: 30px;
        padding: 35px 40px;
        margin-bottom: 250px;
        &::before {
            position: absolute;
            left: 48%;
            content: "";
            width:252px;
            border: 10px solid $heading;
            border-top-right-radius: 15px;
            border-bottom-right-radius: 15px;
            bottom: -50%;
        }
        &::after {
            position: absolute;
            left: 48%;
            content: "";
            border: 10px solid $heading;
            height: 78px;
            border-top-left-radius: 15px;
            border-top-right-radius: 15px;
            bottom: -50%;
        }
        
        .key-box-icon{
            position: absolute;
            top: -32%;
            left: -15.1%;
        }
        .key-box-details{
            color:$white;
            text-align:center;
            span{
                font-size:28px;
                margin-bottom:30px;
                display:inline-block;
                font-weight:bold;
            }
            p{
                font-size:18px;
            }
        }
    }
    .key-green {
        margin-top:250px;
        background-color: #00838f;
        position: relative;
        display: inline-block;
        width: 485px;
        height: 270px;
        border-radius: 30px;
        padding: 35px 40px;

        &::before {
            position: absolute;
            left:0%;
            content: "";
            border:10px solid #005561;
            height:18px;
            border-top-left-radius:15px;
            border-bottom-left-radius:15px;
            bottom:-50%;
            width:252px;
        }
        &::after {
            position: absolute;
            left: 48%;
            content: "";
            border: 10px solid #005561;
            height: 78px;
            border-top-left-radius: 15px;
            border-top-right-radius: 15px;
            bottom: -50%;
        }
        .key-box-icon{
            right: -15%;
            position: absolute;
            top: -30%;
        }
        .key-box-details{
            color:$white;
            text-align:center;
            span{
                font-size:28px;
                margin-bottom:30px;
                display:inline-block;
                font-weight:bold;
                padding: 0 10px 0 10px;
                line-height: initial;
            }
            p{
                font-size:18px;
            }
        }
    }
}

@media only screen and (min-width:768px) and (max-width:1024px)  {
    .key-green, .key-blue{
        width:310px !important;
        height:auto !important;
        padding:35px 5px !important;
        &::before{
            width:150px !important;
            bottom:-33% !important;
        }
        &::after{
            bottom:-33% !important;
        }
        .key-box-icon{
            img{
                width:120px !important;
            }
        }
    }
    .key-box-left{
        .key-green:last-child{
            &::before, &::after{
                display:none;
            }
        }
    }
}

@media only screen and (max-width:767px) {
    .keycomponents_bg{
        .keysyetitle{
            h3{
                &::before, &::after{
                    display:none;
                }
            }
        }
        .key-box-main{
            width:100%;
            margin-top:100px;
            margin-left:0;
        }
        .key-box-left{
            width:100% !important;
        }
        .key-blue, .key-green{
            width:100%;
            height:auto;
            padding:35px 20px;
            &::before, &::after{
                display:none;
            }
            .key-box-icon{
                top:-27%;
                left:0;
                width: 100%;
                img{
                    width:100px;
                }
            }
        }
        .key-blue{
            margin-bottom:110px;
        }
        .key-green{
            margin-top:110px;
        }
    }
}

//Security Token Exchange Ecosystem
.ecosystem_bg{
    padding:20px 0 50px;
    .ecoheading-title{
        text-align:center;
        margin:0 0 40px;
        h3{
            padding:18px 40px;
            color:$white;
            position:relative;
            display:inline-block;
            background:$comheading;
            &::before {
                content:"";
                position:absolute;
                left:-49px;
                top:0;
                background-image: url(Images/MarketingResearch/left-shape.png);
                width:50px;
                height:100%;
                background-repeat:no-repeat;
                background-size:100% 100%;
            }
            &::after {
                content:"";
                position:absolute;
                right:-49px;
                top:0;
                background-image:url(Images/MarketingResearch/right-shape.png);
                width:50px;
                height:100%;
                background-repeat:no-repeat;
                background-size:100% 100%;
            }
        }
    }
    .security-token-box{
        background:linear-gradient(to right, rgba(240,238,238,1) 0%, rgba(251,251,251,1) 100%);
        padding:30px;
        border-radius:150px;
        margin-top: 20px;
        img{
            padding-top:8px;
        }
        span{
            font-size:28px;
            line-height:1;
            margin:0px;
            color:#031937;
            margin-bottom:10px;
            font-weight: bold;
            padding-bottom: 15px;
            display: inline-block;
        }
        p{
            font-size:15px;
            line-height:26px;
            margin:0px;
            color:#434343;
            font-weight:normal;
            padding-bottom:10px;
        }
    }
}

@media only screen and (max-width:767px) {
    .ecoheading-title{
        h3{
            &::before, &::after{
                display:none;
            }
        }
    }
    .security-token-box{
        text-align:center !important;
        border-radius:30px !important;
        margin-bottom:15px !important;
        padding:15px !important;
        span{
            margin:18px 0 0  !important;
            font-size:20px !important;
        }
        img{
            width:70px !important;
        }
    }
}

//Security Token Legal Compliance in Different Countries
.STLCompliance-bg{
    .STLCtitle{
        display:block;
        text-align:center;
        width:100%;
        margin:0 0 30px;
        h3{
            font-size:32px;
            padding:18px 40px;
            color:$white;
            position:relative;
            display:inline-block;
            background:$comheading;
            &::before {
                content:"";
                position:absolute;
                left:-49px;
                top:0;
                background-image: url(Images/MarketingResearch/left-shape.png);
                width:50px;
                height:100%;
                background-repeat:no-repeat;
                background-size:100% 100%;
            }
            &::after {
                content:"";
                position:absolute;
                right:-49px;
                top:0;
                background-image:url(Images/MarketingResearch/right-shape.png);
                width:50px;
                height:100%;
                background-repeat:no-repeat;
                background-size:100% 100%;
            }
        }
    }
}

@media only screen and (max-width:767px) {
    .STLCtitle{
        h3{
            font-size:22px;
            &::before, &::after{
                display:none;
            }
        }
    }
}
@media only screen and (min-width:768px) and (max-width:1024px)  {
    .STLCtitle{
        h3{
            &::before, &::after{
                display:none;
            }
        }
    }
}

//List of Countries Welcoming Security Token Exchanges
.liscountrie-bg{
    padding:50px 0 0;
    .liscountrietitle{
        h3{
            text-align:center;
            font-size:32px;
            color:#031937;
            font-weight:bold;
            margin:0 0 80px;
            &::after {
                position:absolute;
                right:0;
                left:0;
                bottom:0;
                content:"";
                background:url(Images/MarketingResearch/heading-big-shape.png) no-repeat center;
                height:30px;
                margin:0 auto;
                top:100px;
            }
        }
    }
    .countries-box{
        margin-bottom: 50px;
        .countries-flag{
            text-align:right;
            margin-bottom:10px;
        }
        .countries-content {
            background:#311b92;
            text-align:center;
            padding-bottom:20px;
            min-height:201px;
            span{
                font-size:22px;
                line-height:1;
                margin:0px;
                color:$white;
                font-weight:bold;
                background:#6746c3;
                display:inline-block;
                padding:15px 17px 13px 16px;
                margin-top:-9px;
                position:relative;
                &::before {
                    position:absolute;
                    top:0;
                    right:-9px;
                    content: "";
                    width:0;
                    height:0;
                    border-style:solid;
                    border-width:9px 0 0 9px;
                    border-color:transparent transparent transparent #000063;
                }
                &::after {
                    position:absolute;
                    top:0;
                    left:-9px;
                    width:0;
                    height:0;
                    border-style:solid;
                    border-width:0 0 9px 9px;
                    border-color:transparent transparent #000063 transparent;
                    content:"";
                }
            }
            p{
                font-size:16px;
                line-height:22px;
                margin:0px;
                color:$white;
                margin-top:15px;
                padding:0 10px;
            }
        }
    }
    .column-box{
        &:nth-child(odd){
            .countries-content {
                background: #00838f;
                span{
                    background: #4fb3bf;
                    &::before{
                        border-color:transparent transparent transparent #005662;
                    }
                    &::after{
                        border-color:transparent transparent #005662 transparent;
                    }
                }
            }
        }
    }
}

@media only screen and (max-width:767px) {
    .countries-content{
        min-height:auto  !important;
        p {
            font-size:16px !important;
        }
    }
    .liscountrietitle{
        h3{
            margin:0 0 30px !important;
            &::after{
                display:none;
            }
        }
    }
    .countries-box {
        margin-bottom:20px !important;
    }
}
@media only screen and (min-width:768px) and (max-width:1024px)  {
    .countries-content{
        p{
            font-size:12px !important;
        }
    }
    .liscountrietitle h3 {
        font-size: 26px !important;
    }
}

//Security Token Exchange Ecosystem Start
.stecosystem-bg{
    .stecosystem-title{
        margin:0px 0 40px;
        display: block;
        text-align: center;
        width:100%;
        h3{
            font-size:32px;
            padding: 18px 40px;
            color: $white;
            position: relative;
            display: inline-block;
            background:$comheading;
            &::before {
                content: "";
                position: absolute;
                left: -49px;
                top: 0;
                background-image: url(Images/MarketingResearch/left-shape.png);
                width: 50px;
                height: 100%;
                background-repeat: no-repeat;
                background-size: 100% 100%;
            }
            &::after {
                content: "";
                position: absolute;
                right: -49px;
                top: 0;
                background-image: url(Images/MarketingResearch/right-shape.png);
                width: 50px;
                height: 100%;
                background-repeat: no-repeat;
                background-size: 100% 100%;
            }
        }
    }
    .ecosystembox{
        background:#f1f1f1;
        min-height:315px;
        text-align: center;
        border-radius: 50px;
        h3{
            font-size: 25px;
            line-height: 1;
            margin: 25px 0 13px 0;
            color: #031937;
            font-weight:bold;
        }
        p{
            font-size: 16px;
            line-height: 26px;
            margin: 0px;
            color: $lightblack;
            padding: 0 32px;
        }
        .country-box{
            background: #7244c8;
            padding: 20px 50px 18px 40px;
            text-align: left;
            border-top-left-radius: 50px;
            border-top-right-radius: 50px;
            span{
                font-size:25px;
                line-height:1;
                margin:0px;
                color:$white;
                font-weight:bold;
                display:inline-block;
                padding-top:7px;
            }
            img{
                float:right;
            }
            
        }
        .countrycolor{
            background:#331496 !important;
        }
        .countrycolor1{
            background:#000064 !important;
        }
    }

    .ecosystemtow-box {
        margin:295px -33px 0 -33px;
        position:relative;
        &::after{
            position: absolute;
            top: -295px;
            left: 0;
            right: 0;
            content: "";
            background: url(Images/MarketingResearch/joint-box-img.png) no-repeat;
            width: 100%;
            height: 295px;
            background-position: center;
        }
        .ecosystemtowsp{
            margin-left:15%;
        }
        .ecosystemtowsp1{
            margin-left:3%;
        }
        .ecosystembox{
            padding:10px 0 0;
        }
        p{
            min-height:160px;
        }
        .country-box{
            background:#00b4c0 !important;
            border-top-left-radius:0;
            border-top-right-radius:0;
            border-bottom-left-radius:50px;
            border-bottom-right-radius:50px;
            padding:15px 50px 23px 40px;
        }
        .countrycolor2{
            background:#008491 !important;
        }
    }
}
@media only screen and (max-width:767px) {
    .ecosystembox{
        h3{
            font-size:22px !important;
        }
        .country-box{
            span{
                font-size:18px !important;
            }
        }
    } 
    .stecosystem-title{
        h3{
            font-size:22px;
            padding:18px 0 !important;
            &::after, &::before{
                display:none;
            }
        }
    }
    .stecosystem-bg{
        .ecosystembox{
            margin: 0 0 30px;
        }
    }
    .ecosystemtow-box{
        margin:0 !important;
        &::after{
            display:none;
        }
        .ecosystemtowsp, .ecosystemtowsp1 {
            margin-left:0% !important;
            width: 100%;
            padding: 0;
        }
    }
}

@media only screen and (min-width:768px) and (max-width:1024px)  {
    .stecosystem-bg{
        .ecosystembox{
            border-radius:20px;
            min-height:290px;
            .country-box{
                padding:10px 20px 18px 20px;
                border-top-left-radius: 20px;
                border-top-right-radius: 20px;
                span{
                    font-size:16px;
                }
            }
            h3{
                font-size:18px;
            }
            p{
                font-size:12px;
                padding:0 10px;
            }
        }
        .ecosystemtow-box{
            .country-box{
                padding:10px 20px 18px 20px;
                border-top-left-radius:0px;
                border-top-right-radius:0px;
                border-bottom-left-radius:20px;
                border-bottom-right-radius:20px;
            }
            .ecosystemtowsp {
                margin-left: 5%;
            }
            .ecosystemtowsp1 {
                margin-left: 24%;
            }
        }
    }
}
//Security Token Exchange Ecosystem End


//The Best Places to Start Security Token Exchange Start
.startste-bg{
    padding:50px 0 0;
    .startste-title{
        display:block;
        text-align:center;
        margin:0 0 40px;
        width:100%;
        h3{
            font-size:32px;
            padding:18px 40px;
            color:$white;
            position:relative;
            display:inline-block;
            background:$comheading;
            &::before {
                content:"";
                position:absolute;
                left:-49px;
                top:0;
                background-image: url(Images/MarketingResearch/left-shape.png);
                width:50px;
                height:100%;
                background-repeat:no-repeat;
                background-size:100% 100%;
            }
            &::after {
                content:"";
                position:absolute;
                right:-49px;
                top:0;
                background-image: url(Images/MarketingResearch/right-shape.png);
                width:50px;
                height:100%;
                background-repeat:no-repeat;
                background-size:100% 100%;
            }
        }
    }
    .startste-box {
        background: #311b92 url(Images/MarketingResearch/lithuania-bg-img.jpg) no-repeat;
        color:$white;
        min-height:450px;
        border-radius:30px;
        text-align:center;
        background-position:bottom center;
        margin:14px 0;
        span{
            background:#6746c3;
            font-size:26px;
            margin:0px;
            color:$white;
            width:100%;
            border-top-left-radius:30px;
            border-top-right-radius:30px;
            text-align:center;
            padding:10px 0;
            margin-bottom:30px;
            display: inline-block;
            font-weight:bold;
        }
        p{
            font-size:16px;
            padding:0 35px;
        }

        ul{
            padding:0 15px;
            li{
                //list-style:none;
                display:inline-block;
                font-size:24px;
                color:$white;
                font-weight:bold;
                width:31%;
                margin:0 10px;
                vertical-align:top;
                div{
                    min-height: 110px;
                }
                p{
                    font-size:16px;
                    font-weight:normal;
                    padding: 18px 0;
                }
                .stetitle{
                    min-height:40px;
                }
                ul{
                    li{
                        font-size:16px;
                        font-weight:normal;
                        padding:5px 0;
                        width:100%;
                    }
                }
            }
        }
    }
}
@media only screen and (max-width:767px) {
    .startste-bg {
        .startste-title{
            margin: 0;
            h3{
                &::before, &::after{
                    display:none;
                }
            }
        }
        .startste-box{
            min-height:auto;
            span{
                margin-bottom:0px;
            }
            p{
                padding:20px 35px;
            }
            ul{
                padding:0 15px 15px 0px;
                li{
                    font-size:20px;
                    width:100%;
                    div{
                        min-height:0;
                    }
                }
            }
        }
    }
}

@media only screen and (min-width:768px) and (max-width:1024px)  {
    .startste-bg {
        .startste-title{
            margin: 0;
            h3{
                &::before, &::after{
                    display:none;
                }
            }
        }
        .startste-box{
            min-height:auto;
            span{
                margin-bottom:0px;
            }
            p{
                padding:20px 35px;
            }
            ul{
                padding:0 15px 15px 0px;
                li{
                    font-size:20px;
                    width:100%;
                    div{
                        min-height:0;
                    }
                }
            }
        }
    }
}
//The Best Places to Start Security Token Exchange End

//Future Usage of Security Token Exchanges Start
.futureusage-bg{
    background:#301b92;
    padding:50px 0;
    margin:50px 0;
    .futureusage-title{
        display: block;
        text-align: center;
        width:100%;
        span{
            padding:5px 40px 12px;
            color: $white;
            font-size:32px;
            position: relative;
            display: inline-block;
            background:$comheading;
            &::before {
                content: "";
                position: absolute;
                left: -49px;
                top: 0;
                background-image: url(Images/MarketingResearch/left-shape.png);
                width: 50px;
                height: 100%;
                background-repeat: no-repeat;
                background-size: 100% 100%;
            }
            &::after {
                content: "";
                position: absolute;
                right: -49px;
                top: 0;
                background-image: url(Images/MarketingResearch/right-shape.png);
                width: 50px;
                height: 100%;
                background-repeat: no-repeat;
                background-size: 100% 100%;
            }
        }
    }
    .futureusage-box{
        margin-top:70px;
        text-align:center;
        
        .futureusage-details{
            text-align:left;  
            span{
                font-size:26px;
                color:$white;
                padding: 10px 0;
                display: inline-block;
                font-weight:bold; 
            }
            p{
                font-size:16px;
                color: $white;
            }
        }
    }
}

@media only screen and (max-width:767px) {
    .futureusage-bg{
        padding:20px 0;
        .futureusage-title{
            span{
                font-size:24px !important;
                &::after, &::before{
                    display:none;
                }
            }
        }
        .futureusage-box {
            margin-top:20px !important;
        }
    }
}

@media only screen and (min-width:768px) and (max-width:1024px)  {
    .futureusage-title{
        span{
            &::after, &::before{
                display:none;
            }
        }
    }
}
//Future Usage of Security Token Exchanges End


//Limitations in the current security token exchange market Start
.LimitSTEM-title{
    display:block;
    text-align:center;
    width:100%;
    margin:0 0 50px;
    span{
        padding:5px 40px 12px;
        color: $white;
        font-size:32px;
        position: relative;
        display: inline-block;
        background:$comheading;
        &::before {
            content: "";
            position: absolute;
            left: -49px;
            top: 0;
            background-image: url(Images/MarketingResearch/left-shape.png);
            width: 50px;
            height: 100%;
            background-repeat: no-repeat;
            background-size: 100% 100%;
        }
        &::after {
            content: "";
            position: absolute;
            right: -49px;
            top: 0;
            background-image: url(Images/MarketingResearch/right-shape.png);
            width: 50px;
            height: 100%;
            background-repeat: no-repeat;
            background-size: 100% 100%;
        }
    }
}
.limitations-box {
    box-shadow: 5px 5px 5px 0px rgba(0,0,0,0.2);
    padding: 15px 15px 15px 30px;
    border-left: 22px solid #6746c3;
    border-top-right-radius: 100px;
    border-bottom-right-radius: 100px;
    margin-bottom: 33px;
    span{
        font-size:26px;
        color: #031937;
        font-weight:bold;
        padding-bottom:9px;
        padding-top:6px;
    }
    p{
        font-size:16px;
        color: #333333;
        font-weight: normal;
    }
}

.limitations-box {
    &:nth-child(odd){
        border-left:22px solid #4fb3be;
    }
}

@media only screen and (max-width:767px) {
    .LimitSTEM-title{
        span{
            font-size:26px;
            &::after, &::before{
                display:none;
            }
        }
    }
    .limitations-box{
        border-top-right-radius:0;
        border-bottom-right-radius:0;
        margin-left:0px !important;
        margin-right:0px !important;
        text-align:center;
        padding:15px 0px 15px 0px;
        span{
            font-size:18px;
            margin:10px 0 0;
            display:inline-block;
        }
        p{
            font-size:14px;
        }
        img{
            width:80px;
        }
    }
}
@media only screen and (min-width:768px) and (max-width:1024px)  {
    .limitations-box{
        margin-left:0px !important;
        margin-right:0px !important;
        padding:15px 0px 15px 0px;
        span{
            font-size:18px;
        }
        p{
            font-size:14px;
            padding: 10px 0 0 0;
        }
        img{
            width:120px;
        }
    }
    .limitations-box ~  img {
        text-align:center;
    }
}
//Limitations in the current security token exchange market End


//Potential Available Market for Security Token Exchanges Start
.PotentialAvailableMarket-bg{
    background:#000063;
    padding:50px 0;
    margin:50px 0;
    .potential-title{
        text-align:center;
        span{
            padding:5px 40px 12px;
            color: $white;
            font-size:32px;
            font-weight: bold;
            position: relative;
            display: inline-block;
            background:#311b92;
            &::before {
                content: "";
                position: absolute;
                left: -49px;
                top: 0;
                background-image: url(Images/MarketingResearch/left-shape-b.png);
                width: 50px;
                height: 100%;
                background-repeat: no-repeat;
                background-size: 100% 100%;
            }
            &::after {
                content: "";
                position: absolute;
                right: -49px;
                top: 0;
                background-image: url(Images/MarketingResearch/right-shape-b.png);
                width: 50px;
                height: 100%;
                background-repeat: no-repeat;
                background-size: 100% 100%;
            }
        }
        p{
            font-size:16px;
            line-height:26px;
            color:$white;
            text-align: center;
            padding-bottom: 24px;
            margin:40px 0 0 0;
            padding:0 60px 0 60px;
        }
    }
    .potential-box{
        margin:50px 0 0;
        span{
            position:relative;
            font-size:22px;
            color: $white;
            background-image:url(Images/MarketingResearch/potential-icone-1.png);
            background-color:#311b92;
            background-repeat:no-repeat;
            display:inline-block;
            width:480px;
            padding:16px 17px 22px 31px;
            background-position-x:97%;
            background-position-y:46%;
            background-size:50px;
            font-weight:bold;
            &::after {
                position:absolute;
                top:0;
                right:-35px;
                content:"";
                width:0;
                height:0;
                border-style:solid;
                border-width:75px 35px 0 0;
                border-color:#311b92 transparent transparent transparent;
            }
        }
    }
    .potential-detial-box{
        background:$white;
        width:100%;
        padding:20px 30px;
        min-height:350px;
        p{
            font-size:17px;
            line-height:26px;
            margin:0px;
            color:$black;
            font-weight:normal;
            padding-bottom:14px;
        }
        ul{
            padding:0 15px;
            li{
                font-size:17px;
                color:$black;
                line-height: 40px;
            }
        }
    }
}


@media only screen and (max-width:767px) {
    .PotentialAvailableMarket-bg{
        .potential-title{
            span{
                padding:5px 15px 12px;
                font-size:24px;
                &::after, &::before{
                    display:none;
                }
            }
            p{
                padding:0;
            }
        }
        .potential-box{
            span{
                width:100%;
                padding:16px 17px 22px 15px;
                &::after{
                    display:none;
                }
            }
        }
        .potential-detial-box{
            padding: 20px 15px;
        }
    }
}

@media only screen and (min-width:768px) and (max-width:1024px)  {
    .PotentialAvailableMarket-bg{
        .potential-title{
            span{
                font-size:24px;
            }
        }
        .potential-detial-box {
            min-height:auto;
        }
        .potential-box{
            span{
                font-weight:normal;
            }
        }
    }
}
//Potential Available Market for Security Token Exchanges End

//Total Serviceable Market for Security Token Exchanges Start
.totalservices-bg{
    padding:0px 0 50px;
    .totalservicestitle{
        text-align:center;
        span{
            padding:5px 40px 12px;
            color: $white;
            font-size:32px;
            font-weight:bold;
            position: relative;
            display: inline-block;
            background:$comheading;
            &::before {
                content: "";
                position: absolute;
                left: -49px;
                top: 0;
                background-image: url(Images/MarketingResearch/left-shape.png);
                width: 50px;
                height: 100%;
                background-repeat: no-repeat;
                background-size: 100% 100%;
            }
            &::after {
                content: "";
                position: absolute;
                right: -49px;
                top: 0;
                background-image: url(Images/MarketingResearch/right-shape.png);
                width: 50px;
                height: 100%;
                background-repeat: no-repeat;
                background-size: 100% 100%;
            }
        }
        p{
            text-align:center;
            margin-bottom:35px;
            padding:22px 110px 0;
            color:$lightblack;
        }
    }
    .tsmsecurity-box{
        .tsm-details{
            .tsm-detailsbox{
                background:#F1F1F1;
                text-align:center;
                min-height:440px;
                img{
                    padding:30px 0;
                }
                span {
                    font-size:26px;
                    font-weight:bold;
                    text-align:center;
                    padding:12px 0;
                    background:#6746c3;
                    color:$white;
                    width:100%;
                    display:inline-block;
                }
                p{
                    padding:0 15px 30px;
                }
            }
        }
        .tsm-details:nth-child(1){
            .tsm-detailsbox{
                border-bottom:3px solid #6746c3;
                span{
                    background:#6746c3 !important;
                }
            }
            
            &::before{
                position: absolute;
                content: "";
                background-image:url(Images/MarketingResearch/sm-box-shap.png);
                background-repeat: no-repeat;
                height: 290px;
                background-size: contain;
                right: -2px;
                width: 5px;
                top: 50%;
                transform: translateY(-50%);
                -moz-transform: translateY(-50%);
                -webkit-transform: translateY(-50%);
            
            }
        }
        .tsm-details:nth-child(2){
            .tsm-detailsbox{
                border-bottom:3px solid #311b92;
                span{
                    background:#311b92 !important;
                }
            }
            &::before{
                position: absolute;
                content: "";
                background-image:url(Images/MarketingResearch/sm-box-shap.png);
                background-repeat: no-repeat;
                height: 290px;
                background-size: contain;
                right: -2px;
                width: 5px;
                top: 50%;
                transform: translateY(-50%);
                -moz-transform: translateY(-50%);
                -webkit-transform: translateY(-50%);
            
            }
        }
        .tsm-details:nth-child(3){
            .tsm-detailsbox{
                border-bottom:3px solid #000063;
                span{
                    background:#000063 !important;
                }
            }
        }
    }
}

@media only screen and (max-width:767px) {
    .totalservices-bg {
        .totalservicestitle{
            span{
                font-size:18px;
                &::after, &::before{
                    display:none;
                }
            }
            p{
                padding:22px 0 0;
            }
        }
        .tsm-detailsbox{
            margin-bottom:30px;
        }
        .tsm-details:nth-child(2), .tsm-details:nth-child(1){
            &::before{
                display:none;
            }
        }
    }
}
@media only screen and (min-width:768px) and (max-width:1024px)  {
    .totalservices-bg {
        .totalservicestitle{
            span{
                font-size:24px;
            }
        }
        .tsm-detailsbox{
            margin-bottom:30px;
        }
        .tsm-details:nth-child(2){
            &::before{
                display:none;
            }
        }
    }
}
//Total Serviceable Market for Security Token Exchanges End

//Worldwide Acceptance of Security Tokens Start
.worldwideacceptance-bg{
    .worldwide-title{
        margin-bottom: 40px;
        display: block;
        text-align: center;
        width: 100%;
        span{
            padding:10px 40px;
            color:$white;
            font-size:32px;
            font-weight:bold;
            position:relative;
            display:inline-block;
            background:#000062;
            &::before {
                content: "";
                position: absolute;
                left: -49px;
                top: 0px;
                background-image:url(Images/MarketingResearch/left-shape.png);
                width: 50px;
                height: 100%;
                background-size: 100% 100%;
                background-repeat: no-repeat;
            }
            &::after {
                content: "";
                position: absolute;
                right: -49px;
                top: 0px;
                background-image:url(Images/MarketingResearch/right-shape.png);
                width: 50px;
                height: 100%;
                background-size: 100% 100%;
                background-repeat: no-repeat;
            }
        }
    }
    .wast-cerbox{
        height: 140px;
        width: 360px;
        background: #000063;
        text-align: center;
        margin: 0 auto;
        position: relative;
        margin-top: 60px;
        &::before {
            position:absolute;
            content:"";
            border-bottom:2px solid #003e67;
            width:209px;
            top:47%;
            left:-57.5%;
        }
        &::after {
            position:absolute;
            content:"";
            border-bottom:2px solid #003e67;
            width:196px;
            top:48%;
            right:-54%;
        }
        .wb-box-icon{
            position: absolute;
            left: 50%;
            top: -50%;
            transform: translateX(-50%);
            -moz-transform: translateX(-50%);
            -webkit-transform: translateX(-50%);
        }
        h3{
            color:$white;
            font-size:32px;
            padding-top:80px;
        }
    }

    .worldwide-security-box {
        margin-top: 115px;
        .worldwide-box {
            display:inline-block;
            margin-right:4%;
            background:#4fb3bf;
            min-height:315px;
            vertical-align:top;
            position:relative;
            padding:0 25px;
            &::before {
                position:absolute;
                content:"";
                border-left:2px solid #003e67;
                height: 130px;
                left: 50%;
                top: -60%;
                z-index:-1;
            }
        }
        .worldwidecolor {
            background-color:#00838f;
        }
        .worldwidecolor1 {
            background-color:#005662;
        }
        .worldwide-box-icon {
            position: absolute;
            top: -20%;
            left: 50%;
            -moz-transform: translateX(-50%);
            -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
        }
        .worldwide-des{
            text-align:center;
            span{
                font-size:26px;
                color:$white;
                padding-top:80px;
                display:inline-block;
                font-weight:bold;
            }
            p{
                color:$white;
                margin-top:10px;
                font-size:16px;
            }
        }
    }

    .healthcare-industry{
        padding:50px 0;
        .helth-box{
            background: #6746c3;
            text-align: center;
            margin: 0 auto;
            position: relative;
            width: 480px;
            border-radius: 10px;
            padding: 25px 0;
            &::before {
                content: "";
                position: absolute;
                background-image: url(Images/MarketingResearch/helthcare-shape.png);
                background-size: contain;
                background-repeat: no-repeat;
                width: 124px;
                height: 130px;
                left: -35%;
                top: 50%;
            }
            &::after {
                content: "";
                background-size: contain;
                background-repeat: no-repeat;
                position: absolute;
                background-image: url(Images/MarketingResearch/helthcare-shape2.png);
                width: 124px;
                height: 130px;
                right: -35%;
                top: 50%;
            }
            h3{
                color:$white;
                font-size:32px;
                padding:15px 0 0;
                font-weight:bold;
            }
        }
    }
    .helthleft-box {
        width:100%;
        background: #311b92;
        border-radius: 10px;
        height:205px;
        display:inline-block;
        .helthleft{
            background:#6746c3;
            display:inline-block;
            vertical-align:middle;
            height:100%;
            padding:30px;
            border-top-left-radius:10px;
            border-bottom-left-radius:10px;
            text-align:center;
            span{
                font-size:26px;
                color:$white;
                margin-top:12px;
                text-align:center;
                display:inline-block;
                font-weight:bold;
            }
        }
        p{
            font-size:16px;
            color:$white;
            padding:35px 10px;
        }
    }

    .techimg{
        text-align:center;
        position:relative;
        margin-top:70px;
    }
    .techno-box-main {
        margin-top:70px;
        position: relative;
        .tech-bg {
            background-image: url(Images/MarketingResearch/tech-bg.png);
            background-repeat: no-repeat;
            min-height: 180px;
            background-position: center center;
            position: absolute;
            z-index: -1;
            background-size: cover;
            min-width: 910px;
            top: -57%;
            display: inline-block;
            left: 50%;
            transform: translateX(-50%);
            -moz-transform: translateX(-50%);
            -webkit-transform: translateX(-50%);
        }
        .technology-box {
            border-bottom: 12px solid #4fb3be;
            vertical-align: top;
            display: inline-block;
            padding: 0 25px;
            position: relative;
            width: 23%;
            min-height: 365px;
            border-radius: 30px;
            box-shadow: 10px 4px 20px rgba(164, 164, 164, 0.5);
            margin:0 10px;
            &:nth-child(2n) {
                border-color: #00838f;
            }
            .tech-icon{
                display: block;
                position: absolute;
                top: -15%;
                left: 50%;
                transform: translateX(-50%);
                -moz-transform: translateX(-50%);
                -webkit-transform: translateX(-50%);
            }
            .tech-details{
                text-align:center;
                margin-top:60px;
                span{
                    font-size:26px;
                    color:#003d67;
                    margin-bottom:10px;
                    display:inline-block;
                    font-weight:bold;
                }
                p{
                    font-size:16px;
                    line-height:24px;
                }
            }
        }
    }
    .neufund-box-main {
        background:#000063;
        border-radius:145px;
        margin:50px 0;
        .neufund-box {
            display: inline-block;
            vertical-align: middle;
            position: relative;
            .neu-icon-txt {
                position: absolute;
                bottom: 10%;
                left: 3%;
                text-align: center;
                font-weight: bold;
                width: 18%;
                span {
                    font-size:25px;
                    color:$white;
                    text-align:center;
                }
            }
        }
        .neu-content{
            display: inline-block;
            width: 70%;
            padding-left: 35px;
            vertical-align: middle;
            color:$white;
            span{
                font-size: 26px;
                font-weight: bold;
                display: inline-block;
                width: 100%;
                padding: 10px 0 0;
            }
            p{
                font-size:16px;
            }
        }
    }
}

@media only screen and (min-width:768px) and (max-width:1024px)  {
    .worldwideacceptance-bg{
        .wast-cerbox {
            &::before{
                width:67px;
                top:38%;
                left:-17.5%;
            }
            &::after{
                width:67px;
                top:38%;
                right:-17.5%;
            }
        }
        .worldwide-security-box {
            .worldwide-box{
                min-height:460px;
                &::before{
                    left:52%;
                    top:-44%;
                }
            }
        }
        .healthcare-industry{
            .helth-box{
                &::before, &::after{
                    display:none;
                }
            }
        }
        .helthleft-box{
            height:auto;
            .helthleft{
                padding:30px 0;
            }
        }

        .techno-box-main{
            .technology-box {
                margin:0 6px;
                padding:0 10px;
                min-height:510px;
            }
        }
    }
    .neu-content{
        width:100%;
    }
    .neufund-box{
        img, .neu-icon-txt{
            display:none;
        }
    }
}

@media only screen and (max-width:767px) {
    .worldwide-title{
        span{
            font-size:20px !important;
            &::before, &::after{
                display:none;
            }
        }
    }
    .wast-cerbox, .worldwide-box{
        &::before, &::after{
            display:none;
        }
    }
    .wast-cerbox{
        h3{
            font-size:20px !important;
        }
    }
    .worldwide-box-icon, .wb-box-icon{
        top: -12% !important;
        img{
            width:80px;
        }
    }
    .worldwide-des {
        span{
            padding-top:60px !important;
            font-size:20px !important;
        }
    }
    .worldwide-security-box {
        margin-top:50px !important;
    }
    .worldwide-box {
        min-height:auto !important;
        margin-right:0 !important;
        margin-bottom:50px !important;
    }

    .healthcare-industry{
        .helth-box{
            width:100% !important;
            &::before, &::after{
                display:none;
            }
        }
    }
    .helthleft-box{
        height:auto !important;
        margin:0 0 50px !important;
    }
    .helthleft{
        width:100% !important;
        span{
            font-size:20px !important;
            width:100%;
            display:inline-block;
        }
    }

    .techno-box-main{
        .tech-bg{
            display:none !important;
        }
        .technology-box{
            width:100% !important;
            min-height:auto !important;
            margin:0 10px 60px !important;
            .tech-icon{
                img{
                    width:80px !important;
                }
            }
        }
    }
    .neufund-box-main{
        border-radius:0 !important;
        margin: 0px 0 50px;
        width: 100%;
        .neufund-box{
            img, .neu-icon-txt{
                display:none;
            }
        }
        .neu-content{
            width:100% !important;
        }
    }
    
}
//Worldwide Acceptance of Security Tokens End


//Current Market Performance for Security Token Exchange Development Start
.currentmarket-bg{
    padding:50px 0;
    .currentmarket-title{
        text-align: center;
        width: 100%;
        p{
            text-align: center;
            margin-bottom:35px;
            padding: 22px 110px 0;
            color:$lightblack;
        }
        h3{
            padding:15px 40px;
            color:$white;
            font-size:28px;
            font-weight:bold;
            position:relative;
            display:inline-block;
            background:#000062;
            &::before {
                content: "";
                position: absolute;
                left: -49px;
                top: 0px;
                background-image:url(Images/MarketingResearch/left-shape.png);
                width: 50px;
                height: 100%;
                background-size: 100% 100%;
                background-repeat: no-repeat;
            }
            &::after {
                content: "";
                position: absolute;
                right: -49px;
                top: 0px;
                background-image:url(Images/MarketingResearch/right-shape.png);
                width: 50px;
                height: 100%;
                background-size: 100% 100%;
                background-repeat: no-repeat;
            }
        }
    }
    .currentmarket-details{
        span{
            color: #003d67;
            font-size:28px;
            margin-bottom:10px;
            font-weight:bold;
            display: inline-block;
        }
        p{
            font-size: 16px;
            line-height: 26px;
        }
    }
}
@media only screen and (max-width:767px) {
    .currentmarket-title{
        h3{
            font-size:22px !important;
            padding:10px 5px !important;
            &::after, &::before{
                display:none;   
            }
        }
        p{
            padding:22px 10px 0 !important;
        }
    }
    .currentmarket-details{
        text-align:center;
        span{
            font-size:22px !important; 
        }
    }
}
//Current Market Performance for Security Token Exchange Development End

//Security Token Exchange-Related Revenue Streams Start
.relate-bg{
    background:#000063;
    padding:50px 0;
    .relate-title{
        margin-bottom: 40px;
        display: block;
        text-align: center;
        width:100%;
        span{
            padding:5px 40px 12px;
            color: $white;
            font-size:32px;
            font-weight:bold;
            position: relative;
            display: inline-block;
            background:#311b92;
            &::before {
                content: "";
                position: absolute;
                left: -49px;
                top: 0;
                background-image: url(Images/MarketingResearch/left-shape-b.png);
                width: 50px;
                height: 100%;
                background-repeat: no-repeat;
                background-size: 100% 100%;
            }
            &::after {
                content: "";
                position: absolute;
                right: -49px;
                top: 0;
                background-image: url(Images/MarketingResearch/right-shape-b.png);
                width: 50px;
                height: 100%;
                background-repeat: no-repeat;
                background-size: 100% 100%;
            }
        }
    }
    .revenue-box{
        background:#311b92;
        min-height:295px;
        border-radius:10px;
        padding:30px;
        margin-bottom:30px;
        color:$white;
        span{
            color:$white;
            font-size:28px;
            margin-bottom:15px;
            font-weight:bold;
        }
        p{
            font-size:16px;
            line-height:26px;
            padding:10px 0 0;
        }
        img{
            right: 0;
            position: absolute;
        }
    }
}

@media only screen and (max-width:767px) {
    .relate-title{
        span{
            font-size:22px !important;
            padding:10px 5px !important;
            &::after, &::before{
                display:none;   
            }
        }
    }
    .revenue-box{
        img{
            right:unset !important;
            position:relative !important;
        }
        span{
            font-size:28px !important;
            margin-bottom:0 !important;
            margin-top: 30px;
            display: inline-block;
        }
    }
}
//Security Token Exchange-Related Revenue Streams End


//Key Players in the Security Token Exchange Market Start
.keyplayers-bg{
    padding:50px 0 30px;
    .keyplayers-title{
        margin-bottom: 40px;
        display: block;
        text-align: center;
        width: 100%;
        span{
            padding:18px 40px;
            color:$white;
            position:relative;
            display:inline-block;
            background:#000062;
            font-size:32px;
            font-weight:bold;
            &::before {
                content:"";
                position:absolute;
                left:-49px;
                top: 0px;
                background-image:url(Images/MarketingResearch/left-shape.png);
                width:50px;
                height:100%;
                background-size:100% 100%;
                background-repeat:no-repeat;
            }
            &::after {
                content:"";
                position:absolute;
                right:-49px;
                top:0px;
                background-image:url(Images/MarketingResearch/right-shape.png);
                width:50px;
                height:100%;
                background-size:100% 100%;
                background-repeat:no-repeat;
            }
        }
    }
    .keyplayers-box{
        margin-bottom:35px;
        background:#f7f7f7;
        border:1px solid #dbdbdb;
        border-radius:10px;
        padding:15px 0;
        h3{
            font-size:28px;
            margin-top:10px;
            color:#003d67;
        }
        p{
            font-size:20px;
            color:#003d67;
            margin: 0px;
            font-weight:600;
        }
        ul{
            padding:5px 15px; 
            li{
                list-style:none;
                font-size:15px;
                &::before {
                    content: "\2022";
                    color: #6746c3;
                    font-size: 18px;
                    font-weight: bold;
                    display: inline-block;
                    margin-left:12px;
                    left: 0;
                    position: absolute;
                }
            }
        }
    }
}


@media only screen and (max-width:767px) {
    .keyplayers-title{
        span{
            font-size:26px;
            &::after, &::before{
                display:none;
            }
        }
    }
}
//Key Players in the Security Token Exchange Market End

//Stock Exchanges Interested in Launching Security Token Exchanges Start
.launchingste-bg{
    padding:0 0 50px 0;
    .launchingste-title{
        margin-bottom: 40px;
        display: block;
        text-align: center;
        width: 100%;
        span{
            padding:18px 40px;
            color:$white;
            position:relative;
            display:inline-block;
            background:#000062;
            font-size:32px;
            font-weight:bold;
            &::before {
                content:"";
                position:absolute;
                left:-49px;
                top: 0px;
                background-image:url(Images/MarketingResearch/left-shape.png);
                width:50px;
                height:100%;
                background-size:100% 100%;
                background-repeat:no-repeat;
            }
            &::after {
                content:"";
                position:absolute;
                right:-49px;
                top:0px;
                background-image:url(Images/MarketingResearch/right-shape.png);
                width:50px;
                height:100%;
                background-size:100% 100%;
                background-repeat:no-repeat;
            }
        }
    }

    .launchingste-box{
        display:inline-block;
        vertical-align:top;
        border:1px solid #a5a5a5;
        border-radius:25px;
        text-align:center;
        margin-bottom:30px;
        img{
            padding:30px;
        }
        span{
            background-color:#6746c3;
            font-size:26px;
            padding:10px 0;
            color:$white;
            width:100%;
            display:inline-block;
            font-weight:bold;
        }
        p{
            color:$white;
            min-height:220px;
            background-color:#311b92;
            padding:20px 20px 20px;
            border-bottom-left-radius:25px;
            border-bottom-right-radius:25px;
            margin:0;
        }
    }
}

@media only screen and (max-width:767px){
    .launchingste-bg {
        .launchingste-title{
            span{
                padding:18px 15px;
                font-size:20px;
                &::before, &::after{
                    display:none !important;
                }
            }
        }
    }
}


@media only screen and (min-width: 768px) and (max-width: 1024px) {
    .launchingste-bg {
        .launchingste-box{
            span{
                font-size:20px !important;
            }
            p{
                min-height:290px;
            }
        }
        .launchingste-title{
            span{
                &::before, &::after{
                    display:none !important;
                }
            }
        }
    }
}

//Stock Exchanges Interested in Launching Security Token Exchanges End

//Parting Words Start
.partingwords-bg{
    display:inline-block;
    background-position:center;
    background-image:url(Images/MarketingResearch/site-footer-bg.jpg);
    background-repeat:no-repeat;
    background-size:cover;
    padding: 70px 0;
    
    .partingwords-title{
        display: block;
        text-align: center;
        width: 100%;
        span{
            background:#311b92;
            padding:10px 40px;
            color:$white;
            position:relative;
            font-weight:bold;
            font-size:32px;
            &::before {
                content: "";
                position: absolute;
                left: -49px;
                top: 0;
                background-image: url(Images/MarketingResearch/left-shape-b.png);
                width: 50px;
                height: 100%;
                background-repeat: no-repeat;
                background-size: 100% 100%;
            }
            &::after {
                content: "";
                position: absolute;
                right: -49px;
                top: 0;
                background-image: url(Images/MarketingResearch/right-shape-b.png);
                width: 50px;
                height: 100%;
                background-repeat: no-repeat;
                background-size: 100% 100%;
            }
        }
        p{
            color:$white;
            font-size:16px;
        }
    }
    
    .partingwords-logo{
        margin:50px 0;
    }
}

//Parting Words End


@media only screen and (max-width:767px) {
    .differentareasmain, .area-heading{
        h2, h3{
            &::after, &::before{
                display:none !important;
            }
        }
    }
    .area-heading{
        h3{
            border-radius:5px;
        }
        p{
            margin-top: 20px;
            margin-bottom: 40px;
        }
    }
    .cryptohead{
        h2, h3{
            &::after, &::before{
               width:auto !important;
            }
        }
    }
}

@media only screen and (max-width:767px) {
    .utilitysecuritytokens-bg, .CryptocurrencyExchange-bg {
        display:none;
    }
}