//Common Main Title Start
.enterprisetitle{
    padding:0px 0 10px;
    h2, h3, h4, h5{
        font-size:32px;
        color:$darkblue;
        text-align: left;
        font-weight: 700;
        font-style: normal;
    }
    p{
        font-size:16px;
        color:$github;
    }
}

.enterpriseabouttitle{
    h2{
        font-size:26px;
        color:$darkblue;
        text-align:left;
        font-weight:500;
        font-style: normal;
    }
    p{
        font-size:16px;
        color:$github;
    }
}

//Enterprise Crypto Exchange Banner

.enterpriseexchange-bg{
    padding:100px 0;
    background:$bannerlightgray;
    background-image:url(Images/features-bg.jpg);
    background-size: cover;
    background-position:center;
    overflow: hidden;
    .jumbotron{
        background:transparent;
        padding:0;
        margin:0;
    }
    .bannerdetails{
        text-align: center;
        padding:50px 0 0;
        h1{
            font-size:34px;
            color:$darkblue;
            line-height:50px;
            text-align:center;
            font-weight:700;
            font-style:normal;
        }
        p{
            font-size:20px;
            color:$darkblue;
            line-height:36px;
            text-align:center;
        }
        button, a{
            background:$lightorange;
            font-weight:bold;
            border-radius:30px;
            padding:10px 30px;
            color:$white;
            border:none;
            margin-top:30px;
            display: inline-block;
            &:hover{
               background:$gradient-bluesky;
               text-decoration:none;
            }
        }
    }
}

//Cryptocurrency Exchange Software Development Features
.enterprisefeatures-bg{
    background:$bannerlightgray;
    padding:30px 0 30px;
    .enterprisefeaturesbox{
        background-color:$white;
        padding:10px 20px;
        box-shadow:15px 10px 30px rgba(0, 0, 0, 0.2);
        min-height:165px;
        border-radius:8px;
        margin-bottom:30px;
        span, h3{
            font-size:20px;
            color:$darkblue;
        }
        p{
            font-size:14px;   
            padding-top:10px;      
        }
    }
}

//Benefits For Bitcoin Exchange Platform
.enterprisebenefitsbox{
    min-height:160px;
    span, h3{
        text-align:left;
        color:$darkblue;
        display:inline-block;
        width:100%;
        font-size:20px;
        font-weight: 600;
    }
    p{
        text-align:left;
        font-size:14px;
    }
}

//Our Exchange Software Offering
.enterpriseexchangesolution{
    span, h3{
        text-align:left;
        color:$darkblue;
        display:inline-block;
        width:100%;
        font-size:24px;
    }
    ul{
        li{
            padding-bottom:20px;
            font-size:20px;
            list-style: none;
            padding-left: 15px;
            &:after{
                background: url(Images/itemright.png);
                content: "";
                position: absolute;
                left:15px;
                right: 0;
                margin: 0;
                width: 30px;
                height: 30px;
                background-repeat: no-repeat;
            }
        }
    }
    a{
        background:$gradient-skyblue;
        color:$white;
        border-radius: 30px;
        padding: 10px 30px;
        border: none;
        margin-top: 0;
        display: inline-block;
        margin-bottom: 20px;
        &:hover{
            background:$gradient-bluesky;
            color:$white;
            text-decoration:none;
        }
    }
}

//Looking to Start Exchange
.enterprisebranded-bg{
    background:$gradient-skyblue;
    padding:30px 0;
    span{
        color:$white;
        font-size:24px;
    }
    a{
        background-color:$white;
        color:$darkblue;
        cursor: pointer;
        box-shadow: 0 2px 1px rgba(0,0,0,0.1), 0 1px 3px rgba(0,0,0,0.3);
        padding: 10px 20px;
        font-size: 18px;
        border-radius: 3px;
        margin: 0px 0 0 0;
        display: inline-block;
        &:hover{
            color:$darkblue;
            box-shadow: 0 4px 2px rgba(0,0,0,0.1), 0 1px 3px rgba(0,0,0,0.3);
            text-decoration:none;
        }
    }
}

//Why Choose Espay
.whychoosebox{
    background-color:$white;
    font-size:20px;
    padding:25px 30px;
    color:$darkblue;
    box-shadow:15px 10px 30px rgba(0, 0, 0, 0.2);
    text-align:center;
    img{
        width:80px;
    }
    span{
        width:100%;
        display:inline-block;
        padding-top:20px;
    }
}

.enterpriseexchangeproduct{
    background-color:$lightwhite;
    margin-top:50px;
    padding:30px 0;

    .enterpriseproductbox{
        background:$white;
        padding:15px 20px;
        min-height:280px;
        margin:0 0 30px;
        a{
            img{
                width:70%;
                margin:0 15% 0;
            }
            text-decoration:none;
            color:$github;  
            span, h3{
                font-size:20px;
                color:$darkblue;
                margin:15px 0 5px;
                display:inline-block;
                font-weight:700;
            }
            p{
                font-size:15px;
                color:$github;  
            }
        }
        &:hover {
            transition: all 2s ease;
            transform: scale(1.0);
            
        }  
    }
}

@media only screen and (min-width: 320px) and (max-width: 680px)  {

    //feature-icon
    .img-fluid.enterprise-feature-icon{
        height: 100px;
    }

    //offering
    .enterprice-feature-img{
        max-width: 100%;
    }
}
@media only screen and (max-width:767px) {
    .enterpriseexchange-bg{
        padding:0;
        .bannerdetails{
            padding:30px 0 30px;
        }
    }
    .enterprisebenefitsbox {
        min-height: 100px;
    }
}

//Tablet View
@media (min-width: 768px) and (max-width: 1024px) {
    .enterprisefeatures-bg{
        .enterprisefeaturesbox{
            min-height: 270px;
        }
    }
    .enterprisebranded-bg{
        a{
            font-size: 16px;
        }
    }
    .enterpriseexchangeproduct{
        .enterpriseproductbox{
            min-height:380px;
        }
    }
}