.talktoexcpert{
    padding:50px 0 50px;
    background-image:url(Images/features-bg.jpg);
    background-size: cover;
}
.innercontent{
    padding:100px 0 0;
}

.innerfrom{ 
    .card{
        box-shadow: 0 5px 30px rgba(0, 0, 0, 0.2);
        border-radius: 5px;     
            .contact-form-logo{
                    .contact-logo{
                        text-align: center;
                            img{
                                border-radius: 6rem;
                                width: 8%;
                                margin-top: -2%;
                                background: $white;
                            }
                        }
                    }
                }
            }

.Mainfromelement {
    .form-group{
        .form-control{
            border-radius:1rem;
        }
    }
}

.talktoexceprt {
    width: 20%;
    border: none;
    border-radius: 1rem;
    padding: 10px;
    background: $gradient-skyblue;
    font-weight: 600;
    color: $white;
    cursor: pointer;
    margin-top: 20px;
        &:hover{
            background: $gradient-bluesky;
        }
}   

//responsive
@media only screen and (max-width : 767px) {
 
    .contact-logo{
        text-align: center;
            img{
                width: 25% !important;
                margin-top: -8% !important;
            }
        }
    }