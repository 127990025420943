.page_section1{
    padding-bottom: 50px;
    padding-top: 80px;
    background-color: #4471a6;
    text-align: center;

    h1{
        font-size: 52px;
        color:$white;
        text-align: center;
    }
    p{
        font-size: 26px;
        color:$white;
        padding: 10px 0px 10px 0px;
    }
    .found_btn{
        background-color:#FFAF48;
        font-size: 16px;
        text-transform: uppercase;
        border: none;
        box-shadow: 0 2px 3px rgba(0,0,0,0.1), 0 4px 8px rgba(0,0,0,0.3);
        padding: 10px 20px 10px 20px;
        margin:50px 0 0;

        &:hover{
            box-shadow: 0 2px 3px rgba(0,0,0,0.1), 0 4px 8px rgba(0,0,0,0.3);
            background-color: #FFAF48;
            border: none;
        }
        a{
            color:$white;

            &:hover{
                color:$white;
                text-decoration: none;
            }
        }
    }
}