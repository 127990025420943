// Color Variabless
$white:                           #FFFFFF;
$black:                           #000000;
$lightblack:                      #212121;
$light-yellow:                    #FFF59D;
$twitter:                         #52AEFF;
$facebook:                        #3B5998;
$google:                          #F13A30;
$pinterest:                       #d32f2f;
$instagram:                       #8d6e63;
$rss:                             #ff9800;
$tumblr:                          #607d8b;
$linkedin:                        #4875B4;
$dribbble:                        #f06292;
$youtube:                         #d50000;
$github:                          #424242;
$skype:                           #4fc3f7;
$lightgray:                       #F4F7FA;
$lightgreen:                      #249c29;
$darkorange:                      #F9672D;
$darakwhite:                      #dfdfdf;
$darkblue:                        #20126f;
$lightborder:                     #f7f7f7;
$lightsky:                        #379DB7;
$lightblue:                       #28d1ca;
$bannerlightgray:                 #f8f8f8;
$lightwhite:                      #eef4f5;
$lightblack:                      #1e2221;
$burlywood:                       #deb887;
$landingbgsection11:              #ebebeb;
$coverflow :                      rgba(255, 255, 255, 0.1);
$timelinebg:                      #dcdcdc;
$mision_bg :                      #f8f8f8;
$heading :                        #20126f;
$lightorange :                    #FFAF48;
$comheading :                     #000062;


 // Gradient Color
$gradient-skyblue : linear-gradient(to right, #4470a6 , #28d1ca);
$gradient-bluesky : linear-gradient(to right, #28d1ca , #4470a6);

//$gradient-skyblue : linear-gradient(to right, #28d1ca , #4470a6);
//$gradient-bluesky : linear-gradient(to right, #4470a6 , #28d1ca);


