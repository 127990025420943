// Lavrage Page

.lavragemarginex-section {
    padding: 50px 0px;
    .lavragemarginex-content {
        padding-top: 90px;
        h2 {
            font-size: 32px;
            color: #20126f;
            text-align: left;
            font-weight: 700;
            font-style: normal;
            padding-bottom: 15px;
        }
    }
}

.Leveragework-bg {
    background-image: url("Images/Leverage/leveragebanner.jpg");
    min-height: 100%;
    background-size: cover;
    position: relative;
    background-attachment: fixed;
    width: 100%;

    .lavrage-works-content {
        padding: 20px;
        background: radial-gradient(circle, rgba(34, 193, 195, 1) 0%, rgba(68, 112, 166, 1) 100%);
        h3 {
            font-size: 32px;
            color: #ffffff;
            text-align: left;
            font-weight: 700;
            font-style: normal;
            padding-bottom: 20px;
        }
    }
}

.lavrageadvantage-title {
    h2 {
        font-size: 32px;
        color: #20126f;
        text-align: left;
        font-weight: 700;
        font-style: normal;
        padding-bottom: 15px;
        padding-top: 50px;
    }
}

.lavrageadvantage-section {
    text-align: center;
    margin-top: 50px;
    .lavrageadv-stuff {
        border: 1px solid #52aeff;
        padding: 15px;
        background: #fff;
        box-shadow: 7px 5px 10px rgba(0, 0, 0, 0.1);
        min-height: 408px;
        text-align: center;
        h3 {
            font-size: 24px;
            color: #20126f;
            text-align: left;
            font-weight: 700;
            font-style: normal;
            padding-bottom: 20px;
            text-align: center;
            min-height: 75px;
        }
        img {
            width: 76px;
            padding-bottom: 50px;
        }
    }
}

.start-lavrage-section {
    margin-top: 80px;
    h2 {
        font-size: 32px;
        color: #20126f;
        text-align: left;
        font-weight: 700;
        font-style: normal;
    }
}

.Cutting-edge-section {
    .cuttingbox {
        list-style-type: none;
        display: inline-flex;
        background-color: #ffffff;
        padding: 20px;
        border-radius: 10px;
        box-shadow: 1px 5px 36px rgba(0, 0, 0, 0.1);
        margin: 30px 0 0 0;
        min-height: 236px;
        h4 {
            font-size: 19px;
            font-weight: bold;
            margin-bottom: 5px;
            display: inline-block;
            font-family: Gill Sans Extrabold, sans-serif;
            color: #1e2221;
        }
        p {
            margin-bottom: 0px;
        }
    }
}

.offtertrader-bg {
    padding-top: 50px;
    padding-bottom: 70px;
    h2 {
        font-size: 32px;
        color: #20126f;
        text-align: left;
        font-weight: 700;
        font-style: normal;
        text-align: center;
        padding-bottom: 15px;
    }
    .offerborder {
        border: 1px solid #22d7cf !important;
        padding: 10px;
        margin-bottom: 20px;
        .offertrader-content {
            padding-top: 50px;
        }
    }
}

// Crypto Paymet exchange

.crypto-section {
    text-align: center;
    margin-top: 50px;
    .lavrageadv-stuff {
        border: 1px solid #52aeff;
        padding: 15px;
        background: #fff;
        box-shadow: 7px 5px 10px rgba(0, 0, 0, 0.1);
        min-height: 330px;
        text-align: center;
        h3 {
            font-size: 21px;
            color: #20126f;
            text-align: left;
            font-weight: 700;
            font-style: normal;
            padding-bottom: 20px;
            text-align: center;
        }
        img {
            width: 76px;
            padding-bottom: 50px;
        }
    }
}

////////////////////     ICO Pages           /////////////////////////////

.presalesection {
    .ico-sub-title {
        font-size: 21px;
        color: #20126f;
        text-align: left;
        font-weight: 700;
        font-style: normal;
        padding-bottom: 20px;
    }
    .privatesale-div {
        margin-top: 15px;
    }
}
.double-border {
    padding-top: 2px;
    margin: 25px 0px 25px 0px;
    width: 100%;
    border-top: 1px dashed #aaa;
    border-bottom: 1px dashed #aaa;
}

.icoproject-bg {
    background-image: url("Images/ICO_Marketing/BannerICO.jpg");
    min-height: 100%;
    background-size: cover;
    position: relative;
    background-attachment: fixed;
    width: 100%;

    .icoproject-section {
        padding: 20px;
        background: radial-gradient(circle, rgba(34, 193, 195, 1) 0%, rgba(68, 112, 166, 1) 100%);
        h2 {
            font-size: 32px;
            color: #ffffff;
        }
        h3 {
            font-size: 21px;
            color: #ffffff;
            text-align: left;
            font-weight: 700;
            font-style: normal;
            padding-bottom: 20px;
        }
        p {
            color: #ffffff;
        }
    }
}

.AirDrop {
    background: #f8f8f8;
    border-radius: 15px;
    margin-top: 30px;
    padding: 20px 0px;
}

.icoadvertise {
    margin-top: 70px;
    height: 500px;
    overflow-x: scroll;
    .stoadviceitle {
        padding-bottom: 100px;
    }
    span {
        font-weight: bold;
        padding: 0px 5px;
    }
}
.icoadverstise-bg {
    background-image: url("Images/poster.png");
    min-height: 100%;
    background-size: cover;
    position: relative;
    background-attachment: fixed;
    width: 100%;
    height: 600px;
}
.icoadverstiseone-bg {
    background-image: url("Images/poster1.png");
    min-height: 100%;
    background-size: cover;
    position: relative;
    background-attachment: fixed;
    width: 100%;
    height: 600px;
}
.icoadverstisethree-bg {
    background-image: url("Images/poster3.png");
    min-height: 100%;
    background-size: cover;
    position: relative;
    background-attachment: fixed;
    width: 100%;
    height: 600px;
}
.icoadverstisefour-bg {
    background-image: url("Images/poster4.png");
    min-height: 100%;
    background-size: cover;
    position: relative;
    background-attachment: fixed;
    width: 100%;
    height: 600px;
}
.icoadverstisefive-bg {
    background-image: url("Images/poster5.png");
    min-height: 100%;
    background-size: cover;
    position: relative;
    background-attachment: fixed;
    width: 100%;
    height: 600px;
}
.icoadverstisesix-bg {
    background-image: url("Images/poster6.png");
    min-height: 100%;
    background-size: cover;
    position: relative;
    background-attachment: fixed;
    width: 100%;
    height: 600px;
}
.icoadverstiseseven-bg {
    background-image: url("Images/poster7.png");
    min-height: 100%;
    background-size: cover;
    position: relative;
    background-attachment: fixed;
    width: 100%;
    height: 600px;
}
.icoadverstiseeight-bg {
    background-image: url("Images/poster8.png");
    min-height: 100%;
    background-size: cover;
    position: relative;
    background-attachment: fixed;
    width: 100%;
    height: 600px;
}
.icoadverstise-text {
    padding-top: 120px;
}
.whatisico-section {
    .stoadviceitle {
        padding-top: 185px;
        padding-bottom: 10px;
    }
}

.stoadviceitle-text {
    font-size: 32px;
    color: #20126f;
    text-align: left;
    font-weight: 700;
    font-style: normal;
}
.airdropimg {
    width: 100%;
}
@media only screen and (max-width: 767px) {
    .crypto-section {
        margin-top:0px;
        .lavrageadv-stuff{
            margin-bottom: 20px;
        }
    }
    .lavragemarginex-section .lavragemarginex-content{
        padding-top:0px;
    }
    .whatisico-section .stoadviceitle{
       padding-top: 40px;
   }
}
