//Common Main Title Start
.CookiePolicytitle{
    padding:30px 0 10px;
    h2, h3, h4, h5,h6{
        font-size:22px;
        color:$darkblue;
        text-align: left;
        font-weight: 700;
        font-style: normal;
    }
    p{
        font-size:16px;
        color:$github;
        b{
          font-size:20px;
        }
    }
}

//Cookie Policy Banner Start
.CookiePolicyBanner{
    padding:100px 0;
    background:url(Images/features-bg.jpg);
    background-size:cover;
    background-position:center;
    
    .jumbotron{
        background:transparent;
        padding:0;
        margin:0;
    }
    .CookiePolicydetails{
        text-align: center;
        padding:50px 0 0;
        h1{
            font-size:30px;
            color:$darkblue;
            line-height:50px;
            text-align:center;
            font-weight:700;
            font-style:normal;
        }
        p{
            font-size:20px;
            color:$darkblue;
            line-height:36px;
            text-align:center;
        }
    }
}