//Common Main Title Start
.Centralizedtitle{
    padding:30px 0 10px;
    h2, h3, h4, h5{
        font-size:32px;
        color:$darkblue;
        text-align: left;
        font-weight: 700;
        font-style: normal;
    }
    p{
        font-size:16px;
        color:$github;
    }
}

//Crypto Exchange Banner Start
.CentralizedExchangeSolution{
    padding:90px 0 0;
    //background:$bannerlightgray;
    background-image:url('Images/HeaderBanner.jpg');
    position:relative;
    min-height:450px;
    canvas{
        height:350px !important;
    }
    
    .jumbotron{
        background:transparent;
        padding:0;
        margin:0;
    }
    .bannerdetails{
        //position: absolute;
        text-align: center;
        padding:80px 0;
        h1{
            font-size:34px;
            color:$darkblue;
            line-height:50px;
            text-align:center;
            font-weight:700;
            font-style:normal;
        }
        p{
            font-size:20px;
            color:$darkblue;
            line-height:36px;
            text-align:center;
        }
        button, a{
            background:$lightorange;
            font-weight:bold;
            border-radius:30px;
            padding:10px 15px;
            color:$white;
            border:none;
            margin-top:30px;
            display:inline-block;
            &:hover{
               background:$gradient-bluesky;
               text-decoration:none;
            }
        }
    }
}

//Espay Centralized Exchange Features
.CentralizedFeature-bg{
    background-image:url('Images/features-bg.jpg');
    background-position:center;
    background-size:cover;
    margin:30px 0 0;
}

.centrlized-feature-WhiteLabel-img{
    max-width: 100%;
    position: relative;
    top: 65px;
}

.centrlized-feature-Standard-img{
    max-width: 100%;
}

.centrlized-feature-Enterprise-img{
    max-width: 100%;
    position: relative;
    top: 65px;
}

.CentralizedFeatureBox{
    min-height:230px;
    img{
        width:80px;
    }
    span,h3{
        text-align:left;
        color:$darkblue;
        padding:20px 0 10px 0;
        display:inline-block;
        width:100%;
        font-size:20px;
        font-weight: 600;
    }
    p{
        text-align:left;
        font-size:14px;
        min-height:100px;
    }
}
.CentralizedIdea-bg{
    padding:20px 0;
    background:$bannerlightgray;
    .CentralizedIdealService{
        font-size:20px;
        color:$github;
        text-align:center;
        padding:0 35px;
        img{
            width:110px;
        }
    }
}

.CentralizedBenefitsbox{
    span, h3{
        text-align:left;
        color:$darkblue;
        display:inline-block;
        width:100%;
        font-size:20px;
        font-weight: 600;
    }
    p{
        text-align:left;
        font-size:14px;
    }
}

//Our Centralized Exchange Development Packages
.CentralizedWhiteLabel{
    span, h3, h2{
        text-align:left;
        color:$darkblue;
        display:inline-block;
        width:100%;
        font-size:24px;
    }
    ul{
        li{
            padding-bottom:20px;
            font-size:20px;
            list-style: none;
            padding-left: 15px;
            &:after{
                background: url(Images/itemright.png);
                content: "";
                position: absolute;
                left:15px;
                right: 0;
                margin: 0;
                width: 30px;
                height: 30px;
                background-repeat: no-repeat;
            }
        }
    }
    a{
        background:$gradient-skyblue;
        color:$white;
        border-radius: 30px;
        padding: 10px 30px;
        border: none;
        margin-top: 0;
        display: inline-block;
        margin-bottom: 20px;
        &:hover{
            background:$gradient-bluesky;
            color:$white;
            text-decoration:none;
        }
    }
}

//Our Centralized Exchange Product
.CentralizedExchangeProduct{
    background-color:$lightwhite;
    .centralizedproductbox{
        background:$white;
        padding:15px 20px;
        min-height:250px;
        margin:0 0 30px;
        a{
            img{
                width:70%;
                margin:0 15% 0;
            }
            text-decoration:none;
            color:$github;  
            span, h3{
                font-size:20px;
                color:$darkblue;
                margin:15px 0 5px;
                display:inline-block;
                font-weight:700;
            }
            p{
                font-size:15px;
                color:$github;  
            }
        }
        &:hover {
            transition: all 2s ease;
            transform: scale(1.0);
            
        }  
    }
}


@media only screen and (max-width:767px) {
        .CryptoExchangeSolutionMenu{
            .headermenu{
                display: block;
            }
        }
        .mobilebtnces{
            display:Block;
            position: relative;
            z-index: 9999;
            width: 100%;
            a{
                position:fixed;
                left: 0;
                right: 0;
                top: 0;
                background: orange;
                color: #FFF;
                padding: 6px;
                text-align: center;
            }
        }
    .CentralizedExchangeSolution{
        padding: 20px 0 40px !important;
    }
    //canvas
    .Centralizedcanvas {
        height:440px !important;
    }

    //feature-icon
    .img-fluid.centralized-fea-icon{
        height: 100px;
    }

    //feature-Whitelabel
    .centrlized-feature-WhiteLabel-img{
        max-width: 100%;
        position: relative;
        top: 0px;
    }

    //Enterprise
    .centrlized-feature-Enterprise-img{ 
        max-width: 100%;
        position: relative;
        top: 0px;
    }
}

//Tablet View
@media (min-width: 768px) and (max-width: 1024px) {
    .CentralizedIdea-bg {
        .CentralizedIdealService{
            font-size:14px;
            img{
                width:80%;
            }
        }
    }
    .CentralizedWhiteLabel{
        ul{
            li{
                font-size:16px;
            }
        }
        span,h2{
            font-size:19px;
        }
    }
    .centralizedproductbox{
        min-height:350px !important;
    }
}