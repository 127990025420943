//Common Main Title Start
.dynamiccmsmanagmenttitle{
    padding:0px 0 10px;
    h2, h3, h4, h5{
        font-size:32px;
        color:$darkblue;
        text-align: left;
        font-weight: 700;
        font-style: normal;
    }
    p{
        font-size:16px;
        color:$github;
    }
}

//dynamic-cms-managment Banner Start
.dynamicCMSManagement{
    padding:90px 0 0;
    //background:$bannerlightgray;
    background-image:url('Images/HeaderBanner.jpg');
    position:relative;
    min-height:450px;
    canvas{
        height:350px !important;
    }
    
    .jumbotron{
        background:transparent;
        padding:0;
        margin:0;
    }
    .bannerdetails{
        //position: absolute;
        text-align: center;
        padding:100px 0 0;
        width: 100%;
        h1{
            font-size:34px;
            color:$darkblue;
            line-height:50px;
            text-align:center;
            font-weight:700;
            font-style:normal;
        }
        p{
            font-size:20px;
            color:$darkblue;
            line-height:36px;
            text-align:center;
        }
        button, a{
            background:$lightorange;
            font-weight:bold;
            border-radius:30px;
            padding:10px 30px;
            color:$white;
            border:none;
            margin-top:30px;
            display:inline-block;
            &:hover{
               background:$gradient-bluesky;
               text-decoration:none;
            }
        }
    }
}


//dynamic_cms_mngt_section2 start

.CMSincludes{
    background-color: $bannerlightgray;
    padding:50px 0;
    .dynamic_cmsincludes {
        box-shadow: 1px 5px 36px rgba(0, 0, 0, 0.1);
        border-radius: 8px;
        padding:10px;
        background-color: $white;
        min-height:270px;
        margin-bottom:25px;
        img{
            width:80px;
        }
        h3{
            font-size: 20px;
            padding: 15px 0 0;
        }
    }
}

//Dynamic Interested section 3

.dynamiccms-Interested{
    padding:50px 0;
    background: url(Images/features-bg.jpg);
    background-size:cover;


    .dynamic-cms-box{
        background-color:$white;
        box-shadow: 0 5px 30px rgba(0, 0, 0, 0.1);
        padding: 20px 20px;
        text-align: left;
        margin: 10px 0 0 0;
        min-height:300px;

        a{
            img{
            margin: 0 auto;
            width:245px;
            height: 68px;
            display: block;
            }
           
            .interested-header{
                border:none;
                font-size: 20px;
                color:$lightblack;
                display: block;
                padding:20px 0px 0;
                &:hover{
                color: $lightblue;
                text-decoration: none;
                }
            }

            .interested-caption{
                border:none;
                font-size: 16px;
                color:$lightblack;
                display: block;
                padding:0px 0px 0;
                &:hover{
                text-decoration: none;
                }
            }
            &:hover {
                text-decoration:none;
                
            }  
        }
        
    }
}


//dynamiccms-Insight section 4

.dynamiccms-Insight{
    padding:50px 0px 80px 0px;;
    
  
    .card-title{
        height: 70px;
        font-size: 18px;
        color: $lightblack;
        &:hover{
            color: $lightblue;
           text-decoration: none;
        }
    }

    .carddiv{
        &:hover{
        box-shadow: 0 2px 3px rgba(0,0,0,0.1), 0 5px 10px rgba(0,0,0,0.25);
        transition: box-shadow 0.3s;
        }
    }
  
}

@media only screen and (min-width: 320px) and (max-width: 680px)  {
    .dynamicCMSManagement {
        padding:0;
    }
    //canvas
    .dynamic_cmscanvas {
        height:400px !important;
    }
}



@media only screen and (max-width : 767px) {
    .mainsection{
        padding: 25px 0px 55px 0px;
        
    }
}

//Tablet View
@media (min-width: 768px) and (max-width: 1024px) {
    .dynamicCMSManagement {
        .bannerdetails {
            padding: 80px 0 0 !important;
        }
    }
    .dynamiccms-Interested{
        .dynamic-cms-box{
            min-height: 440px;
        }
    }
    .CMSincludes{
        ul{
            min-height: 210px;
        }
    }
}