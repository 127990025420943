@import './VM-Section1';
@import './VM-Section2';

//Common Main Title Start
.VisionMissiontitle{
    padding:30px 0 10px;
    h2, h3, h4, h5{
        font-size:32px;
        color:$darkblue;
        text-align: left;
        font-weight: 700;
        font-style: normal;
    }
    p{
        font-size:16px;
        color:$github;
    }
}

//Hybrid Exchange Banner Start
.VisionMissionBanner{
    padding:100px 0;
    background:url(Images/features-bg.jpg);
    
    .jumbotron{
        background:transparent;
        padding:0;
        margin:0;
    }
    .bannerdetails{
        text-align: center;
        padding:50px 0 0;
        h1{
            font-size:34px;
            color:$darkblue;
            line-height:50px;
            text-align:center;
            font-weight:700;
            font-style:normal;
        }
        p{
            font-size:20px;
            color:$darkblue;
            line-height:36px;
            text-align:center;
        }
        button{
            background:$gradient-skyblue;
            border-radius:30px;
            padding:10px 30px;
            color:$white;
            border:none;
            margin-top:30px;
            &:hover{
               background:$gradient-bluesky;
            }
        }
    }
}

//Our Mission
.Mission-bg{
    background:$lightwhite;
    padding:50px 0;
    .Missiondes{
        h3, h2{
            font-size: 32px;
            color:$darkblue;
            text-align: left;
            font-weight: 700;
            font-style: normal;
        }
    }
}

@media only screen and (max-width : 767px) {
    .VisionMissionBanner{
        padding:0 0 30px;
    }
}