//Common Main Title Start
.Decentralizedtitle{
    padding:30px 0 10px;
    h2, h3, h4, h5{
        font-size:32px;
        color:$darkblue;
        text-align: left;
        font-weight: 700;
        font-style: normal;
    }
    p{
        font-size:16px;
        color:$github;
    }
}

//Crypto Exchange Banner Start
.DecentralizedExchangeSolution{
    padding:90px 0 0;
    //background:$bannerlightgray;
    position:relative; 
    background-image:url('Images/HeaderBanner.jpg');
    min-height:450px;
    canvas{
        height:350px !important;
    }
    .jumbotron{
        background:transparent;
        padding:0;
        margin:0;
    }
    .bannerdetails{
        //position: absolute;
        text-align: center;
        padding:80px 0 0;
        h1{
            font-size:34px;
            color:$darkblue;
            line-height:50px;
            text-align:center;
            font-weight:700;
            font-style:normal;
        }
        p{
            font-size:20px;
            color:$darkblue;
            line-height:36px;
            text-align:center;
        }
        button, a{
            background:$lightorange;
            font-weight:bold;
            border-radius:30px;
            padding:10px 30px;
            color:$white;
            border:none;
            margin-top:30px;
            display:inline-block;
            &:hover{
               background:$gradient-bluesky;
               text-decoration:none;
            }
        }
    }
}

//Smart Contract Based Exchange Development
.DecentralizedServiceBox{
    border:1px solid $bannerlightgray;
    box-shadow:1px 5px 36px rgba(0, 0, 0, 0.1);
    padding:20px 15px;
    border-radius:8px;
    width:100%;
    text-align:center;
    margin:15px 0;
    min-height:240px;
    img{
        width:110px;
    }
    p{
        min-height:100px;
    }
    svg{
        color:$lightblue;
        width:100%;
    }
    span, h3{
        font-size:22px;
        color:$darkblue;
        font-weight:700;
        width:100%;
        display: inline-block;
        padding:10px 0;
    }
}

//How Atomic Swap Power Exchange Work
.DecentralizedFeature-bg{
   background:$lightwhite;
   margin:30px 0 0;
   padding:30px 0;
}

//How Decentralized Exchange Helps Your Business
.DecentralizedHelpbox{
    padding:20px 16px;
    border-left:2px solid $lightblue;
    border-right:2px solid $lightsky;
    border-radius:5px;
    box-sizing:border-box;
    background-position:0 -17.5px, -1px 113.5%;
    background-repeat: no-repeat;
    -webkit-background-size: 100% 20px;
    background-image: linear-gradient(to right, #4470a6 0%, #28d1ca 100%), linear-gradient(to right, #4470a6 0%, #28d1ca 100%);
    margin: 0 0 28px;
    box-shadow: 1px 5px 36px rgba(0, 0, 0, 0.1);
    svg{
        margin: 6px 0 0 0;
    }
    span, h3{
        font-size:22px;
        color:$github;
    }
    p{
        font-size:14px;
        color:$github;
    }
}

//Decentralized Exchange Offering
.DecentralizedExchange-bg {
    padding-top:0px;
    padding-bottom:20px;
    background:url(Images/sectionbg_ed.jpg);
    background-size:cover;
    background-position:center;

    .decentralizedexchange{
        span, h3{
            text-align:left;
            color:$darkblue;
            display:inline-block;
            width:100%;
            font-size:24px;
        }
        ul{
            li{
                //padding-bottom:20px;
                font-size:20px;
                list-style: none;
                padding-left: 15px;
                font-weight:500;
                color:$github;
                &:after{
                    background: url(Images/itemright.png);
                    content: "";
                    position: absolute;
                    left:15px;
                    right: 0;
                    margin: -92px 0 0 0;
                    width: 30px;
                    height: 30px;
                    background-repeat: no-repeat;
                }
                p{
                    font-size:16px;
                    font-weight:normal;
                    min-height:50px;
                }
            }
        }
        a{
            background:$gradient-skyblue;
            color:$white;
            border-radius: 30px;
            padding: 10px 30px;
            border: none;
            margin-top: 0;
            display: inline-block;
            margin-bottom: 20px;
            &:hover{
                background:$gradient-bluesky;
                color:$white;
                text-decoration:none;
            }
        }
    }
}

//Decentralized crypto exchange
.Decentralizedbranded-bg{
    background:$gradient-skyblue;
    padding:30px 0;
    span{
        color:$white;
        font-size: 24px;
    }
    a{
        background-color:$white;
        color:$darkblue;
        cursor: pointer;
        box-shadow: 0 2px 1px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.3);
        padding: 10px 20px;
        font-size: 18px;
        border-radius: 3px;
        margin: 0px 0 0 0;
        display: inline-block;
        &:hover{
            text-decoration:none;
            color:$darkblue;
        }
    }
}

//Difference Between Centralized & Decentralized
.CentralizedDecentralized{
    box-shadow:1px 5px 36px rgba(0, 0, 0, 0.1);
    border:1px solid $bannerlightgray;
    padding:15px 25px;
    min-height:356px;
    text-align:center;
    margin: 0 0 40px;
    ul{
      padding: 0 15px;
       li{
        text-align:left;
       }
    }
    span, h3{
        font-size:24px;
        color:$darkblue;
        font-weight:700;
        text-align:left;
        display: inline-block;
        width: 100%;
        padding: 10px 0;
    }
}

//Our Decentralized Exchange Product
.DecentralizedExchangeProduct{
    background:url(Images/features-bg.jpg);
    background-size:cover;
    background-position:center;

    .Decentralizedproductbox{
        background:$white;
        padding:15px 20px;
        min-height:250px;
        margin:0 0 30px;
        a{
            img{
                width:70%;
                margin:0 15% 0;
            }
            text-decoration:none;
            color:$github;  
            span, h3{
                font-size:20px;
                color:$darkblue;
                margin:15px 0 5px;
                display:inline-block;
                font-weight:700;
            }
            p{
                font-size:15px;
                color:$github;  
            }
        }
        &:hover {
            transition: all 2s ease;
            transform: scale(1.0);
        }  
    }
}

@media only screen and (min-width: 320px) and (max-width: 680px)  {

    //canvas
    .decentralizedcanvas {
        height:410px !important;
    }

    //offering-img
    .decentralized_offerning{
        max-width: 100%;
    }

}
@media only screen and (max-width:767px) {
    .DecentralizedExchangeSolution{
        padding:0;
    }
    
}

//Tablet View
@media (min-width: 768px) and (max-width: 1024px) {
    .Decentralizedbranded-bg{
        a{
            font-size:16px;
        }
        span{
            font-size: 22px;
        }
    }
    .DecentralizedExchangeProduct{
        .Decentralizedproductbox{
            min-height: 320px;
        }
    }
}


// Lavrage Page

.lavragemarginex-section {
    padding: 50px 0px;
    .lavragemarginex-content {
        padding-top: 70px;
        h2 {
            font-size: 32px;
            color: #20126f;
            text-align: left;
            font-weight: 700;
            font-style: normal;
            padding-bottom: 15px;
        }
    }
}

.Leveragework-bg {
    background-image: url("Images/Leverage/leveragebanner.jpg");
    min-height: 100%;
    background-size: cover;
    position: relative;
    background-attachment: fixed;
    width: 100%;

    .futurelavrage-title {
        font-size: 49px;
        line-height: 76px;
        padding-top: 75px;
        font-weight: bold;
    }
    .extra-btn {
        background: linear-gradient(to right, #28d1ca, #4470a6);
        font-weight: bold;
        border-radius: 30px;
        padding: 10px 30px;
        color: #ffffff;
        border: none;
        margin-top: 30px;
        display: inline-block;
        &:hover {
            background: linear-gradient(to right, #28d1ca, #4470a6);
            text-decoration: none;
        }
    }
    .lavrage-works-first {
        margin-left: -10px;
        margin-right: 10px;
    }

    .lavrage-works-content {
        padding: 20px;
        background: radial-gradient(circle, rgba(34, 193, 195, 1) 0%, rgba(68, 112, 166, 1) 100%);
        h3 {
            font-size: 32px;
            color: #ffffff;
            text-align: left;
            font-weight: 700;
            font-style: normal;
            padding-bottom: 20px;
        }
        .future-box {
            margin-top: 26px;
            .future-box-heading {
                background: #ffffff;
                color: #20126f;
                font-weight: bold;
                padding-left: 10px;
                font-size: 17px;
                padding-top: 3px;
                padding-bottom: 3px;
            }
            .future-box-desc {
                padding-left: 10px;
            }
        }
    }
}

.lavrageadvantage-title {
    h2 {
        font-size: 32px;
        color: #20126f;
        text-align: left;
        font-weight: 700;
        font-style: normal;
        padding-bottom: 15px;
        padding-top: 50px;
    }
}

.lavrageadvantage-section {
    text-align: center;
    margin-top: 50px;
    .lavrageadv-stuff {
        border: 1px solid #52aeff;
        padding: 15px;
        background: #fff;
        box-shadow: 7px 5px 10px rgba(0, 0, 0, 0.1);
        min-height: 408px;
        text-align: center;
        h3 {
            font-size: 24px;
            color: #20126f;
            text-align: left;
            font-weight: 700;
            font-style: normal;
            padding-bottom: 20px;
            text-align: center;
            min-height: 75px;
        }
        img {
            width: 76px;
            padding-bottom: 50px;
        }
    }
}

.start-lavrage-section {
    margin-top: 80px;
    h2 {
        font-size: 32px;
        color: #20126f;
        text-align: left;
        font-weight: 700;
        font-style: normal;
    }
}

.Cutting-edge-section {
    .cuttingbox {
        list-style-type: none;
        display: inline-flex;
        background-color: #ffffff;
        padding: 20px;
        border-radius: 10px;
        box-shadow: 1px 5px 36px rgba(0, 0, 0, 0.1);
        margin: 30px 0 0 0;
        min-height: 225px;
        h4 {
            font-size: 19px;
            font-weight: bold;
            margin-bottom: 5px;
            display: inline-block;
            font-family: Gill Sans Extrabold, sans-serif;
            color: #1e2221;
            min-height: 35px;
        }
        p {
            margin-bottom: 0px;
        }
    }
}

.offtertrader-bg {
    padding-top: 50px;
    padding-bottom: 30px;
    h2 {
        font-size: 32px;
        color: #20126f;
        text-align: left;
        font-weight: 700;
        font-style: normal;
        text-align: left;
        padding-bottom: 15px;
        margin-bottom: 50px;
    }
    .offerborder {
        border: 1px solid #22d7cf !important;
        padding: 10px;
        margin-bottom: 20px;
        .offertrader-content {
            padding-top: 50px;
        }
    }
}

.lavrage-CryptoExchange{
    .offerborder{
        min-height: 248px;
        img{
            width: 60px;
            padding-bottom: 15px;
        }
    }
}




// OTC Desk
.Turnkeytitle {
    padding: 30px 15px;
    text-align: center;
    box-shadow: 7px 5px 10px rgba(0, 0, 0, 0.1);
    margin: 77px 0px 77px 0px;
    border: 1px solid gray;
    border-bottom: 4px solid #4470a6;
    img {
        width: 80px;
        padding-bottom: 50px;
    }
    h2 {
        font-size: 32px;
        color: #20126f;
        font-weight: 700;
        font-style: normal;
    }
    p {
        font-size: 18px;
    }
}

.otc-custom-platformbox {
    border: 1px dashed #52aeff;
    min-height: 168px;
    padding: 15px;
    margin-bottom: 30px;
    h2 {
        font-size: 22px;
        color: #20126f;
        text-align: left;
        font-weight: 500;
        font-style: normal;
        img{
            width: 50px;
            padding-right: 10px;
        }
    }
}
.otctrading-title {
    h2 {
        font-size: 32px;
        color: #20126f;
        text-align: left;
        font-weight: 700;
        font-style: normal;
        padding-bottom: 20px;
        padding-top: 50px;
    }
}
.otctrading {
    p {
        font-size: 18px;
        color: black;
        text-align: left;
        font-style: normal;
        padding-bottom: 21px;
        line-height:32px;
        margin-top: 20px;
    }
}
.otc-mobile-step {
    display: none !important;
}

.OTCDesk-process {
    margin-bottom: 50px;
    .step-one,
    .step-two,
    .step-four,
    .step-five {
        &::before {
            content: "";
            position: absolute;
            border-top: 7px dashed #4470a6;
            width: 59px;
            right: -29px;
            top: 146px;
        }
    }
    .step-three {
        &::before {
            content: "";
            position: absolute;
            border-left: 7px dashed #4470a6;
            width: 59px;
            right: 130px;
            height: 120px;
            bottom: -119px;
        }
    }

    .Otcstep {
        padding: 0px 30px;
        .OTCDesk-process-box {
            background: white;
            min-height: 352px;
            box-shadow: none;
            padding: 54px 20px;
            border: 1px solid gray;
            img {
                text-align: center;
                width: 54px;
                display: block;
                margin: 0 auto;
            }
            h3 {
                padding-bottom: 18px;
                padding-top: 15px;
                text-align: center;
            }
            p {
                text-align: center;
            }
        }
    }
}
.OTCDesk-process-second {
    margin-top: 120px;
}

@media only screen and (max-width: 767px) {
    .Turnkeytitle {
        margin: 31px 0px 35px 0px;
    }
    .otc-mobile-step {
        display: block !important;
    }
    .otc-desktop-step {
        display: none !important;
    }
    .OTCDesk-process-second {
        margin-top: 0px;
    }
    .OTCDesk-process {
        margin-bottom: 0px;
        .Otcstep {
            .OTCDesk-process-box {
                margin-bottom: 15px;
            }
        }
        .step-one,
        .step-two,
        .step-four,
        .step-five {
            &::before {
                display: none;
            }
        }
        .step-three {
            &::before {
                display: none;
            }
        }
    }
}

// Mobile Trading Terminal
.AttractiveBusiness-section {
    padding: 50px 0px;
    .AttractiveBusiness-content {
        padding-top: 110px;
        h2 {
            font-size: 32px;
            color: #20126f;
            text-align: left;
            font-weight: 700;
            font-style: normal;
            padding-bottom: 15px;
        }
    }
}

.mobile-advantage {
    padding: 50px 0px;
    h2 {
        font-size: 32px;
        color: #20126f;
        text-align: left;
        font-weight: 700;
        font-style: normal;
        padding-bottom: 70px;
    }
    .mobile-advantage-box {
        margin-bottom: 70px;
        .mobile-adv-line-right {
            border-right: 1px solid #4470a6;
            margin-left: -5px;
            padding: 26px 26px 26px 0px;
        }
        .mobile-adv-line-left {
            border-left: 1px solid #4470a6;
            padding-left: 5px;
            margin-left: 5px;
            padding: 26px 0px 26px 31px;
            
        }
        h3 {
            padding-bottom: 20px;
            padding-top: 20px;
        }
        .mobile-adv-image-4 {
            width: 373px;
            padding-top: 15px;
        }
        .mobile-adv-image-3 {
            width: 373px;
            padding-top: 15px;
        }
        .mobile-adv-image-2 {
            width: 320px;
            padding-top: 10px;
        }
        .mobile-adv-image-1 {
            width: 350px;
            padding-top: 8px;
        }
    }
}

@media only screen and (max-width: 767px) {
    .mobile-advantage {
        .mobile-advantage-box {
            .mobile-adv-line-right {
                border-right: 0px solid #4470a6;   
            }
            .mobile-adv-line-left {
                border-left: 0px solid #4470a6;   
            }
        }
    }
}

// Derivative Trading Platform
.derivative-parameter {
    padding: 70px 0px;
    p {
        padding-top: 45px;
        font-size: 20px;
        font-weight: bold;
    }
    li {
        font-size: 18px;
        padding-bottom: 05px;
    }
}
.Brokerage-section {
    background: #f8f8f8;
    padding: 70px 0px;
    h3 {
        font-size: 32px;
        color: #20126f;
        text-align: left;
        font-weight: 700;
        font-style: normal;
    }
    .brokeage-facility-box {
        background: #ececec;
        padding: 15px;
        border-radius: 10px;
        margin-bottom: 10px;
        ul {
            padding-left: 15px;
        }
    }
}
.institutional-traders {
    padding: 70px 0px;
    h2 {
        font-size: 43px;
        color: #20126f;
        text-align: left;
        font-weight: 700;
        font-style: normal;
        line-height: 64px;
        padding-top: 25px;
    }
    .institutional-box {
        font-size: 22px;
        line-height: 39px;
        border: 1px solid gray;
        padding: 20px 25px;
        border-bottom: 8px solid #4470a6;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        min-height: 374px;
        .Traders-img {
            height: 325px;
            width: 287px;
        }
    }
}

.DerivativeFeature-section {
    background: linear-gradient(to right, #4470a6, #28d1ca);
    padding: 70px 0 30px 0;
    h2 {
        color: white;
        padding-bottom: 30px;
    }
    .futures-ueser {
        background: white;
        padding: 15px 25px;
        border-radius: 10px;
        min-height: 263px;
        margin-bottom: 21px;
        .future-circle {
            text-align: center;
            border: 1px dashed #4470a6;
            width: 50px;
            height: 50px;
            border-radius: 50px;
            margin: 0 auto;
            span {
                padding: 8px 13px 6px 13px;
                color: white;
                background: #28d1ca;
                border-radius: 50px;
                width: 42px;
                height: 42px;
                margin: 3px 3px;
                display: block;
                font-weight: bold;
            }
        }
        .futures-text {
            padding-top: 10px;
            font-size: 18px;
            text-align: center;
        }
    }
    .Core-Features-p{
        position: relative;
        padding-left: 20px;
        font-size: 18px;
        &::before{
            content: "";
            position: absolute;
            width: 5px;
            height: 52px;
            background: white;
            left: 0;
        }
    }
}

.custmorchallanges-section {
    padding: 70px 0px;
    position: relative;
    .customer-row {
        margin-bottom: 100px;
    }
    .customer-box {
        background: #ececec;
        padding: 25px 30px;
        min-height: 465px;
        h2 {
            font-size: 32px;
            color: #20126f;
            text-align: left;
            font-weight: 700;
            font-style: normal;
            padding-bottom: 20px;
        }
        li {
            padding-bottom: 20px;
            font-size: 20px;
        }
    }
    .customr-img {
        img {
            margin-top: 50px;
            border: 1px solid gray;
            width: 450px;
            padding: 17px;
        }
    }
    .customr-img-2 {
        img {
            margin-top: 120px;
            border: 1px solid gray;
            width: 450px;
            padding: 17px;
        }
    }
}