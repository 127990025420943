.sitemap-bg{
    padding:100px 0;
    .sitemapbanner{
        h1{
            font-size: 24px;
            color:$github;
            // padding: 20px 0;
            &:hover{
                color:$lightblue;
            }
        }
        h2{
            a{
                font-size: 24px;
                color:$github;
                padding: 10px 0;
                display: inline-block;
                display: inline-block;
                border-bottom: 1px solid $lightsky;
                width: 100%;
                &:hover{
                    color:$lightblue;
                    text-decoration:none;
                }
            }
        }
    }
    .exchangestitle{
        font-weight: 700;
        padding: 0;
        list-style:none;
        
        > li{
            border-bottom: 1px solid $lightsky;
            border-top: 1px solid $lightsky;
            margin: 10px 0 0 0;
        }
        a{
            color:$lightsky;
            padding: 7px 10px;
            display: inline-block;
            &:hover{
                text-decoration:none;
            }
        }
        .submenulist{
            list-style:none;
            padding:0;
            > li{
                a{
                    color:$darkblue;
                    &::before{
                        content: "o";
                        font-size: 24px;
                        padding: 0 5px 0 25px;
                    }
                } 
            }

            ul{
                list-style:none;
                li{
                    a{
                        color:$darkblue;
                        font-weight:normal;
                        padding: 0;
                        &::before{
                            content: ">";
                            font-size:24px;
                            padding: 0 5px 0 25px;
                        }
                    } 
                }
            }
            
        }
    }
}

.sitemap-bg .featuremain {
    font-weight: 700;
    list-style: none;
    padding: 0;
    border: 1px solid $darkblue;
    
            > li {
                margin: 10px 0 0 0;
                    a {
                        color: $darkblue;
                        padding: 3px 10px;
                        display: inline-block;
                        &::before{
                            content: "o";
                            font-size: 24px;
                            padding: 0 5px 0 10px;
                        }
                    }
                }

        .featuremenulist{
            list-style:none;
            padding:0;
            > li{
                a{
                    color: $darkblue;
                    font-weight: normal;
                    padding:0 0 0 15px;
                    &:hover{
                        text-decoration:none;
                    }
                    &::before{
                        content: ">";
                        font-size: 24px;
                        padding: 0 5px 0 25px;
                    }
                } 
            }
        }
    }

