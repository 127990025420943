.awardcertificate_bg{
    padding:0 0 50px;
    h2, h3{
        font-size:32px;
        color:$darkblue;
        text-align: left;
        font-weight: 700;
        font-style: normal;
    }
    p{
        font-size:16px;
        color:$github;
    }
    
    .awardbox{
        display:inline-block;
        text-align:center;
        img{
            width:300px;
            margin-top:15px;
        }
        span{
            font-size: 18px;
            text-align: center;
            margin: 15px 0 0;
            display: inline-block;
            font-weight: bold;
            color:$heading;
        }
    }
}