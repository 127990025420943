//Common Main Title Start
.SecurityTokentitle{
    padding:30px 0 10px;
    h2, h3, h4, h5{
        font-size:32px;
        color:$darkblue;
        text-align: left;
        font-weight: 700;
        font-style: normal;
    }
    p{
        font-size:16px;
        color:$github;
    }
}

//Security Token Exchange Banner Start
.SecurityTokenBanner{
    padding:90px 0 0;
    //background:$bannerlightgray;
    background-image:url('Images/HeaderBanner.jpg');
    position: relative;
    min-height:450px;
    canvas{
        height:350px !important;
    }
    .jumbotron{
        background:transparent;
        padding:0;
        margin:0;
    }
    .bannerdetails{
        //position: absolute;
        text-align: center;
        padding:80px 0 0;
        h1{
            font-size:34px;
            color:$darkblue;
            line-height:50px;
            text-align:center;
            font-weight:700;
            font-style:normal;
        }
        p{
            font-size:20px;
            color:$darkblue;
            line-height:36px;
            text-align:center;
        }
        button, a{
            background:$lightorange;
            font-weight:bold;
            border-radius:30px;
            padding:10px 30px;
            color:$white;
            border:none;
            margin-top:30px;
            display:inline-block;
            &:hover{
               background:$gradient-bluesky;
               text-decoration:none;
            }
        }
    }
}

//Security Token Exchange Development Features
.SecurityTokenFeatures{
    background:url(Images/features-bg.jpg);
    background-size:cover;
    background-position:center;
    padding:0 0 30px;

    .SecurityTokenFeaturesbox{
        background:$white;
        padding:20px 30px;
        text-align:center;
        margin:10px 0 20px;
        span, h3{
            color:$darkblue;
            font-size:22px;
            display:inline-block;
            font-weight:bold;
            padding: 10px 0;
            width:100%;
        }
    }
}

.IdealSecurityToken{
    text-align:center;
    img{
        width: 80px;
        margin: 20px 0 0;
    }
    span{
        font-size: 18px;
        color:$github;
        width: 100%;
        margin:20px 0 20px;
        display: inline-block;
    }
}

//Security Token Exchange
.SecurityTokenbranded-bg{
    background:$gradient-skyblue;
    padding:30px 0;
    span{
        color:$white;
        font-size:24px;
    }
    a{
        background-color:$white;
        color:$darkblue;
        cursor: pointer;
        box-shadow: 0 2px 1px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.3);
        padding: 10px 20px;
        font-size: 18px;
        border-radius: 3px;
        margin: 0px 0 0 0;
        display: inline-block;
        &:hover{
            text-decoration:none;
            color:$darkblue;
        }
    }
}

//Security Token Exchange Benefits
.SecurityBenefitsbox{
    min-height: 200px;

    span, h3{
        text-align:left;
        color:$darkblue;
        display:inline-block;
        width:100%;
        font-size:20px;
        font-weight: 600;
    }
    p{
        text-align:left;
        font-size:14px;
    }
}

//Our Other Security Token Exchange Offering
.securityexchangeproduct-bg{
    background-color:$lightwhite;

    .securityproductbox{
        background:$white;
        padding:15px 20px;
        min-height:300px;
        margin:0 0 30px;
        a{
            img{
                width:70%;
                margin:0 15% 0;
            }
            text-decoration:none;
            color:$github;  
            span, h3{
                font-size:20px;
                color:$darkblue;
                margin:15px 0 5px;
                display:inline-block;
                font-weight:700;
            }
            p{
                font-size:15px;
                color:$github;  
            }
        }
        &:hover {
            transition: all 2s ease;
            transform: scale(1.0);
            
        }  
    }
}

@media only screen and (min-width: 320px) and (max-width: 680px)  {
    
    //canvas
    .securitycanvas{
        height: 520px !important;
    }
}

//Tablet View
@media (min-width: 768px) and (max-width: 1024px) {
    .securityexchangeproduct-bg{
        .securityproductbox{
            min-height: 410px;
        }
    }
}