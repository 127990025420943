       
.cryderivativestitle{
    padding:30px 0 20px;
    text-align:center;
    h2{
        font-size:30px;
        color:$darkblue;
        font-weight:700;
        font-style:normal;
    }
    p{
        font-size:16px;
        color:$github;
        padding:0 40px 10px;
        display:inline-block;
        text-align:center;
        margin:0;
    }
}
.cryderivativesbanner{
    padding:100px 0 50px;
    background:$bannerlightgray;
    background-image:url(Images/HeaderBanner.jpg);
    background-size: cover;
    background-position:center;
    overflow: hidden;
    .bannerdetails{
        text-align: center;
        padding:50px 0 0;
        h1{
            font-size:34px;
            color:$darkblue;
            line-height:50px;
            text-align:center;
            font-weight:700;
            font-style:normal;
        }
        button, a{
            background:$lightorange;
            font-weight:bold;
            border-radius:30px;
            padding:10px 30px;
            color:$white;
            border:none;
            margin-top:30px;
            display: inline-block;
            &:hover{
               background:$gradient-bluesky;
               text-decoration:none;
            }
        }
    }
}

.crytokensbg{
    background-image:url(Images/Derivatives/Tokensbg.jpg);
    background-position: center;
    background-size:cover;
    ul{
        list-style:none;
        padding:50px 0;
        margin:0;
        li{ 
            color:$white;
            display: inline-block;
            text-align: center;
            width:20%;
            img{
                width: 60px;
                margin: 0 0 20px;
            }
            span{
                width:100%;
                font-size:18px;
                display: inline-block;
            }
            p{
                width:100%;
                font-size:24px;
            }
        }
    }
}

.hightplatdetails{
    margin:20px 0;
    span{
        font-size:24px;
        text-align:left;
        color:$darkblue;
        font-weight:500;
        font-style:normal;
    }
    p{
        font-size:16px;
        text-align:left;
    }
}

.derivativesfeatures{
    text-align:center;
    .subdescription{
        text-align:center;
        width:60%;
    }
    span{
        font-size:20px;
        color:$darkblue;
        font-weight:500;
        font-style:normal;
        width:100%;
        display:inline-block;
    }
    p{
        font-size:16px;
    }
}

.derivetivehelpbox{
    margin:20px 0;
    display:inline-block;
    width:100%;
    i{
        background:$darkblue;
        color:$white;
        border-radius: 50%;
        width:35px;
        display: inline-block;
        height:35px;
        text-align: center;
        padding:5px;
        font-weight: bold;
        font-size:16px;
    }
    span{
        font-size:20px;
        color:$darkblue;
        font-weight:500;
        font-style:normal;
        display:inline-block;
        padding: 10px 15px;
    }
    p{
        font-size:16px;
    }
}
.derivativesroadbg{
    background:$lightgray;
    padding:20px 0;
    margin:50px 0 30px;
}

.focussedbox{
    box-shadow:24px 0px 30px 0px rgba(113, 113, 113, 0.24);
    margin:0 0 30px;
    padding:15px;
    h3{
        font-size:24px;
        padding:20px 0 0 0;
    }
    p{
        font-size:16px;
    }
    a{
        color:$linkedin;
        font-weight: 600;
        &:hover{
            text-decoration:none;
        }
    }
}
.investorsbg{
    background:$lightgray;
}
.investorsbox{
    width:100%;
    padding:30px 0;
    img{
        width:100px;
        display:inline-block;
        float:left;
        margin:0 30px 0 0;
    }
    span{
        color:$comheading;
        font-size:30px;
        font-weight:700;
    }
    p{
        font-size:16px;
    }
}
.risktradingbox{
    img{
        width:80px;
    }
    span, h3{
        color:$comheading;
        font-size:20px;
        font-weight:600;
        padding:0 0 0 15px;
        display:inline-block;
    }
    p{
        font-size:16px;
        min-height:120px;
    }
}
.buildtradingbox{
    background:$white;
    box-shadow:24px 0px 30px 0px rgba(113, 113, 113, 0.24);
    text-align:center;
    padding:10px 15px;
    margin:60px 0;
    img{
        position:absolute;
        top:0px;
        left:30px;
        right:0;
        margin:0 auto;
        width:100px;
    }
    span, h3{
        color:$comheading;
        font-size:20px;
        font-weight:600;
        padding:10px 0 10px 15px;
        width:100%;
        display:inline-block;
        margin:40px 0 0 0;
    }
    p{
        font-size:16px;
        text-align: justify;
    }
}

.faqpanel{
    margin:0 0 15px;
    [role="button"]{
        background:$lightborder;
        > div{
            p{
                color:$comheading;
                font-weight:700;
                font-size:16px;
            }
        }
    }
}
.faqdetials{
    a{
        font-size:16px;
        background:$lightorange;
        color:$white;
        padding:8px 25px 10px;
        font-weight:500;
        border-radius:20px;
        margin-top:2px;
        display:inline-block;
        &:hover{
            text-decoration:none;
        }
    }
}

.technologybox{
    text-align:center;
    img{
        margin-top:20px;
    }
    span, h3{
        font-weight:500;
        font-size:18px;
        color:$comheading;
        display: inline-block;
        width: 100%;
        margin-bottom:20px;
        padding:10px 0 0 0;
    }
}

.derivativecta-bg{
    background:$gradient-skyblue;
    padding:30px 0;
    span{
        color:$white;
        font-size:24px;
        padding:0 0 10px;
        display:inline-block;
    }
    p{
        color:$white;
        text-align:justify;
    }
    a{
        background-color:$white;
        color:$darkblue;
        cursor: pointer;
        box-shadow: 0 2px 1px rgba(0,0,0,0.1), 0 1px 3px rgba(0,0,0,0.3);
        padding: 10px 20px;
        font-size: 18px;
        border-radius: 3px;
        margin:40px 0 0 0;
        display: inline-block;
        &:hover{
            color:$darkblue;
            box-shadow: 0 4px 2px rgba(0,0,0,0.1), 0 1px 3px rgba(0,0,0,0.3);
            text-decoration:none;
        }
    }
}

.contactbtn {
    input {
      cursor: pointer;
      border: 2px solid $rss !important;
      color: $rss;
      height: 50px;
      display: inline-block;
      padding: 10px 30px;
      border-radius: 50px;
      font-size: 18px;
      font-weight: bold;
      &:hover {
        text-decoration: none;
        background-color: $rss;
        color: $white;
        border: 2px solid $rss !important;
      }
    }
  }
.contacttitle{
    margin:0 0 30px 0px;
    h2{
      font-size:22px;
    }
    &::after{
      content:"";
      position:absolute;
      width:10%;
      height:3px;
      background:$linkedin;
    }
  }
  .contactbg{
    background:$lightgray;
    padding:20px 0;
    margin:50px 0 0px;
    input[type=tel]{
        width:100% !important;
    }
    .errorMsg.react-phone-number-input__error {
        font-size: 12px;
        color: red;
        margin-left:0 !important;
      }
      .react-phone-number-input__country{
        margin: 0 0 4px 0 !important;
      }
      .react-phone-number-input__input{
        border-bottom:none !important;
      }
      .react-phone-number-input__icon-image{
        margin: -10px 0 0 0;
      }
  }
.contactlinkbox{
    width:100%;
    margin:0 auto;
    text-align:center;
    a{
      font-size:14px;
      padding:10px 15px;
      margin-top:0;
      display:inline-block;
      border-radius: 0;
      color:$white;
      width:31%;
      margin-left:5px;
      border-radius:35px;
      cursor: pointer;
      img{
        width:25px;
      }
      &:first-child{
        background:#00ADF2;
      }
      &:nth-child(2){
        background:#4BCA58;
      }
      &:nth-child(3){
        background:#2DAAE2;
      }
      &:hover{
        text-decoration:none;
      }
    }
    span{
      margin:0 10px;
    }
  }