//Common Main Title Start
.stoprotitle,
.legalheadertitle {
    padding: 30px 0 10px;
    h2 {
        font-size: 28px;
        color: $darkblue;
        text-align: left;
        font-weight: 700;
        font-style: normal;
    }
    p {
        font-size: 16px;
        color: $github;
    }
}
.stoproabouttitle {
    h3 {
        font-size: 22px;
        color: $darkblue;
        text-align: left;
        font-weight: 500;
        font-style: normal;
    }
    p {
        font-size: 16px;
        color: $github;
        text-align: justify;
    }
}

//Cryptocurrency Exchange Software Banner

.programming-bg,
.legalheader-bg {
    // padding:100px 0;
    // background:$bannerlightgray;
    // background-image:url(Images/features-bg.jpg);
    // background-size: cover;
    // background-position:center;
    padding: 100px 0 0;
    background-image: url("Images/HeaderBanner.jpg");
    position: relative;
    min-height: 450px;
    .jumbotron {
        background: transparent;
        padding: 0;
        margin: 0;
    }
    .bannerdetails {
        text-align: center;
        padding: 90px 0 0;
        h1 {
            font-size: 34px;
            color: $darkblue;
            line-height: 50px;
            text-align: center;
            font-weight: 700;
            font-style: normal;
        }
        p {
            font-size: 20px;
            color: $darkblue;
            line-height: 36px;
            text-align: center;
        }
        .bannersubline-one {
            margin-bottom: 0px;
        }
        button,
        a {
            background: $lightorange;
            font-weight: bold;
            border-radius: 30px;
            padding: 10px 30px;
            color: $white;
            border: none;
            margin-top: 30px;
            display: inline-block;
            &:hover {
                background: $gradient-bluesky;
                text-decoration: none;
            }
        }
    }
}

//STO Dashboard & Platform
.Platformbox {
    border: 1px dashed $twitter;
    min-height: 200px;
    padding: 15px;
    margin-bottom: 30px;
    h2 {
        font-size: 22px;
        color: $darkblue;
        text-align: left;
        font-weight: 500;
        font-style: normal;
    }
}

//SmartContact
.SmartContact-bg {
    background: $bannerlightgray;
    margin: 50px 0 0;
    padding: 0 0 50px;
    .smartcontractbox {
        border: 1px solid $twitter;
        padding: 15px;
        background: #fff;
        box-shadow: 7px 5px 10px rgba(0, 0, 0, 0.1);
        min-height: 342px;
        text-align: center;

        h3 {
            font-size: 24px;
            color: $darkblue;
            text-align: center;
            font-weight: 500;
            font-style: normal;
            margin-top: 20px;
        }
        p {
            font-size: 16px;
            color: $github;
            text-align: justify;
        }

        &:hover {
            transform: scale(1.02);
            transition: all 300ms;
        }
    }
}

.stowebpagebox {
    h3 {
        font-size: 24px;
        color: $darkblue;
        text-align: left;
        font-weight: 500;
        font-style: normal;
    }
}

//Our Service
.stoservicebox,
.legalservicebox {
    padding: 15px;
    background: #fff;
    box-shadow: 7px 5px 10px rgba(0, 0, 0, 0.1);
    margin: 15px 0;
    padding: 15px 20px;

    h3 {
        font-size: 24px;
        color: $darkblue;
        text-align: left;
        font-weight: 500;
        font-style: normal;
    }
}
.legalservicebox {
    background: $mision_bg;
    min-height: 352px;
    img {
        text-align: center;
        display: inherit;
        margin: 10px auto;
    }
    h3 {
        text-align: center;
    }
}

.stoAdvantagesbox {
    padding: 15px;
    // background: #f2f2f2;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
    margin: 15px 0;
    min-height: 330px;
    img {
        display: inherit;
        text-align: center;
        margin: 0 auto 20px;
    }
    h3 {
        font-size: 24px;
        color: $darkblue;
        text-align: center;
        font-weight: 500;
        font-style: normal;
    }
}
.PublicRelation-bg {
    background: #f2f2f2;
    padding: 50px 0;
}

//Marketing Page Start
.marketingservicebox {
    padding: 15px;
    background: #f2f2f2;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
    margin: 15px 0;
    padding: 15px 20px;
    min-height: 415px;
    img {
        text-align: center;
        margin: 0 auto;
        display: inherit;
        padding: 0 0 10px;
    }
    h3 {
        font-size: 24px;
        color: $darkblue;
        text-align: center;
        font-weight: 500;
        font-style: normal;
    }
}
.marketingmaintitle {
    h2 {
        font-size: 32px;
        color: $darkblue;
        text-align: center;
        font-weight: 700;
        font-style: normal;
    }
    h3 {
        font-size: 28px;
        color: $darkblue;
        text-align: left;
        font-weight: 700;
        font-style: normal;
    }
}
.marketingbox {
    h3 {
        font-size: 24px;
        color: $darkblue;
        text-align: left;
        font-weight: 500;
        font-style: normal;
    }
}

.MediaMarketing-bg {
    background-image: url(Images/STO/MediaMarketingBg.jpg);
    //background:$bannerlightgray;
    padding: 50px 0;
    height: 500px;
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    .container {
        background: rgba(255, 255, 255, 0.8);
        padding: 20px;
    }
}

.MarketingComman {
    margin: 60px 0 0 0;
}

.commmarketing {
    .row {
        margin: 50px 0;
    }
}

// Mobile View
@media only screen and (max-width: 768px) {
    .programming-bg {
        padding: 30px 0 0;
        min-height: 290px;
    }
    .SmartContact-bg {
        margin: 0 0 30px;
        padding: 30px 0 30px;
        .smartcontractbox {
            margin: 15px 0;
        }
    }
    .stoservicebox {
        h3 {
            font-size: 20px;
        }
    }
    .Platformbox {
        min-height: auto;
    }

    //STO marketing Start
    .MediaMarketing-bg {
        height: auto;
    }

    //STO Legal Start
    .legalheader-bg {
        padding: 30px 0;
        min-height: 240px;
    }
    .legalheadertitle {
        padding: 0;
    }
}

//iPad Mini
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
    .programming-bg {
        padding: 100px 0 0;
        min-height: 350px;
    }
    .stoAdvantagesbox {
        min-height: 455px;
    }
    .smartcontractbox {
        min-height: 425px !important;
        p {
            font-size: 14px !important;
        }
    }
    .Platformbox {
        min-height: auto;
    }

    //STO Legal Start
    .legalheader-bg {
        .jumbotron {
            .bannerdetails {
                padding: 100px 0 0 !important;
            }
        }
    }
    .legalservicebox {
        min-height: 450px;
    }
}

// IEO Page
.IEO-campaigns{
    img{
        width: 96px;
    }
}
.Ieobackground-bg{
    .programming-bg{ 
        .bannerdetails{
            padding: 90px 0px 34px;
    }
}
}
.ieoconsultation-image{
    padding-top:80px;
}
.IEO-campaigns {
    margin-top: 30px;
}
.ieoconsultingservice-box {
    img{
        width: 96px;
    }
    .marketingservicebox {
        background: white;
        min-height: 451px;
        box-shadow: none;
        padding: 54px 20px;
        h3 {
            padding-bottom: 18px;
            padding-top: 15px;
        }
        p {
            text-align: center;
        }
        &:hover {
            box-shadow: 0 1.5rem 4rem rgba(0, 0, 0, 0.15);
            transform: translate(0, -10px);
            transition: all 0.3s ease-out;
            &::before {
                border-top: 4px solid #4470a6;
                border-radius: 5px;
                position: absolute;
                content: "";
                left: 0;
                right: 0;
                top: 0;
                width: 30%;
                margin: 0 auto;
            }
        }
    }
}
