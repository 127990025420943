.ieovalbanner{
    padding:100px 0;
    background:url(Images/features-bg.jpg);
    background-size: cover;
    .bannerdetails{
        font-size:30px;
        color:$darkblue;
        line-height:50px;
        text-align:center;
        font-weight:700;
        text-align: center;
        padding:50px 0 0;
    }
}