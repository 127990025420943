//Common Main Title Start
.CryptoSoluctiontitle{
    padding:30px 0 10px;
    h2, h3, h4, h5 , h6{
        font-size:32px;
        color:$darkblue;
        text-align: left;
        font-weight: 700;
        font-style: normal;
    }
    p{
        font-size:16px;
        color:$github;
    }
}

//Crypto Exchange Banner Start
.CryptoExchangeSolution{
    padding:90px 0 0;
    background:$bannerlightgray;
    position: relative;
    canvas{
        height:350px !important;
    }
}    
.CryptoBanner{
    position: absolute;
    .jumbotron{
        background:transparent;
        padding:0;
        margin:0;
        .bannerdetails{
            text-align: center;
            padding:50px 0 0;
            h1{
                font-size:34px;
                color:$darkblue;
                line-height:50px;
                text-align:center;
                font-weight:700;
                font-style:normal;
            }
            p{
                font-size:20px;
                color:$darkblue;
                line-height:36px;
                text-align:center;
            }
            button, a{
                background:$gradient-skyblue;
                border-radius:30px;
                padding:10px 30px;
                color:$white;
                border:none;
                margin-top:30px;
                display:inline-block;
                &:hover{
                    background:$gradient-bluesky;
                    text-decoration:none;
                }
            }
        }
    }
}


.OneStopCryptobox{
    background-color:$white;
    box-shadow: 0 5px 30px rgba(0, 0, 0, 0.1);
    padding:30px;
    margin: 0 0 30px;
    text-align: left;
    border-radius:8px;
    min-height: 285px;
    &:hover{
        background:linear-gradient(to left, #28d1ca, #4470a6 50%);
        color:$white;
        p{
            color:$white;
        }
        svg{
            color:$white;
        }
    }
    h3{
       font-size:22px;
       padding:15px 0 0;
    }
    svg{
        color:$lightsky;
        //color:$lightblue;
    }
    p{
        font-size:15px;
        color:$github;
    }
    a{
        box-shadow: 0 2px 1px rgba(0,0,0,0.1), 0 1px 3px rgba(0,0,0,0.3);
        background: $gradient-skyblue;
        border-radius: 30px;
        padding:10px 30px;
        color:$white;
        vertical-align: middle;
        display: inline-block;
        margin-top:10px;
        &:hover{
            background:$gradient-bluesky;
            text-decoration:none; 
            color:$white;
        }
    }
}

//Cryptocurrency Exchange Platform Features
.FeaturesPlatform{
    padding:20px 0;
    background:$bannerlightgray;

    .PlatformFeaturesBox{
        background:$white;
        padding:20px;
        border-radius:8px;
        border:1px solid $white;
        min-height:210px;
        margin:0 0 30px;
        text-align:left;
        box-shadow:0px 1px 5px #B1B1B1;
        span, h3{
            font-size:20px;
            padding:0 0 10px;
            display:inline-block;
        }
        p{
            font-size:15px;
            color:$github;  
        }
    }
   
}

//Complete Exchange Software Solution
.CompleteExchangeSoftware{
    padding:0 0 30px;
    .mt-30{
        margin-top:30px;
    }
    .mt-20{
        margin-top:20px;
    }
    .CompleteExchangeleft{
        span, h3{
            font-size:20px;
            padding:0 0 10px;
            display:inline-block;
            color:$darkblue;
            font-weight:700;
        }
        p{
            font-size:15px;
            color:$github;  
        }
    }
}

//Ideal Centralized Exchange Solution
.IdealCentralizedExchange{
    padding-top: 30px;
    padding-bottom: 30px;
    background-image: url(Images/sectionbg_ed.jpg);
    .IdealCentralizedTitle{
        h2{
            font-size: 30px;
            color:$darkblue;
            text-align: left;
            font-weight: 700;
            font-style: normal;
            margin: 0 0 20px;
        }
        
    }
    .IdealService{
        font-size:20px;
        color:$github;
        text-align:center;
        h3{
            font-size:18px;
            color:$github;
            text-align:center;
        }
    }
}

.ExchangeWorkDes{
    margin:20px 0;
    p{
        font-size:15px;
        color:$github;  
    }
}

//Our Exchange Product
.OurExchangeservices{
    background-color:$white;
    .cryptoproductbox{
        background:$lightwhite;
        padding:15px 20px;
        min-height:250px;
        margin:0 0 30px;
        a{
            img{
                width:70%;
                margin:0 15% 0;
            }
            text-decoration:none;
            color:$github;  
            span,h3{
                font-size:20px;
                color:$darkblue;
                margin:15px 0 5px;
                display:inline-block;
                font-weight:700;
            }
            p{
                font-size:15px;
                color:$github;  
            }
        }
        &:hover {
            transition: all 2s ease;
            transform: scale(1.0);
            
        }  
    }
}
.OurExchangeProduct{
    background-color:$lightwhite;
    .cryptoproductbox{
        background:$white;
        padding:15px 20px;
        min-height:250px;
        margin:0 0 30px;
        a{
            img{
                width:70%;
                margin:0 15% 0;
            }
            text-decoration:none;
            color:$github;  
            span,h3{
                font-size:20px;
                color:$darkblue;
                margin:15px 0 5px;
                display:inline-block;
                font-weight:700;
            }
            p{
                font-size:15px;
                color:$github;  
            }
        }
        &:hover {
            transition: all 2s ease;
            transform: scale(1.0);
            
        }  
    }
}


@media only screen and (max-width:767px) {
    .CryptoExchangeSolution{
        padding: 0;
    }
    .Cryptocanvas {
        height:520px !important;
    }

}

//Tablet View
@media (min-width: 768px) and (max-width: 1024px) {
    .bannerdetails{
        padding: 20px 0 0 !important;
    }
    .FeaturesPlatform{
        .PlatformFeaturesBox{
            min-height:330px;
        }
    }
    .IdealCentralizedExchange {
        .IdealService{
            font-size:14px;
            img{
                width:80%;
            }
        }
    }
    .OurExchangeProduct{
        .cryptoproductbox{
            min-height:315px;
        }
    }
}

