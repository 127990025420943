// Home Banner 
.banner_bg{
    //background:$bannerlightgray;
    background-image:url('Images/Redefining-Trading-Technologies.png');
    padding-top: 90px;
    position:relative;
    background-position:center;
    background-size: cover;
    canvas{
        height:450px !important;
    }
}

.HomeBanner{
    //position: absolute;
    .jumbotron {
        text-align: left;
        padding:80px 0 0;
        background:transparent;
        .bannerdetails{
            h1{
                color:$darkblue;
                text-align: left;
                font-weight: 700;
                font-style: normal;
                font-size: 36px;
            }
            p{
                color:$darkblue;
                text-align: left;
                font-size: 26px;
                line-height: 36px;
            }
            button, a{
                background:$lightorange;
                box-shadow: 0 2px 1px rgba(0,0,0,0.1), 0 1px 3px rgba(0,0,0,0.3);
                border-radius: 30px;
                font-weight:bold;
                padding: 12px 30px;
                color:$white;
                border: none;
                display:inline-block;
                margin:30px 0 0;

                &:hover{
                    background:$gradient-bluesky;
                    text-decoration:none;
                }
            }
        }
    }
    .banner_img {
        max-width: 100%;
        margin-bottom: 32px;
    }    
}

// Overview Section Tow Start
.OverviewHome{
    padding:50px 0;
    h2{
        color:$darkblue;
        text-align: left;
        font-weight: 700;
        font-style: normal;
    }
    p{
        font-size:16px;
    }
    a, a:hover{
        color:$lightsky;
        text-decoration:none;
    }
}

// Comprise Section Tow Start
.Comprisefeature-bg{
    padding:50px 0;
    background: url(Images/features-bg.jpg);
    background-size:cover;

    .Compriseof{
        h2{
            font-size: 32px;
            color: $darkblue;
            text-align:left;
            font-weight:700;
            font-style: normal;
        }
        p{
            font-size:16px;
        }
    }

    .Comprisebox{
        background-color:$white;
        box-shadow: 0 5px 30px rgba(0, 0, 0, 0.1);
        padding: 20px 20px;
        text-align: left;
        border-radius: 8px;
        margin: 10px 0 0 0;
        svg{
            color:$lightsky;
        }
        i{
            background:$lightsky;
        }
        h3{
            padding-top:10px;
            font-size:22px;
            color: $lightblack;
            margin-top:10px;
            margin-bottom:15px;
        }
        p{
            min-height:120px;
        }
        a{
            background:$gradient-skyblue;
            border-radius:30px;
            padding:6px 30px;
            color:$white;
            border:none;
            margin: 10px 0 0;
            display: inline-block;

            &:hover{
                background:$gradient-bluesky;
                text-decoration:none;
            }
        }
    }
}

//Unique Features Start 
.UniqueFeaturesInfo{
    padding:50px 0;
    h2, h3, h4{
        font-size: 32px;
        color: $darkblue;
        text-align:left;
        font-weight:700;
        font-style: normal;
    }
    p{
        font-size:16px;
    }
}
.UniqueFeaturesBox{
    text-align: center;
    span, h3{
        font-size:22px;
        color: $darkblue;
        width: 100%;
        display: inline-block;
        text-align: center;
    }
    p{
        font-size: 14px;
    }
}

//Additional Exchange Features Start 
.AdditionalExchangeFeatures{
    padding:50px 0;
    background: url(Images/features-bg.jpg);
    background-size:cover;

    .ExchangeFeaturestitle{
        h4, h2{
            font-size: 32px;
            color: $darkblue;
            text-align:left;
            font-weight:700;
            font-style: normal;
        }
        p{
            font-size:16px;
            margin-bottom:20px;
        }
    }
    .ExchangeFeaturesBg{
        background:$white;
        padding:20px 40px;
        .AdditionalBox{
            min-height:130px;
            span, h3{
                font-size:22px;
            }
            p{
                font-size:16px;
            }
        }
        button, a{
            background:$gradient-skyblue;
            box-shadow: 0 2px 1px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.3);
            border-radius: 30px;
            padding: 12px 30px;
            color:$white;
            border: none;
            margin:0 auto 10px;

            &:hover{
                background:$gradient-bluesky;
                text-decoration:none;
            }
        }
    }
}


//Why Choose Espay For Exchange Development
.WhyChooseExchange{
    padding:50px 0;

    .WhyChooseTitle{
        h4,h2{
            font-size: 32px;
            color: $darkblue;
            text-align:left;
            font-weight:700;
            font-style: normal;
        }
        p{
            font-size:16px;
            margin-bottom:20px;
        }
    }
    .WhyChoosebox{
        background-color:$white;
        text-align:center;
        padding:25px 30px;
        color:$darkblue;
        box-shadow:15px 10px 30px rgba(0, 0, 0, 0.2);
        transition:all 0.3s ease-in-out 0s;
        &:hover{
            transform: scale(1.1);
        }
        img{
            width:80px;
        }
        p{
            padding-top: 10px;
            font-size: 20px;
            color:$darkblue;
        }
    }
}

//Get Started Today
.GetStartedToday{
    background:$bannerlightgray;
    padding:50px 0;
    .GetStartedTitle{
        h5, h2{
            font-size: 32px;
            color:$darkblue;
            text-align: left;
            font-weight: 700;
            font-style: normal;
        }
        p{
            font-size:16px;
            color:$github;
        }
    }
    .GetStartedBox{
        text-align: center;
        .GetStartedNumber{
            text-align: center;
            font-size:70px;
            width:110px;
            margin: 15px auto;
            color:$white;
            border-left: 2px solid #3EC8AC;
            border-right: 2px solid #4E90A4;
            border-radius: 5px;
            box-sizing: border-box;
            background-position: 0 -17px, 0 121%;
            background-repeat: none;
            background-image: linear-gradient(to right, #4470a6 0%, #28d1ca 100%), linear-gradient(to right, #4470a6 0%, #28d1ca 100%);
        }
        span, h2, h3{
            font-size: 18px;
            font-weight: 600;
            color:$darkblue;
        }
        p{
            line-height: 20px;
            padding-top: 10px;
            color:$github;
        }
    }
    .Contactbtn{
        margin:20px auto 0;
        text-align:center;
        a{
            background:$gradient-skyblue;
            border-radius:30px;
            padding:10px 30px;
            color:$white;
            display: inline-block;
            vertical-align: middle; 
            &:hover{
                background:$gradient-bluesky;
                text-decoration:none;
            }
        }
    }
}


//Blog Section
.blogbg{
    background: #E2ECF8;
    padding:50px 0;
    .blogbgtitle{
        h5{
            font-size: 32px;
            color:$darkblue;
            text-align: left;
            font-weight: 700;
            font-style: normal;
            padding-bottom: 25px;
        }
    }
}

.widget{
    h5{
        font-size: 25px;
        color: $darkblue;
        text-align: center;
        font-weight: 400;
        padding-bottom: 10px;
    }
    .espayblog{       
        height: 450px;
        width: 350px;
        border: none;
        header, footer{
            display:none !important;
        }
    }
    iframe{
        border:none;
    }
}

.HomeBlogmain{
    height: 450px;
    overflow-y: scroll;
    overflow-x: hidden;
    background:$white;

    .carddiv{
        margin:0 10px 25px;
        .card-title{
            color:$github;
            &:hover{
                color:$github;
                text-decoration:none;
            }
        }
    }
}


//Certificate Section Start
.certificatebg{
    background:url('Images/certificatebanner.jpg');
    padding: 50px 0;
    .certificatetitle{
        h2{
            font-size:32px;
            color:$darkblue;
            text-align:left;
            font-weight:700;
            font-style:normal;
        }
        p{
            font-size: 16px;
            margin-bottom: 20px;
        }
    }
    .certificatebox{
        box-shadow:0 2px 10px rgba(0,0,0,0.5);
        background:rgba(242,242,242, 0.8);
        text-align: center;
        padding: 10px 15px;
        margin:0 0 30px;
        z-index: 1;
        min-height:450px;
        img{
            width:180px;
        }
        h3{
            font-size:24px;
            padding:15px 0;
        }
        p{
            font-size:16px;
        }
    }
}   


@media only screen and (max-width:767px) {

    //banner
    .banner_img {
        display: none;
    } 
    .banner_bg{
        padding-top: 0;
    }
    .HomeBanner {
        .jumbotron{
            padding:28px 0 0;
        }
    } 
    .WhyChooseExchange{
        .WhyChoosebox{
            margin:10px 0;
        }
    }
    .FooterMain{
        padding-top:15px;
    }
    
    //canvas
    .home_maincanvas{
        height:470px !important;
    }
    .jumbotron{
        .bannerdetails h1 {
            font-size:26px !important;
        }
        .bannerdetails p {
           font-size:20px !important;
           line-height:28px !important;
        }
    }
}

//Tablet View
@media (min-width: 768px) and (max-width: 1024px) {
    .jumbotron{
        padding:0 !important;
        .bannerdetails h1 {
            font-size:24px !important;
        }
    }
    .AdditionalBox{
        padding:0 0 0 15px;
    }
   
    
}