@mixin nfttitle {
    font-size:32px;
    text-align: left;
    font-weight: 700;
    font-style: normal;
    display: inline-block;
    padding-bottom: 10px;
    margin-bottom:30px;
}

.NftBanner-bg{
    padding:130px 0 0;
    background-image:url('Images/NFT/NFTBannner.png');
    position: relative;
    min-height:450px;
    .bannerdetails{
        text-align: left;
        padding:80px 0 0;
        h1{
            font-size:84px;
            color:$white;
            font-weight:700;
            font-style:normal;
            line-height: 85px;
            margin: 0;
        }
        span{
            font-size:30px;
            color:$white;
            padding-bottom:20px;
            display:inline-block;
        }
        p{
            font-size:16px;
            color:$white;
        }
    }
}

// NFT Service
.Nftservices-main{
    padding:50px 0 50px;
    h2, h3, h4, h5{
        font-size:32px;
        color:$darkblue;
        text-align: left;
        font-weight: 700;
        font-style: normal;
        border-bottom:3px solid $lightblue;
        display: inline-block;
        padding-bottom: 10px;
        margin-bottom:50px;
    }
    .nftservicesbox{
        border:2px solid $tumblr;
        text-align:center;
        padding:20px 15px;
        border-radius:5px;
        img{
            display: inherit;
            margin: 0 auto;
        }
        span{
            color:$darkblue;
            font-size:26px;
            display:inline-block;
            font-weight:bold;
            width: 100%;
            padding:20px 40px;
        }
        p{
            font-size:16px;
        }
    }
}

// NFT Service CTA
.Nftservices-cta{
    background:url('Images/NFT/NFTBannner.png');
    padding:80px 0;
    h3{
        font-size:42px;
        color:$white;
        font-weight:bold;
        margin:0;
    }
    p{
        font-size:16px;
        padding:10px 0;
        color:$white;
    }
    a{
        background:$lightorange;
        font-weight:bold;
        border-radius:30px;
        padding:10px 30px;
        color:$white;
        border:none;
        margin-top:30px;
        display:inline-block;
        &:hover{
           background:$gradient-bluesky;
           text-decoration:none;
           color:$white;
        }
    }
}

//Features Tokens 
.featurestokens{
    padding:50px 0 50px;
    h3{
        @include nfttitle();
        color:$darkblue;
        border-bottom:3px solid $lightblue;
    }
  
    ul{ padding:0; margin:0;
        li{
            display:inline-block;
            width:33%;
            &:last-child{
                img{
                    float: left;
                }
                span{
                    display: inherit;
                    padding-left: 85px !important;
                    padding-right: 20px;
                    vertical-align: bottom;
                }
            }
            .fettokensbox{  
                text-align:left;
                margin:15px 0;
                img{
                    margin-bottom:20px;
                    width:70px;
                }
                span{
                    font-size:20px;
                    color:$darkblue;
                    font-weight: 500;
                    line-height: 24px;
                    padding-left:15px;
                    vertical-align: super;
                    // width:100%;
                    // display: inline-block;
                }
            }
        }
    }
}

// Benefit NFT
.benefit-nft{
    padding:50px 0 50px;
    h3{
        @include nfttitle();
        color:$darkblue;
        border-bottom:3px solid $lightblue;
    }
    .benefitbox{
        span{
            text-align: center;
            margin: 0 auto;
            width: 100%;
            display: inline-block;
            position: relative;
            z-index: 999;
            bottom: 60px;
            font-size: 30px;
            color:$white;
            font-weight: bold;
        }
        ul{
            border:1px solid $darkblue;
            border-top: none;
            position: relative;
            bottom: 50px;
            li{
                padding:10px 0;
                list-style-image: url('Images/NFT/Polygon.png');
                &::marker{
                    width:20px;
                }
            }
        }
    }
}