.Thankyou{
  background:url(Images/Landing/landing1.png);
  background-size: cover;
  height: 750px;
  padding:120px 0px 100px 0px;

    .thankyou-content{
      text-align: center;
      color: $white;
      padding: 150px 0px 0px 0px;
      h1{
        font-size: 60px;
        font-weight: bold;
        line-height: 100px;
      }
      p{
        font-size: 25px;
      }
    }
}
////////////////////////// Responsive  ///////////////////////

@media only screen and (max-width: 500px) {
  .Thankyou{
    height: 350px !important;
    padding: 100px 0px;
  } 
  .thankyou-content{
    text-align: center;
    color: $white;
    padding: 20px 0px 0px 0px !important;
    h1{
      font-size: 25px !important;
      font-weight: bold;
      line-height: 30px !important;
    }
    p{
      font-size: 15px !important;
    }
  }
}

@media only screen and (max-width: 1400px) {
  .Thankyou{
    background-size: cover;
    height: 654px;
    padding: 100px 0px;

    .thankyou-content{
      text-align: center;
      color: $white;
      padding: 150px 0px 0px 0px;
      h1{
        font-size: 60px;
        font-weight: bold;
        line-height: 100px;
      }
      p{
        font-size: 25px;
      }
    }
  
  }
}