//Common Main Title Start
.marketingfeaturestitle{
    padding:0px 0 10px;
    h2, h3, h4, h5{
        font-size:32px;
        color:$darkblue;
        text-align: left;
        font-weight: 700;
        font-style: normal;
    }
    p{
        font-size:16px;
        color:$github;
    }
}

// Banner Start
.marketingfeature{
    padding:90px 0 0;
    //background:$bannerlightgray;
    background-image:url('Images/HeaderBanner.jpg');
    position: relative;
    min-height:450px;
    canvas{
        height:350px !important;
    }
    
    .jumbotron{
        background:transparent;
        padding:0;
        margin:0;
    }
    .bannerdetails{
        //position: absolute;
        text-align: center;
        padding:80px 0 0;
        width: 100%;
        h1{
            font-size:34px;
            color:$darkblue;
            line-height:50px;
            text-align:center;
            font-weight:700;
            font-style:normal;
        }
        p{
            font-size:20px;
            color:$darkblue;
            line-height:36px;
            text-align:center;
        }
        button, a{
            background:$lightorange;
            font-weight:bold;
            border-radius:30px;
            padding:10px 30px;
            color:$white;
            border:none;
            margin-top:30px;
            display:inline-block;
            &:hover{
               background:$gradient-bluesky;
               text-decoration:none;
            }
        }
    }
}

//main section_1
.mainsection{
    .marketingfeaturestitle{
        padding-top: 95px;
    }
}

//section2 start

.Marketing_Features_exfeature{
    background-color: $bannerlightgray;
    padding:50px 0;
    ul{
        list-style-type: none;
        display: inline-flex;
        background-color: $white;
        padding: 20px;
        border-radius:10px;
        box-shadow: 1px 5px 36px rgba(0, 0, 0, 0.1);
        min-height: 150px;
        margin: 0 0 30px;
        Img{
            width: 83px;
          

        }
        .marketing-features-cont{
            padding-left: 26px;

            h4{
                font-size: 18px;
                margin-bottom: 5px;
                display: inline-block;
                font-family: Gill Sans Extrabold, sans-serif;
                color: $lightblack;
            }
            
            p{
                font-size: 14px;
            }
           
        }
        .dynamic-cms-icn{
            color:$lightblue;
        }
    }
    
}


// Interested section 3

.Marketing_Features_Interested{
    padding:50px 0;
    background: url(Images/features-bg.jpg);
    background-size:cover;


    .marketing-features-box{
        background-color:$white;
        box-shadow: 0 5px 30px rgba(0, 0, 0, 0.1);
        padding: 20px 20px;
        text-align: left;
        margin: 10px 0 0 0;
        min-height: 300px;
        a{
            img{
                margin: 0 auto;
                width:245px;
                height: 68px;
                display: block;
            }
            h3{
                padding-top:10px;
                font-size:22px;
                color: $lightblack;
                margin-top:10px;
                margin-bottom:15px;
            }
            .interested-header{
                border:none;
                font-size: 20px;
                color:$lightblack;
                display: block;
                padding: 10px 0px;
                &:hover{
                color: $lightblue;
                text-decoration: none;
                }
            }

            .interested-caption{
                border:none;
                font-size: 16px;
                color:$lightblack;
                display: block;
                padding: 10px 0px;
                &:hover{
                text-decoration: none;
                }
            }
            &:hover {
                text-decoration:none;
                
            }  
        }
    }
}


//Insight section 4

.Marketing_Features_Insight{
    padding:50px 0px 80px 0px;;
    
  
    .card-title{
        height: 70px;
        font-size: 18px;
        color: $lightblack;
        &:hover{
            color: $lightblue;
           text-decoration: none;
        }
    }

    .carddiv{
        &:hover{
        box-shadow: 0 2px 3px rgba(0,0,0,0.1), 0 5px 10px rgba(0,0,0,0.25);
        transition: box-shadow 0.3s;
        }
    }
}

@media only screen and (min-width: 320px) and (max-width: 680px)  {
    .marketingfeature  {padding: 0;}
    //canvas
    .marketing_featurecanvas {
        height:400px !important;
    }
}


@media only screen and (max-width : 767px) {
    //main section_1
    .mainsection{
        .marketingfeaturestitle{
            padding-top: 16px;
        }
    }
}


//Tablet View
@media (min-width: 768px) and (max-width: 1024px) {
    .mainsection{
        .marketingfeaturestitle {
            padding-top: 0;
        }
    }
    .marketingfeature{
        .bannerdetails {
            padding: 70px 0 0 !important;
        }
    }
    .Marketing_Features_exfeature{
        ul{
            min-height:190px;
        }
    }
    .Marketing_Features_Interested{
        .marketing-features-box{
            min-height: 420px;
        }
    }
}