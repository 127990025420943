.contactusbanner-bg,
.innerbanner-bg {
  color:$github;
  text-align:center;
  .bannerdetails {
    padding:150px 0 50px;
  }
  span {
    font-size: 18px;
    padding: 30px 0 0 0;
    display: inline-block;
  }
}

#gmap_canvas {
  border: none;
}

.contactdetails {
  padding:8px 0 0;
  svg {
    //fill: $darkgreen;
    fill:#12b09c;
    path {
      //fill: $darkgreen;
      fill:#12b09c;
    }
  }
  a {
    &:hover {
      text-decoration: none;
    }
  }
}
.Contactusmain {
  padding: 60px 0 60px;
}
.contactbtn {
  input {
    //font-family: $FontRegular;
    cursor: pointer;
    //border: 2px solid $darkorrenge !important;
    //color: $darkorrenge;
    height: 50px;
    display: inline-block;
    padding: 10px 30px;
    border-radius: 50px;
    font-size: 18px;
    font-weight: bold;
    &::before {
      border-radius: -20px 0 0 0;
      transition: all 0.8s;
      //background-color: $darkorrenge;
      color: $black;
    }
    &::after {
      border-radius: -20px 0 0 0;
      transition: all 0.8s;
      //background-color: $darkorrenge;
      color: $black;
    }
    &:hover {
      text-decoration: none;
      //background-color: $darkorrenge;
      color: $white;
      //border: 2px solid $darkorrenge !important;
    }
  }
}
.react-tabs__tab {
  min-width: 95px;
  text-align: center;
}

.socialmediamain {
  padding: 0;
  li {
    list-style: none;
    display: inline-block;
    padding: 0px 10px 0 0;
    a {
      display: inline-block;
      img {
        width: 40px;
      }
    }
  }
}
.mapbox {
  width: 100% !important;
  height: 180px !important;
  padding-right: 0px;
  padding-left: 0px;
}
.react-tel-input input[type="tel"] {
  width: 100% !important;
  //font-family: $FontRegular;
  font-size: 16px !important;
}

.contacttitle{
  margin:0 0 30px 0px;
  h2{
    font-size:22px;
  }
  &::after{
    content:"";
    position:absolute;
    width:10%;
    height:3px;
    //background:$darkgreen;
  }
}
// .contactbox{
//   border:1px solid $darkgreen;
//   text-align:center;
//   min-height:150px;
//   padding:30px 0 0;
//   margin-bottom:40px;
//   cursor:pointer;
//   svg{
//     fill:$darkgreen;
//     path{
//       fill:$darkgreen;
//     }
//   }
//   span, a{
//     display:inline-block;
//     width:100%;
//     padding:10px 5px;
//     font-size:15px;
//     color:$darkgreen;
//   }
//   &:hover{
//     box-shadow: 0 1.5rem 4rem rgba(0, 0, 0, 0.15);
//     transform: translate(0, -10px);
//     transition: all .3s ease-out;
//     path{
//       fill:$white;
//     }
//     background:$darkgreen;
//     color:$white;
//     text-decoration:none;
//     span, a{
//       color:$white;
//       text-decoration:none;
//     }
//   }
// }

.contactlinkbox{
  width:100%;
  margin:0 auto;
  text-align:center;
  a{
    font-size:14px;
    padding:10px 15px;
    margin-top:0;
    display:inline-block;
    border-radius: 0;
    color:$white;
    width:31%;
    margin-left:5px;
    border-radius:35px;
    cursor: pointer;
    &:hover{
      text-decoration:none;
      color:$white;
    }
    img{
      width:25px;
    }
    &:first-child{
      background:#00ADF2;
    }
    &:nth-child(2){
      background:#4BCA58;
    }
    &:nth-child(3){
      background:#2DAAE2;
    }
    
  }
  span{
    margin:0 10px;
  }
}

//Mobile View Start

@media only screen and (max-width: 767px) {
  .Contactusmain {
    padding: 60px 24px 50px;
  }
  .mapbox {
    position: unset !important;
  }
  .contactusbanner-bg{
    .bannerdetails{
      display:none;
    }
  }
  .contactlinkbox{
    a{
      width:80%;
      margin-bottom:10px;
    }
  }
}

.react-phone-number-input__error {
  font-size:12px  !important;
  color: red  !important;
  margin-left:-10px !important;
}
.react-phone-number-input__country{
  margin:0 0 4px 0 !important;
}
.react-phone-number-input__input{
  border-bottom:none !important;
}
.react-phone-number-input__icon-image{
  margin:-10px 0 0 0;
}


.iframeform{
  border: none;
  .zcwf_title{
    display:none;
  }
}
.zcwf_title{
  display:none !important;
}
