.derivative_bg{
    padding:90px 0 0;
    background-image:url('Images/HeaderBanner.jpg');
    position:relative;
    min-height:450px;
    .bannerdetails{
        text-align: center;
        padding:80px 0;
        h1{
            font-size:36px;
            color:$darkblue;
            line-height:50px;
            text-align:center;
            font-weight:700;
            font-style:normal;
            padding: 0 0 12px;
            span{
                width:100%;
                display:inline-block;
            }
        }
        p{
            font-size:20px;
            color:$darkblue;
            line-height:30px;
            text-align:center;
            margin:0;
        }
        button, a{
            background:$lightorange;
            font-weight:bold;
            border-radius:30px;
            padding:10px 15px;
            color:$white;
            border:none;
            margin-top:30px;
            display:inline-block;
            &:hover{
               background:$gradient-bluesky;
               text-decoration:none;
            }
        }
    }
}

.derivativesmain{
    padding:50px 0;
    .derivativetitle{
        h2{
            font-size:32px;
            color:$darkblue;
            text-align:left;
            font-weight:700;
            font-style:normal;
        }
        p{
            font-size:16px;
            color:$github;
            padding:0 0 10px;
            display:inline-block;
        }
    }
    .derivativedetails{
        background-color:$white;
        padding:10px 20px;
        box-shadow:15px 10px 30px rgba(0,0,0,.2);
        min-height:190px;
        border-radius:8px;
        margin-bottom:30px;
        span{
            font-size:22px;
            color:$darkblue;
            font-weight:500;
        }
    }
}